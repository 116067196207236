import '../assets/css/SearchSuggestions.scss';
import alargada2 from '../assets/img/alargada2.jpg';
import cuadroUno from '../assets/img/gallery-view/cuadros/uno.jpg';
import cuadroDos from '../assets/img/gallery-view/cuadros/dos.jpg';
import cuadroTres from '../assets/img/gallery-view/cuadros/tres.png';
import cuadroCuatro from '../assets/img/gallery-view/cuadros/cuatro.png';
import cuadroCinco from '../assets/img/gallery-view/cuadros/cinco.png';
import cuadroSeis from '../assets/img/gallery-view/cuadros/seis.jpg';
import cuadroSiete from '../assets/img/gallery-view/cuadros/siete.png';
import cuadroOcho from '../assets/img/gallery-view/cuadros/ocho.png';
import alargada from '../assets/img/alargada.jpg';
import {motion, AnimatePresence} from 'framer-motion';
import { useSelector } from 'react-redux/es/exports';

const SearchSuggestions = ({value}) => {
     const data = [cuadroUno, cuadroDos, cuadroTres, cuadroCuatro, cuadroCinco, cuadroSeis, cuadroSiete, cuadroOcho, alargada, alargada2];
    //const data = [];
    const state = useSelector(state => state);

    return (
        <>
            <motion.div className="search__suggestions-popup"
                key="search__suggestions-popup"
                animate={{ opacity: 1}}
                initial={{ opacity: 0}}
                exit={{ opacity: 0}}
                transition={{ duration: 0.25}}
            >
                <div className="search__suggestions-item header">See full results for "{value}"</div>
                    {
                        data.length > 0 ?
                            //TODO: CHANGE FOR DATABASE ID
                            data.map((artwork, index)=>{
                                return ( 
                                    <div key={index} className="search__suggestions-item artwork">
                                        <div className="search__suggestions-item-image" style={{backgroundImage: `url(${artwork})`}}></div>

                                        <div className="search__suggestions-item-details">
                                            <p className="search__suggestions-item-details-name">Mar en calma pero mucho mas largo</p>
                                            <p className="search__suggestions-item-details-artist">Sergio Rodriguez</p>
                                        </div>
                                    </div>
                                );
                            })
                        :
                            <div className="search__suggestions-item artwork loading">
                                <div className="search__pulseLoader">
                                </div>
                            </div>
                    }
            </motion.div>
        </>
    );
};


export default SearchSuggestions;