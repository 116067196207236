import config from '../config/project.config.js'

const UserActions =  {
    addToWishlist: (artwork) => {
        return {
            type: '@user/add_to_wishlist',
            payload: {
                artwork
            }
        }
    },

    removeFromWishlist: (artwork) => {
        return {
            type: '@user/remove_from_wishlist',
            payload: {
                artwork
            }
        }
    },

    addToCart: (artwork) => {
        return {
            type: '@user/add_to_cart',
            payload: {
                artwork
            }
        }
    },

    removeFromCart: (artwork) => {
        return {
            type: '@user/remove_from_cart',
            payload: {
                artwork
            }
        }
    },

    clearWishlist: () => {
        return {
            type: '@user/clear_wishlist',
        }
    },

    clearCart: () => {
        return {
            type: '@user/clear_cart',
        }
    },

    updateQuantity: (product, quantity) => {
        return {
            type: '@user/update_quantity',
            payload: {
                product,
                quantity
            }
        }
    }
}
  
  
  export default UserActions;