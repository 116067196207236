/**
 * Hook explanation:
 * 
 * This hook will provide easy to call fetch petitions to APIs. It basically prepares the info for the fetch petition. It's kind of like a bundler for a fetch. It gathers all the info
 * needed for the kind of petition that you need. And it returns the promise so that you have full controll over the response and the data.
 * 
 * Usage:
 * fetchWrapper.post(url, options).then(errorHandler);
 * 
 * Options are the ones that we are going to configure. For now, we have this options:
 * 
 * body: Body that you want to include in fetch (alre)
 * injectToken: if truthy (not null or undefined or false) token will be injected into header
 * headers: Any header value that you want to include
 * More features to add in the future
 *  
 */

import {useSelector} from 'react-redux';

const useFetchWrapper = () => {
    const state = useSelector(state => state);

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        delete: request('DELETE')
    };


    function request(method) {
        return (url, options) => {
            let fetchOptions = {};

            if(options.body) {
                fetchOptions = {...fetchOptions, body: options.body}
            }

            if(options.injectToken) {
                fetchOptions = {...fetchOptions, headers: {...fetchOptions.headers, 'Authorization': `${state.token}` }}
            }

            if(options.headers) {
                fetchOptions = {...fetchOptions, headers: {...fetchOptions.headers, ...options.headers}}
            }

            if(options.injectJSON) {
                fetchOptions = {...fetchOptions, headers: {...fetchOptions.headers, 'Content-Type': 'application/json', 'Accept': '*/*'}}
            }

            //Inject method to options
            fetchOptions = {...fetchOptions, method}
            
            return fetch(url, fetchOptions);
        }
    }
};

export default useFetchWrapper;





