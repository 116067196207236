/**
 * The DEFINITIVE HOOK 
 * Manage the global state with SYNCHRONOUS BEHAVIOUR!
 * 
 * BIG NOTE: IF YOU USE THIS HOOK YOU WONT BE ABLE TO USE USEFFECT LOGIC ON AN SPECIFIC ATTRIBUTE OF THE STATE. WHY? BECAUSE IMAGE IF
 * YOU WANT TO TRIGGER A USEFFECT WHENEVER STATE.USER_DATA CHANGES. USER_DATA INITIALLY IS NULL AND IS FILLED INTO THE STATE AFTER A FETCH RESPONSES.
 * WE PUT IT INTO THE STATE USING THE SET FUNCTION. BECAUSE THE SET FUNCTION USES DEEP EXPLORATION, IT BASICALLY COPIES THE WHOLE STATE, UPDATES IT, AND THEN STORES IT.
 * SO, WHEN WE STORE THE WHOLE OBJECT REACT DETECTS THAT EVERYTHING CHANGED. I WANT TO SEE IF I CAN FIX THIS PROBLEM 
 */
import {useDispatch} from 'react-redux';
import actions from '../actions';
import Utils from '../utils';
import store from '../store';
import { useSelector } from 'react-redux';

 const useGlobalState = () => {
     const dispatch = useDispatch();
     const CACHE_PATH = "cache_metas";
     const state = useSelector(state => state); //DO NOT REMOVE THIS LINE. IT IS NECESSARY TO TRIGGER A RE-RENDER WHENEVER THE STATE CHANGES

     return {
        get: () => { return store.getState() },
        getSelector: () => { return state },
        dispatch: (action) => {dispatch(action)},
        contains: (attribute) => {return Utils.objectContains(attribute, store.getState())}, //Does deep exploration
        getValue: (attribute) => {return Utils.getAttributeValue(attribute, store.getState())}, //Does deep exploration
        set: (attribute, value) => {dispatch(actions.set(attribute, value))}, //Does deep exploration
        remove: (attribute) =>{dispatch(actions.remove(attribute))}, //Does deep exploration
        getCache: () => {return Utils.getAttributeValue(CACHE_PATH, store.getState())},
        isCacheExpired: () => {return (Utils.getAttributeValue(CACHE_PATH, store.getState())).expires_in <= Date.now()}, 
        isCacheEmpty: () => {return [0,1].includes(Object.keys((Utils.getAttributeValue(CACHE_PATH, store.getState()))).length)},
        isInCache: (attribute) =>{return Utils.objectContains(CACHE_PATH+"."+attribute, store.getState())}, 
        clearCache: () => {dispatch(actions.restore_cache_meta())},
        getCacheValue: (attribute) => {return Utils.getAttributeValue(CACHE_PATH+"."+attribute, store.getState())}, //Does deep exploration 
        storeInCache: (attribute, value) => {dispatch(actions.set(CACHE_PATH+"."+attribute, value))}, //Does deep exploration
        removeFromCache: (attribute) => {dispatch(actions.remove(CACHE_PATH+"."+attribute))}, //Does deep exploration
        actions,
     };
 };

 //Note: Currently i cant have 2 sets of the state simultaniously in the same function. Why? Because set changes the state and causes a re-render. But both sets are in the same function, so that means that both dispatches are called in the same render.
 // We would like to dispatch, re render and then execute the other dipsatch but i want to make it easier rather then having a useEffect. That's why the solution is to apply the logic in the reducer. Although it's the same render, inside the reducer you have the current value of the state.
 // Use selector return the current value of state IN THE CURRENT RENDER. IF YOU UPDATE STATE BUT YOU ARE IN THE SAME RENDER YET, USE SELECTOR WONT GIVE YOU THE NEW STATE. IF U ARE INSIDE A REDUCER, YOU WILL ALWAYS HAVE THE CURRENT VALUE OF THE STATE NO MATTER THE RENDER THAT YOU ARE.
 // GG WP :).

 //Edit: We can now access the current value of state with getState function. Amazing.
 
 export default useGlobalState;
 
 
 
 
 
 