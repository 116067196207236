import '../assets/css/LoginForm.scss';
import logoHeader from '../assets/img/suarte-heading-logo.png';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Utils from '../utils';
import config from '../config/project.config';
import { useDispatch, useSelector } from 'react-redux';
import AuthActionCreator from '../actions/auth.actions';
import useFetchWrapper from '../hooks/useFetchWrapper';

const LoginForm = ({setSlide}) => {
    const API_URL = `${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/auth/login`;
    const fetchWrapper = useFetchWrapper();
    const textError = useRef();
    const errorContainer = useRef();
    const loginContainer = useRef();
    const loginAnimationEmail = useRef();
    const loginAnimationPassword = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const displayError = (e, error) => {
        errorContainer.current.style.display = 'block';
        textError.current.textContent = error;
        e.target.email.focus();
    }

    const handleLoginSubmit = async (e) => {
        e.preventDefault();

        if(!e.target.email.value || !e.target.password.value) {
            displayError(e, "Enter a username and password");
        } else {
            let galleryInfo = {
                user_email: e.target.email.value,
                user_pass: e.target.password.value
            } 

            try {
                let response = await fetchWrapper.post(API_URL, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': '*/*'
                    },
                    body: JSON.stringify(galleryInfo)  
                })

                // Invalid username or password
                if(response.status===404 || response.status===401 || response.status===400) {
                    displayError(e, "Invalid username or password");
                }

                else if(response.status===500) {
                    displayError(e, "Internal server error");
                }

                else if(response.status===200) {
                    let data = await response.json();
                    let action = AuthActionCreator.login(data);
                    dispatch(action);
                    navigate('/main');
                } 

            } catch(error) {
                displayError(e, "Internal server error");
            }
        }
    }
    
    return (
        <>
            <div className="login-center" ref={loginContainer}>
                <div className="login-container">
                    <img src={logoHeader} alt="Suarte main heading logo" className="login-form__image" />
                    
                    <form onSubmit={handleLoginSubmit} className="login-form">
                        <div className="email-input email-input--login">
                            <input onFocus={()=>{loginAnimationEmail.current.classList.add("active");}} 
                                onBlur={(e)=>{if(e.target.value.length === 0) loginAnimationEmail.current.classList.remove("active");}} 
                                type="text" name="email" className="email-input__email input-animation" autoComplete='on' onChange={Utils.handleInputLoginChange}/>
                            <span className='login-container__animation' ref={loginAnimationEmail}>Username or email</span>
                        </div>
                        <div className="login-form__border"></div>

                        <div className="password-input">
                            <input onFocus={()=>{loginAnimationPassword.current.classList.add("active");}} 
                                onBlur={(e)=>{if(e.target.value.length === 0) loginAnimationPassword.current.classList.remove("active");}} 
                                type="password" name="password" id="password" className="password-input__password input-animation" onChange={Utils.handleInputLoginChange}/>
                            <span className='login-container__animation' ref={loginAnimationPassword}>Password</span>
                        </div>
                        <div className="login-form__border"></div>

                        <div className="info-error-container" ref={errorContainer}>
                            <span className="info-error-container__icon">
                                <i className="fa-solid fa-circle-info"></i>
                            </span>
                            <span className="info-error-container__text-error" ref={textError} >
                            </span>
                        </div>


                        <button className="login-form__submit">
                            Log in
                        </button>

                        <div className="login-form-options">
                            <span className="login-form-options__forgot-password" onClick={()=>{setSlide('right');}}>Forgot password?</span>
                        </div>
    
                    </form>
                </div>
            </div>
        </>
    );
};

export default LoginForm; 