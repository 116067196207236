import '../assets/css/NewArtworkAddImage.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { useEffect, useState } from 'react';
import ImageUploading from 'react-images-uploading';
import config from '../config/project.config';
import Spinner from 'react-bootstrap/Spinner';
import useFetchWrapper from '../hooks/useFetchWrapper';
import useUserId from '../hooks/useUserId';

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

const NewArtworkAddImage = ({images, setImages}) => {
    const [swiper, setSwiper] = useState(null);
    const [addFlag, setAddFlag] = useState(false);
    const files_url = `${config.files_api.url}/artwork`;
    const fetchWrapper = useFetchWrapper();
    const user_id = useUserId();

    const artworkAddUpdateImage = (imageList, imageIndex) => {
        if(imageList.length > images.length) { //Add Case
            setAddFlag(true);

            for(let i = images.length ; i < imageList.length; i++) {
                setImages((prevImages)=>{
                    return [...prevImages, {type: 'loading'}]
                })

                let body = new FormData();
                body.append('main_file', imageList[i].file);
                body.append('user_id', user_id);

                //Fetch call and when it finishes IF IT'S CORRECT we set it to start_to_load with the url that the api sends us (the tmp one)
                fetchWrapper.post(files_url, { injectToken: true, body})
                .then(response => {
                    if(response.status === 200) {
                        response.json().then(data =>{
                            changeImageState(i, "start_to_load",  `${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}${data.path}`, data.path) 
                        })
                    } else {
                        changeImageState(i, "image_fail")
                    }
                })
                .catch(error => {changeImageState(i, "image_fail")})
            }
        } else if(imageList.length === images.length) { //Update Case (package only allows one image to be updated at a time)
            const old = images[imageIndex[0]]; //In case update fails, we put the pic that was already there

            setImages((prevImages) => {
                let newImages = [...prevImages];
                newImages[imageIndex[0]] = {type: 'loading'};
                return newImages;
            })

            let body = new FormData();
            body.append('main_file', imageList[imageIndex[0]].file);
            body.append('user_id', user_id);

            //Fetch call and when it finishes IF IT'S CORRECT we set it to start_to_load
            fetchWrapper.post(files_url, { injectToken: true, body})
            .then(response => {
                if(response.status === 200) {
                    response.json().then(data =>{
                        changeImageState(imageIndex[0], "start_to_load",  `${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}${data.path}`, data.path) 
                    })
                } else {
                    if(old.url) {
                        changeImageState(imageIndex[0], "update_fail", old.url, old.path)
                        alert("Image update failed. Try again.")
                    } else {
                        changeImageState(imageIndex[0], "image_fail") //IF old doesnt have an url, that means that it waa this case: you upload an image and it fails, u put another and it fails again
                    } 
                }
            })
            .catch(error => {
                if(old.url) {
                    changeImageState(imageIndex[0], "update_fail", old.url, old.path)
                    alert("Image update failed. Try again.")
                } else {
                    changeImageState(imageIndex[0], "image_fail") //IF old doesnt have an url, that means that it waa this case: you upload an image and it fails, u put another and it fails again
                } 
            })
        }
    };

    //Removes the given index from the array of images
    const artworkRemoveImage = (index) => {
        setImages((prevImages) =>{
            let newImages = [...prevImages];
            newImages.splice(index, 1);
            return newImages;
        });
    }
    
    //Changes image flag to the value we need
    const changeImageState = (index, type_value, url, path) => {
        setImages((prevImages) => {
            let newImages = [...prevImages];
            newImages[index] = url ? {...newImages[index], type: type_value, url: url, path: path} : {...newImages[index], type: type_value};
            return newImages;
        })
    }

    const swipeImage = (direction, index) => {
        setImages((prevImages) => {
            let newImages = [...prevImages];
            let imageToMove = newImages[index];

            if(direction === "left") {
                newImages[index] = newImages[index - 1];
                newImages[index - 1] = imageToMove;
            } else {
                newImages[index] = newImages[index + 1];
                newImages[index + 1] = imageToMove;
            }

            return newImages;
        })
    }

    useEffect(()=>{
        if(swiper && images && addFlag === true) {
            swiper.slideTo(images.length, 0);
            setAddFlag(false);
        }
    }, [images, swiper, addFlag]);

    return (
        <>
            <div className="new-artwork__add-images-slider">
                <ImageUploading
                    multiple
                    value={images} //value is only to check the length. So that it gives you a correct imageList. That's it. It doesnt update anything. It's cool.
                    onChange={artworkAddUpdateImage}
                    maxNumber={`${config.maxArtworkImages}`}
                    onError={()=>{alert(`You are only allowed to upload ${config.maxArtworkImages} images of an artwork`)}}
                    >
                    {({
                        onImageUpload,
                        onImageUpdate,
                        isDragging,
                        dragProps,
                    }) => (
                        <Swiper
                            className="new-artwork__add-images-slider"
                            slidesPerView={1.6}
                            threshold={20}
                            spaceBetween={20} 
                            breakpoints={{
                                600: {
                                    slidesPerView: 3.02,
                                    spaceBetween: 20 
                                },
                            }}
                            onInit={s => setSwiper(s)}
                        >
                            {
                                images.map((artworkImage, index) => {
                                    return (
                                        <SwiperSlide key={index}>
                                            <div className="new-artwork__image-container">
                                                {
                                                    artworkImage.type === 'loading' || artworkImage.type === "start_to_load" ?
                                                        <>
                                                            <div className="new-artwork__image-loading">
                                                                <Spinner animation="border" variant="dark" />
                                                            </div>
                                                        </>
                                                    :

                                                    artworkImage.type === 'image_fail' ?
                                                        <>
                                                            <div className="new-artwork__image-fail">
                                                                <i className="fa-solid fa-triangle-exclamation"></i>
                                                                <span>Image upload failed</span>
                                                                <button type="button" onClick={() => onImageUpdate(index)}>Upload another</button>
                                                            </div>
                                                        </>
                                                    :
                                                        null
                                                }

                                                {
                                                    //If we are in these state, it means that the image object has the attribute url
                                                    artworkImage.type === 'loaded' || artworkImage.type === 'start_to_load' || artworkImage.type === 'update_fail' ?
                                                        <>
                                                            <div className="new-artwork__image" style={artworkImage.type === "start_to_load" ? {display: 'none'} : artworkImage.type === 'update_fail' ? {border: `2px dashed red`} : null}>
                                                                <img src={artworkImage.url} onLoad={()=>{
                                                                    if(artworkImage.type === 'start_to_load') { //If the image was update_fail, we dont want to notify the user the loaded state, we want to keep it
                                                                        changeImageState(index, "loaded");
                                                                    } 
                                                                }} alt="new artwork" onError={()=>{changeImageState(index, "image_fail")}} onClick={() => onImageUpdate(index)}/>

                                                                <div className="new-artwork__image-buttons">
                                                                    <div className={index === 0 ? "new-artwork__image-button disabled" : "new-artwork__image-button"} onClick={index !== 0 ? ()=>{
                                                                        swipeImage("left", index);
                                                                    } : null}>
                                                                        <i className="fa-solid fa-arrow-left"></i>
                                                                    </div>

                                                                    <div className={index === 4 || index === (images.length - 1) ? "new-artwork__image-button disabled" : "new-artwork__image-button"} onClick={index !== 4 || index === (images.length - 1) ? ()=>{
                                                                        swipeImage("right", index);
                                                                    } : null}>
                                                                        <i className="fa-solid fa-arrow-right"></i>
                                                                    </div>

                                                                    <div className="new-artwork__image-button --remove" onClick={()=>{artworkRemoveImage(index)}}>
                                                                        <i className="fa-solid fa-xmark"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    :   
                                                        null
                                                }
                                                
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                            
                            {
                                images.length < 5 && (
                                    <SwiperSlide>
                                        <div className="new-artwork__add-new-button" style={isDragging ? {color: 'red', border: '2px solid red'} : null} onClick={onImageUpload} {...dragProps}>
                                            <i className="fas fa-plus"></i>
                                        </div>
                                    </SwiperSlide>

                                )
                            }
                        </Swiper>
                    )}
                    </ImageUploading>
            </div>
        </>
    );
};


export default NewArtworkAddImage;