import {configureStore} from '@reduxjs/toolkit';
import MainReducer, {INITIAL_STATE} from '../reducers';

//Global state initialization
const store = configureStore({
    reducer: MainReducer,
    preloadedState: INITIAL_STATE
});

// GLOBAL STATE EXPLANATION

// By default, whenever react refreshes or closes the page, the global store values will be lost. That's why we need a way to keep track of the current values (if we want to do so)
// Right now, we have two choices: Web Storage API or IndexedDB.
// We will use both. 
// So, it will work like this:
// Initial state will be the values stored in localstorage and indexeddb. And everytime the gloal state changes, we will update those attributes in their storage so that we can keep track of them.
//
// NOTE: NOT EVERY VALUE WILL BE PERSISTED. SOME VALUES WILL BE LOST IF THE PAGE IS REFRESHED.

// GLOBAL STORE ATTRIBUTES WE WANT TO KEEP IN LOCALSTORAGE. INDEXEDDB IS NOT AVAILABLE YET.

//EDIT: EL ESTAOD GLOBAL ESCRIBIRLO EN LOKIJS MEJOR
store.subscribe(()=> {
    const state = store.getState();

    if(state.token) {
        localStorage.setItem('token', store.getState().token);
    }
    
    if(state.user_data) {
        localStorage.setItem('user_data', JSON.stringify(store.getState().user_data));
    }
});


export default store;