import '../assets/css/NewArtworkAddAudio.scss';
import AudioPlayer from './AudioPlayer';
import Spinner from 'react-bootstrap/Spinner';
import ImageUploading from 'react-images-uploading';
import config from '../config/project.config';
import useFetchWrapper from '../hooks/useFetchWrapper';
import useUserId from '../hooks/useUserId';

const NewArtworkAddAudio = ({audio, setAudio}) => {

    const files_url = `${config.files_api.url}/artwork/audio`;
    const fetchWrapper = useFetchWrapper();
    const user_id = useUserId();
    
    const artworkAddAudio = (imageList, imageIndex) => {
        setAudio({type: 'loading'});

        let body = new FormData();
        body.append('main_file', imageList[0].file);
        body.append('user_id', user_id);

        fetchWrapper.post(files_url, { injectToken: true, body })
        .then(response => {
            if(response.status === 200) {
                response.json().then(data => {
                    setAudio(prevAudio => {
                        return {
                            ...prevAudio,
                            type: 'loaded',
                            url: `${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}${data.path}`, 
                            path: data.path
                        }
                    })
                });
            } else {
                setAudio(prevAudio => ({...prevAudio, type: 'error', url: ''}))
            }
        })
        .catch(error => {setAudio(prevAudio => ({...prevAudio, type: 'error', url: ''}))});
    }

    return (
        <>
            <ImageUploading
                onChange={artworkAddAudio}
                maxNumber={1}
                allowNonImageType={true}
                acceptType={['mp3', 'ogg', 'wav']}
                onError={()=>{alert(`You are only allowed to upload one audio file`)}}
            >
                {({
                    onImageUpload,
                    isDragging,
                    dragProps,
                }) => (
                    <>
                        {
                            audio && audio.type === "loaded" ? 
                                <>
                                    <div className="new-artwork__add-audio-loaded">
                                        <AudioPlayer audio={audio.url}/>
                                        <div className="add-audio__buttons">
                                            <div className="add-audio__button" onClick={onImageUpload}>
                                                <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                    <path id="Icon_material-edit"
                                                        d="M.31,395.7v103.93H104.24L411.16,192.92l-103.93-103.93L.31,395.7ZM491.61,112.48c10.78-10.82,10.78-28.31,0-39.12L426.68,8.45c-10.82-10.78-28.31-10.78-39.12,0l-50.81,50.81,103.93,103.93,50.81-50.81,.12,.09h0Z"/>
                                                </svg>
                                            </div>
                                            <div className="add-audio__button" onClick={()=>{setAudio(null)}}>
                                                <i className="fa-solid fa-xmark"></i>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            :
                                <>
                                    <div className={isDragging ? "new-artwork__add-audio dragging" : "new-artwork__add-audio"} {...dragProps}>
                                        {
                                            audio && audio.type === "loading" ?
                                            <>
                                                <Spinner animation="border" variant="dark" />
                                            </>
                                        :
                                            audio && audio.type === "error" ?
                                            <>
                                                <div className="new-artwork__image-fail">
                                                    <i className="fa-solid fa-triangle-exclamation"></i>
                                                    <span>Audio upload failed</span>
                                                    <button type="button" onClick={onImageUpload}>Upload another</button>
                                                </div>
                                            </>
                                        :
                                            <>
                                                <div>
                                                    <div className="new-artwork__add-audio-upload-icon">
                                                        <i className="fa-solid fa-cloud-arrow-up"></i>
                                                    </div>
            
                                                    <p className="new-artwork__add-audio-text">Drag your audio here</p>
                                                    <p className="new-artwork__add-audio-text or">or</p>
                                                    <span type='' className="new-artwork__add-audio-browse" onClick={onImageUpload}>Browse</span>
                                                </div>
                                                
                                            </>
                                        }
                                    </div>
                                </>
                        }
                    </>
                )}
            </ImageUploading>
            
        
        </>
    );
};


export default NewArtworkAddAudio;