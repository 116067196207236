const UIActionCreator =  {
    changeTheme: (theme) => {
        return {
            type: '@ui/change-theme',
            payload : {
                theme
            }
        }
    },

    enterView: (view) => {
        return {
            type: '@ui/enterView',
            payload: {
                view
            }
        }
    },

    toggleOnMobileMenu: () => {
        return {
            type: '@ui/toggleOnMobileMenu'
        }
    },

    toggleOffMobileMenu: () => {
        return {
            type: '@ui/toggleOffMobileMenu'
        }
    },

    toggleOnLimitedEdition: () => {
        return {
            type: '@ui/toggleLimitedEdition'
        }
    },

    toggleOffLimitedEdition: () => {
        return {
            type: '@ui/toggleOffLimitedEdition'
        }
    },

    openArtworkZoom: (dzi) => {
        return {
            type: '@ui/openArtworkZoom',
            payload: {
                dzi
            }
        }
    },

    closeArtworkZoom: () => {
        return {
            type: '@ui/closeArtworkZoom',
        }
    },

    openSearchSuggestions: (dzi) => {
        return {
            type: '@ui/openSearchSuggestions',
        }
    },

    closeSearchSuggestions: () => {
        return {
            type: '@ui/closeSearchSuggestions',
        }
    },

    addFilter: (category, filterOption) => {
        return {
            type: '@ui/addFilter',
            payload: {
                category,
                filterOption
            }
        }
    },

    removeFilter: (category,filterOption) => {
        return {
            type: '@ui/removeFilter',
            payload: {
                category,
                filterOption
            }
        }
    },

    clearFilters: () => {
        return {
            type: '@ui/clearFilters',
        }
    }
  }
  
  
  export default UIActionCreator;