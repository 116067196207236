//INITIAL_STATE OF THE GLOBAL STATE. WE WILL READ FROM LOCALSTORAGE AND INDEXEDDB
import config from "../config/project.config";
import Utils from "../utils";

const INITIAL_STATE = {
    token: localStorage.getItem('token') || '',
    theme: 'dark',
    limitedEdition: false,
    filters: {},
    user_data:  JSON.parse(localStorage.getItem('user_data')) || {wishlist:[], cart:[]},
    cache_metas: {
        expires_in: Date.now() + config.cache_expiring_offset,
    }
};

const MainReducer = (state = INITIAL_STATE, action) => {
    switch(action.type) {
        case '@auth/login': {
            return {
                ...state,
                token: action.payload.token,
                gallery_data: {
                    ...action.payload.gallery_data
                },
            }
        }

        case '@auth/logout': {
            return {
                ...state,
                token: ""
            }
        }

        case '@auth/refresh_token': {
            return {
                ...state,
                token: action.payload.token
            }
        }

        case '@ui/change-theme': {
            return {
                ...state,
                theme: action.payload.theme
            }
        }

        case '@ui/enterView': {
            return {
                ...state,
                view: action.payload.view
            }
        }

        case '@ui/toggleOnMobileMenu': {
            return {
                ...state,
                mobile_menu: true
            }
        }

        case '@ui/toggleOffMobileMenu': {
            return {
                ...state,
                mobile_menu: false
            }
        }

        case '@ui/toggleLimitedEdition': {
            return {
                ...state,
                limitedEdition: true
            }
        }

        case '@ui/toggleOffLimitedEdition': {
            return {
                ...state,
                limitedEdition: false
            }
        }

        case '@ui/openArtworkZoom': {
            return {
                ...state,
                zoom: true,
                dzi: action.payload.dzi
            }
        }

        case '@ui/closeArtworkZoom': {
            return {
                ...state,
                zoom: false,
                dzi: ''
            }
        }

        case '@ui/openSearchSuggestions': {
            return {
                ...state,
                search_suggestions: true
                
            }
        }

        case '@ui/closeSearchSuggestions': {
            return {
                ...state,
                search_suggestions: false
            }
        }

        case '@ui/addFilter': {
            const newFilters = state.filters[action.payload.category] ? [...state.filters[action.payload.category]] : [];

            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.category] : [...newFilters, action.payload.filterOption]
                }
            }
        }

        case '@ui/removeFilter': {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.category] : state.filters[action.payload.category].filter(filterOption => filterOption !== action.payload.filterOption)
                }
            }
        }

        case '@ui/clearFilters': {
            return {
                ...state,
                filters: {}
            }
        }

        case '@profile/store_gallery_data': {
            return {
                ...state, 
                gallery_data: action.payload.gallery_data,   
            }

        }

        case '@profile/update_gallery_data': {
            return {
                ...state,
                gallery_data: action.payload.newData
            }
        }

        case '@user/add_to_wishlist':  {
            fetch(`${config.stats_api.protocol}://${config.stats_api.host}:${config.stats_api.port}/wishlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*'
                },
                body: JSON.stringify({
                    token: state.token,
                    status: {
                        product_id: action.payload.artwork.product_id,
                        action: "add",
                        timestamp: Date.now()
                    }
                })
            }).catch(err=>{})

            return {
                ...state,
                user_data: {
                    ...state.user_data,
                    wishlist: [...state.user_data.wishlist, action.payload.artwork]
                }
            }
        }

        case '@user/remove_from_wishlist':  {
            fetch(`${config.stats_api.protocol}://${config.stats_api.host}:${config.stats_api.port}/wishlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*'
                },
                body: JSON.stringify({
                    token: state.token,
                    status: {
                        product_id: action.payload.artwork.product_id,
                        action: "remove",
                        timestamp: Date.now()
                    }
                })
            }).catch(err=>{})

            return {
                ...state,
                user_data: {
                    ...state.user_data,
                    wishlist: state.user_data.wishlist.filter(artwork => artwork.product_id !== action.payload.artwork.product_id)
                }
            }
        }

        case '@user/add_to_cart':  {
            fetch(`${config.stats_api.protocol}://${config.stats_api.host}:${config.stats_api.port}/cart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*'
                },
                body: JSON.stringify({
                    token: state.token,
                    status: {
                        product_id: action.payload.artwork.product_original_id,
                        limited_edition_id: action.payload.artwork.product_limited_id,
                        action: "add",
                        timestamp: Date.now()
                    }
                })
            }).catch(err=>{})

            return {
                ...state,
                user_data: {
                    ...state.user_data,
                    cart: [...state.user_data.cart, action.payload.artwork]
                }
            }
        }

        case '@user/remove_from_cart':  {
            fetch(`${config.stats_api.protocol}://${config.stats_api.host}:${config.stats_api.port}/cart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*'
                },
                body: JSON.stringify({
                    token: state.token,
                    status: {
                        product_id: action.payload.artwork.product_original_id,
                        limited_edition_id: action.payload.artwork.product_limited_id,
                        action: "remove",
                        timestamp: Date.now()
                    }
                })
            }).catch(err=>{})

            return {
                ...state,
                user_data: {
                    ...state.user_data,
                    cart: state.user_data.cart.filter(artwork => artwork.product_id !== action.payload.artwork.product_id || artwork.product_type !== action.payload.artwork.product_type)
                }
            }
        }

        case '@user/clear_wishlist': {
            fetch(`${config.stats_api.protocol}://${config.stats_api.host}:${config.stats_api.port}/wishlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*'
                },
                body: JSON.stringify({
                    token: state.token,
                    status: {},
                })
            }).catch(err=>{})
            
            return {
                ...state, 
                user_data: {
                    ...state.user_data,
                    wishlist: []
                }
            }
        }

        case '@user/clear_cart': {
            fetch(`${config.stats_api.protocol}://${config.stats_api.host}:${config.stats_api.port}/cart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*'
                },
                body: JSON.stringify({
                    token: state.token,
                    status: {},
                })
            }).catch(err=>{})

            return {
                ...state,
                user_data: {
                    ...state.user_data,
                    cart: []
                }
            }
        }

        case '@user/update_quantity': {
            return {
                ...state,
                user_data: {
                    ...state.user_data,
                    cart: state.user_data.cart.map(product => {
                        if(product.product_id === action.payload.product.product_id && product.product_type === action.payload.product.product_type) {
                            return {
                                ...product,
                                product_quantity: action.payload.quantity
                            }
                        } else {
                            return product
                        }
                    })
                }
            }
        }

        case '@cache/restore_cache_meta': {
            return {
                ...state,
                cache_metas: {
                    expires_in: Date.now() + config.cache_expiring_offset,
                }
            }
        }
        case '@profile/allow_access': {
            return {
                ...state,
                profile_access: "ACCESS_GRANTED"
            }
        }

        case '@profile/deny_access': {
            return {
                ...state,
                profile_access: null,
            }
        }
        case '@global/set':  {
            return {
                ...Utils.addAttributeToObject(action.payload.attribute, action.payload.value, state)
            }
        }

        case '@global/remove':  {
            return {
                ...Utils.removeAttributeFromObject(action.payload.attribute, state)
            }
        }

        case '@test/test': {
            return {
                ...state,
                user_data: {
                    ...state.user_data,
                   prueba: ""
                }
            }
        }
        default: {
            return state;
        }
    } 
};


export default MainReducer;
export {INITIAL_STATE};


