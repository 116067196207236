import '../assets/css/MasonryGallery.scss';
import ArtworkCard from './ArtworkCard';
import Masonry from 'react-masonry-css';
import {useNavigate} from 'react-router-dom';


//This component will be in public showroom and suarteworld. So this component should be the one to include the link to artwork view, not artwork card because
//we want to use artworkcard in other views without routing to an specific artwork view. 
const MasonryGallery = ({artworks}) => {
    const navigate = useNavigate();
    
    const breakpoints = {
        default: 3,
        720: 2,
        550: 1
    };

    return (
        <>
            <div className="masonry">
                <Masonry
                    breakpointCols={breakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    {
                        artworks.map((artwork, index)=> {
                            return (
                                <div key={index} style={{cursor: 'pointer'}} onClick={()=>{navigate(`/artwork/${artwork.product_id}`, {state: artwork})}}>
                                    <ArtworkCard artwork={artwork}/>
                                </div>
                            )
                        })
                    }  
                </Masonry>
            </div>
        </>
    );
};

export default MasonryGallery;