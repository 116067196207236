import '../assets/css/SliderWishList.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch } from 'react-redux/es/exports';
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useState } from 'react'
import UIActionCreator from '../actions/ui.actions';
import config from '../config/project.config'

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { EffectCoverflow, Pagination, Navigation, Keyboard } from "swiper";

const SliderWishList = ({setArtwork}) => {
    const dispatch = useDispatch();
    const [swiperRef, setSwiperRef] = useState(null);
    const state = useSelector(state => state);

    const handleUserActionWishlist = (swiper, event) => {
        const slide = event.target.parentElement;

        if(slide.classList.contains("swiper-slide-active")) {
            //Open Zoomable Viewer
            const artworkId = state.user_data.wishlist[swiper.activeIndex].product_id;
            dispatch(UIActionCreator.openArtworkZoom(`${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}/artwork/${artworkId}/original.xml.dzi`))
        } else if (slide.classList.contains("swiper-slide-prev")) {
            //Go to previous slide
            swiper.slidePrev();
        } else if (slide.classList.contains("swiper-slide-next")) {
            //Go to next slide
            swiper.slideNext();
        }  
    }

    const handleWishlistSlide = (swiper, event) => {
        setArtwork(state.user_data.wishlist[swiper.activeIndex]);
    }

    useEffect(()=>{
        if(swiperRef) {
            setArtwork(state.user_data.wishlist[swiperRef.activeIndex]);
        }
    }, [state.user_data.wishlist])

    return (
        <>
        <Swiper
            effect={"coverflow"}
            grabCursor={true}
            spaceBetween={-40}
            centeredSlides={true}
            slidesPerView={1}
            threshold={20}
            slideToClickedSlide={false}
            breakpoints={{
                540: {
                    slidesPerView: 2,  
                },
            }}

            coverflowEffect={{
                rotate: 85,
                stretch: 0,
                depth: -50,
                modifier: 1,
                slideShadows: false,
            }}

           keyboard={true}
           onClick={handleUserActionWishlist}
           onSlideChange={handleWishlistSlide}
           onInit={(swiper) => {
                setSwiperRef(swiper);
            }}
           navigation={true}
           modules={[EffectCoverflow, Pagination, Navigation, Keyboard]}

            className="slider-wishlist"
        >
            {
                state.user_data.wishlist.map((artwork, index) => {
                    return (
                        // TODO: CHANGE KEY INDEX FOR DATABASE ID 
                        <SwiperSlide key={index}>
                            <div className="swiper-bg" style={{backgroundImage: `url(${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}/artwork/${artwork.product_id}/0.webp)`}}></div>
                        </SwiperSlide>
                    );
                })            
            }
        </Swiper>
        </>
    );
};

export default SliderWishList;