import '../assets/css/SuggestionsSlider.scss';
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom';
import config from '../config/project.config.js';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";

// import required modules
import { FreeMode, Navigation } from "swiper";

const SuggestionsSlider = ({suggestions}) => {
    const navigate = useNavigate();
    const files_server = `${config.files_server.url}/artwork`;

    return (
        <>
            <Swiper
                slidesPerView={2.1}
                navigation={true}
                simulateTouch={false}
                spaceBetween={8}
                breakpoints={{
                    600: {
                        slidesPerView: 2.5,
                    },

                    682: {
                        slidesPerView: 3.5,
                    },

                    868: {
                        slidesPerView: 2.1,
                    },

                    1112: {
                        slidesPerView: 2.5,
                    },

                    1728: {
                        slidesPerView: 3.1,
                    },

                    2149: {
                        slidesPerView: 3.5,
                    },

                    2860: {
                        slidesPerView: 4.5,
                    }
                }}
                freeMode={{
                    enabled: true, 
                    momentumBounce: false,
                }}
                pagination={{
                clickable: true,
                }}
                modules={[FreeMode, Navigation]}
                className="suggestions-slider"
            >
                {
                    suggestions.map((suggestion, index) => {
                        return (
                            <SwiperSlide key={index}>
                                <div className="suggestions-slider__item" style={{backgroundImage: `url(${files_server}/${suggestion}/0.large.webp)`}} onClick={()=>{navigate(`/artwork/${suggestion}`, {replace: true});}}></div>
                            </SwiperSlide>
                        )
                    })
                }
        </Swiper>
        </>
    );
};


export default SuggestionsSlider;