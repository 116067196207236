import '../assets/css/ProfileMobileNav.scss';
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProfileNavigation from './ProfileNavigation';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';


const ProfileMobileNav = () => {
    const [isOpen, setOpen] = useState(false);
    const location = useLocation();
    const openButton = useRef();

    useEffect(() => {
        if(isOpen && location.pathname !== "/profile/support" && location.pathname !== "/profile/gallery/showroom" && location.pathname !== "/profile/gallery") {
            setOpen(false);
        }

        if(location.pathname === "/profile/gallery/showroom") {
          openButton.current.classList.add("fixed");  
        } else {
          openButton.current.classList.remove("fixed");
        }
    }, [location.pathname]);

    return (
        <>
          <Offcanvas show={isOpen} onHide={()=>{setOpen(false); }} placement={'start'} className="border mobilemenu__width">
                  <button type="button" className="close-profile-mobile-menu btn-close" aria-label="Close" onClick={()=>{setOpen(false)}}></button>
                  <ProfileNavigation/>
            </Offcanvas>

            <i className="profile-mobile-menu__button fa-solid fa-bars" ref={openButton} onClick={()=>{setOpen(true)}}></i>
        </>
    );
};


export default ProfileMobileNav;