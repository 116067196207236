import '../assets/css/FetchingLoading.scss';

const FetchingLoading = () => {
    return (
        <>
            <div className="fetch-loader__wrap">
                <div className="fetch-loader">
                    <div className="bar1 loading-bar"></div>
                    <div className="bar2 loading-bar"></div>
                    <div className="bar3 loading-bar"></div>
                    <div className="bar4 loading-bar"></div>
                    <div className="bar5 loading-bar"></div>
                    <div className="bar6 loading-bar"></div>
                </div>
            </div>
        </>
    );
};


export default FetchingLoading;