import PageTemplate from './PageTemplate';
import Navbar from '../components/Navbar';
import ArtworkBody from '../components/ArtworkBody';
import ArtworkZoom from '../components/ArtworkZoom';
import Footer from '../components/Footer';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import UIActionCreator from '../actions/ui.actions';
import LimitedEditionPopup from '../components/LimitedEditionPopup';
import { useSelector } from 'react-redux';
import { AnimatePresence } from 'framer-motion';
import config from "../config/project.config"
import { useParams } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import LoadingPage from "../containers/LoadingPage";

const Artwork = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { id } = useParams();
    const API_URL = `${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/product/${id}`;
    const files_api = `${config.files_api.url}/artwork/${id}`;
    const artworkData = useFetch(API_URL, id);
    const artworkInfo = useFetch(files_api, id);

    useEffect(() => {
        dispatch(UIActionCreator.enterView('artwork')); 
    }, []);

    return (
        <>
            {
                artworkData && artworkData !== "LOADING" && artworkInfo && artworkInfo !== "LOADING"? 
                    <>
                        <PageTemplate>
                            <Navbar type="ARTWORK" artworkDetails={artworkData}/>
                            <ArtworkBody artworkDetails={{...artworkData, ...artworkInfo}}/>

                            <AnimatePresence>
                                {state.zoom && <ArtworkZoom />}
                            </AnimatePresence>

                            <Footer />
                        </PageTemplate>

                        <LimitedEditionPopup />
                    </>
                : 
                    <LoadingPage/>
            }
           
        </>
    );
};

export default Artwork;