import '../assets/css/Showroom.scss';
import ShowroomAddAccordeon from './ShowroomAddAccordeon';
import ShowroomMasonry from './ShowroomMasonry';
import FadeIn from './FadeIn';
import { useState } from 'react';
import useUserId from "../hooks/useUserId";
import config from '../config/project.config';
import LoadingPage from '../containers/LoadingPage';
import useCache from '../hooks/useCache';
import useGlobalState from '../hooks/useGlobalState';
import FetchingLoading from '../containers/FetchingLoading';
import HowToEditShowroom from './HowToEditShowroom';

const Showroom = () => {
    const [showroomMode, setShowroomMode] = useState("default");
    const user_id = useUserId();
    const globalState = useGlobalState();
    const showroom = useCache("showroom", `${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/gallery/${user_id}/showroom`, globalState.getCacheValue("showroom"));
    const [showroomState, setShowroomState] = useState("default");
    const [showTutorial, setShowTutorial] = useState(false);

    return (
        <>
            {
                showroom && showroom !== "LOADING" ?
                    <>
                        <div className="showroom__container">
                            <FadeIn>
                                <div className="showroom-header__container">
                                    <div className="showroom__header">
                                        <div className="showroom-header__add">
                                            <ShowroomAddAccordeon />
                                        </div>

                                        <div className="showroom-header__gallery-name">
                                            <h1>{globalState.getValue("gallery_data.user.user_name")}</h1>
                                        </div>

                                        <div className="showroom__edit-buttons-container">
                                            <div className={`showroom-edit-button ${showroomMode}`} onClick={()=>{
                                                if(showroomMode === "default") {
                                                    setShowroomMode("edit");
                                                } else {
                                                    setShowroomMode("default");
                                                }
                                            }}>
                                                    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                        <path id="Icon_material-edit"
                                                            d="M.31,395.7v103.93H104.24L411.16,192.92l-103.93-103.93L.31,395.7ZM491.61,112.48c10.78-10.82,10.78-28.31,0-39.12L426.68,8.45c-10.82-10.78-28.31-10.78-39.12,0l-50.81,50.81,103.93,103.93,50.81-50.81,.12,.09h0Z"/>
                                                    </svg>
                                                    <span>Edit</span>
                                            </div>

                                            <span className="showroom__edit-info-button" onClick={()=>{setShowTutorial(true);}}>
                                                <i className="fa-solid fa-circle-info"></i>
                                            </span>
                                        </div>                                                          
                                    </div>
                                </div>
                                

                                <div className="showroom__wrap">
                                    <div className="showroom-masonry__container">
                                        <ShowroomMasonry artworks={showroom} mode={showroomMode} setShowroomState={setShowroomState}/>
                                    </div>
                                </div>
                            </FadeIn>
                        </div>
                        {(showroomState === "loading") && (<FetchingLoading/>)}
                        <HowToEditShowroom show={showTutorial} setShow={setShowTutorial}/>
                    </>
                    :
                        <LoadingPage />
            }
        </>
    );
};


export default Showroom;