import '../assets/css/NotificationsList.scss';

const NotificationsList = ({notifications, openNotification, setOpenNotification}) => {
    const isNotificationSelected = (notification) => {
        return openNotification && openNotification.notification_id === notification.notification_id;
    }

    //todo: create unseen type of display. Same as whatsapp with a circle on bottom right and stuff
    return (
        <>
        {
            notifications ? 
                <>
                    <div className="notifications__list">
                        {
                            notifications.map((notification, index) => {
                                return (
                                    <div key={index} className={ isNotificationSelected(notification) ? "notifications__item active" : "notifications__item" } onClick={()=>{setOpenNotification(notification)}}>
                                        <div className="notifications__item-wrap">
                                            <div className="notifications__item-content">
                                                <div className="notifications__item-image">
                                                    <img src={notification.notification_image} alt="notification img" />
                                                </div>

                                                <div className="notifications__item-text-container">
                                                    <div className="notifications__item-text-upper">
                                                        <p className="notifications__item-heading">{notification.notification_name}</p>
                                                        <span className="notifications__item-date">{notification.notification_date}</span>
                                                    </div>

                                                    <div className="notifications__item-text-lower">
                                                        <p className="notifications__item-text-preview">{notification.notification_preview}</p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        } 
                    </div>
                </>
            : null
        }
        </>
    );
};


export default NotificationsList;