import '../assets/css/RegisterSuccess.scss';
import FadeIn from './FadeIn';

const RegisterSuccess = () => {
    return (
        <>
            <FadeIn>
                <div className="register-success">
                    <div className="register-success__content">
                        <p className="register-sucess__text">Register success!</p>
                    </div>
                </div>
            </FadeIn>
        </>
    );
};


export default RegisterSuccess;