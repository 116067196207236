import '../assets/css/SelectAccordeon.scss';
import { useState, useRef, useEffect } from 'react';


const SelectAccordeon = ({options, type, option, setOption}) => {
    const [isOpen, setIsOpen] = useState(false);
    const selectList = useRef();
    const scrollList = useRef();

    useEffect(() => {
        if(isOpen) {
            selectList.current.classList.add("active");
            scrollList.current.scrollTop = 0;
        } else {
            selectList.current.classList.remove("active");
        }
    }, [isOpen]);

    return (
        <>
            <div className="select-accordeon" ref={selectList}>
                <div tabIndex={0} className="select-accordeon__selected-option" onClick={()=>{setIsOpen(!isOpen)}} onBlur={()=>{setTimeout(()=>{setIsOpen(false)}, 100)}}>
                    <span className="selected-option__icon">
                        <i className="fa-solid fa-caret-down"></i>
                    </span>
                    <span className="selected-option__option">{option}</span>
                </div>
                <div className="select-accordeon__selected-option-border"></div>

                <div className="select-accordeon__list" ref={scrollList}>
                    {
                        type === "PREFIX" ?
                            options.map((item, index) => {
                                return (
                                    <p key={index} className="select-accordeon__list-item prefix" onClick={()=>{setOption(item.dialCode); setIsOpen(false)}}>
                                        <span>{item.name}</span>
                                    </p>
                                )
                            })
                        :
                            options.map((item, index) => {
                                return (
                                    <p key={index} className="select-accordeon__list-item" onClick={()=>{setOption(item); setIsOpen(false)}}>{item}</p>
                                )
                            })
                    }
                </div>

            </div>
        </>
    );
};


export default SelectAccordeon;