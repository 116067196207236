import '../assets/css/PackageTesting.scss';

const PackageTesting = () => {
    return (
        <>
           <h1>Just testing :)</h1> 
        </>
    );
 };

 export default PackageTesting;











// import '../assets/css/PackageTesting.scss';
// import { useEffect, useState } from 'react';
// import { loadStripe } from "@stripe/stripe-js";
// import { Elements } from "@stripe/react-stripe-js";
// import Stripetesting from './Stripetesting';

// const stripePromise = loadStripe("pk_test_51LXTP6DyAiyWmPjgQa7WbzqV7HfOpl76aujFJeuceEmR7nnzsr8UG6rFDnt1m60F7YslWRG3tDaiAvtHBeFse6dG0060QeX4n1");

// const PackageTesting = () => {
//     const [showStripe, setShowStripe] = useState(false);

//     const [clientSecret, setClientSecret] = useState("");

//     useEffect(() => {
//         // Create PaymentIntent as soon as the page loads
//         fetch("http://192.168.1.122:3005/pay", {
//         method: "POST",
//         headers: { "Content-Type": "application/json" },
//         body: JSON.stringify({ order: {order_id: 2, multiplier: 1}, currency: "eur" }),
//         })
//         .then((res) => res.json())
//         .then((data) => setClientSecret(data.clientSecret));
//     }, []);

//     const appearance = {
//         theme: 'stripe',
//     };
//     const options = {
//         clientSecret,
//         appearance,
//     };

//     return (
//         <>
//             {clientSecret && (
//                 <Elements options={options} stripe={stripePromise}>
//                     <Stripetesting />
//                 </Elements>
//             )}
//          </>
//     );
//  };

//  export default PackageTesting;


