import '../assets/css/PinAccess.scss';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import cero from '../assets/img/pin/0.png';
import uno from '../assets/img/pin/1.png';
import dos from '../assets/img/pin/2.png';
import tres from '../assets/img/pin/3.png';
import cuatro from '../assets/img/pin/4.png';
import cinco from '../assets/img/pin/5.png';
import seis from '../assets/img/pin/6.png';
import siete from '../assets/img/pin/7.png';
import ocho from '../assets/img/pin/8.png';
import nueve from '../assets/img/pin/9.png';
import useless from '../assets/img/pin/useless.png';
import del from '../assets/img/pin/delete.png';
import { useRef } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {motion} from 'framer-motion';
import ProfileActions from '../actions/profile.actions';

const PinAccess = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const keyboardPin = useRef();
    const currentPinRef = useRef("");
    const state = useSelector(state => state);
    const [currentPin, setCurrentPin] = useState("");
    const [circles, setCircles] = useState(null);

    const handleKeyPress = (e) => {
        if(isFinite(e.key)) {
            setCurrentPin(currentPinRef.current + e.key);
        } else if(e.key === "Backspace" || e.key === "Delete") {
            setCurrentPin(currentPinRef.current.slice(0, -1));
        }
    }

    useEffect(()=>{
        document.body.style.overflow = 'hidden'; //remove vertical scroll
        document.addEventListener("keydown", handleKeyPress);
        const keys = keyboardPin.current.children;
        setCircles(keys);
        
        return() => {
            document.body.style.overflow = 'auto'; //restore vertical scroll on unmount
            document.removeEventListener("keydown", handleKeyPress);
        }

    }, []);

    useEffect(()=>{
        currentPinRef.current = currentPin;

        //Double check to ignore first iteration
        if(circles) {
            try {
                if(currentPin.length !== 4) {

                    if(currentPin.length === 3) {
                        keyboardPin.current.classList.remove("pin-shake");
                    }
                   
    
                    //Every time pin is updated, we need to paint currentpin.length circles and clean 4-currentpin.length circles
                    const numberOfPaintedCircles = currentPin.length;
        
                    for (let i = 0; i < numberOfPaintedCircles; i++) {
                        circles[i].classList.add("pin__circle-painted");
                        circles[i].classList.remove("pin__circle-clean");  
                    }
        
                    for (let i = numberOfPaintedCircles; i < 4; i++) {
                        circles[i].classList.add("pin__circle-clean");
                        circles[i].classList.remove("pin__circle-painted");
                    }
                } else {
                    //When pin is 4 digits, we need to paint all circles
                    for (let i = 0; i < 4; i++) {
                        circles[i].classList.add("pin__circle-painted");
                        circles[i].classList.remove("pin__circle-clean");
                    }
                    
                    //We need to check if it's correct pin
                    if(currentPin === ("" + state.gallery_data.gallery_pin_code)) {
                        dispatch(ProfileActions.allowAccess());
                    } else {
                        //We need to clean current pin
                        keyboardPin.current.classList.add("pin-shake");
                        setCurrentPin("");
                    }
                }
            } catch(e) {
                navigate(-1);
            }
            
        }
    }, [currentPin])

    return (
        <>

            <motion.div className="pin-view"
                key="pin-background"
                animate={{ opacity: 1}}
                initial={{ opacity: 0}}
                exit={{ opacity: 0}}
                transition={{ duration: 0.2}}
            >
                <motion.div className="pin-container"
                    key="pin-container"
                    initial={{y: -400, opacity: 0}}
                    animate={{y: 0, opacity: 1}} 
                    transition={{ duration: 0.3, delay: 0.2}}   
                >
                    <div className="pin__close-container">
                        <span className="pin__close-options" onClick={()=>{
                            navigate("/main"); 
                        }}>
                            <span className="pin__x-icon">
                                <i className="fa-solid fa-x"></i>
                            </span>
                            <span className="pin__close-text">Close</span>      
                        </span>     
                    </div>


                    <p className="pin__text">Please, enter your access code</p>
                    <div className="pin__circles" ref={keyboardPin}>
                        <div className="pin__circle"></div>
                        <div className="pin__circle"></div>
                        <div className="pin__circle"></div>
                        <div className="pin__circle"></div>
                    </div>

                    <div className="pin__keyboard-container">
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${uno})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "1");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>1</div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${dos})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "2");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>2</div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${tres})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "3");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>3</div>

                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${cuatro})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "4");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>4</div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${cinco})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "5");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>5</div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${seis})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "6");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>6</div>

                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${siete})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "7");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>7</div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${ocho})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "8");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>8</div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${nueve})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "9");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>9</div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${useless})`}}></div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${cero})`}} onClick={({target})=>{
                            setCurrentPin(currentPin + "0");
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>0</div>
                        <div className="pin__keyboard-item" style={{backgroundImage: `url(${del})`}} onClick={({target})=>{
                            setCurrentPin(currentPin.slice(0, -1));
                            target.classList.add("pressed");
                            setTimeout(()=>{target.classList.remove("pressed")}, 50);
                        }}>
                            <span className="pin__delete-icon">
                                <i className="fa-solid fa-delete-left"></i>
                            </span>
                        </div>
                    </div>
                </motion.div>      
            </motion.div>  
        </>
    );
};

export default PinAccess;