import '../assets/css/Wishlist.scss';
import SliderWishList from '../components/SliderWishList';
import PageTemplate from './PageTemplate';
import Navbar from '../components/Navbar';
import WishlistInfo from '../components/WishlistInfo';
import Footer from '../components/Footer';
import ArtworkZoom from '../components/ArtworkZoom';
import LimitedEditionPopup from '../components/LimitedEditionPopup';
import { useDispatch } from 'react-redux/es/exports';
import { useEffect } from 'react';
import UIActionCreator from '../actions/ui.actions';
import { useSelector } from 'react-redux/es/exports';
import {AnimatePresence} from 'framer-motion';
import { useState } from 'react';
import UserActions from '../actions/user.actions';

const WishList = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [artwork, setArtwork] = useState(state.user_data.wishlist[0]);

    useEffect(()=>{
        dispatch(UIActionCreator.enterView('wishlist'));
    }, []);


    return (
        <>
            <PageTemplate>
                    <Navbar type="WISHLIST"/>
                    {
                        state.user_data.wishlist.length > 0 ?
                            <>
                                <SliderWishList setArtwork={setArtwork}/>
                                <WishlistInfo key={artwork.product_id} artwork={artwork}/>
                                <AnimatePresence>
                                    {state.zoom && <ArtworkZoom />}
                                </AnimatePresence>

                                <div className="wishlist__clear-button-center">
                                    <div className="wishlist__clear-button" onClick={()=>{dispatch(UserActions.clearWishlist())}}>Clear wishlist</div>
                                </div>
                            </>
                        :
                            null
                    }
                    <Footer/>
            </PageTemplate>

            <LimitedEditionPopup/>  
        </>
    );
};

export default WishList;

