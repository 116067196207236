import '../assets/css/Invoices.scss';

const Invoices = () => {
    return (
        <>
            <div className="invoices__container">
                <div className="invoices__wrap">
                    <h1 className="invoices__title">Invoices</h1>

                    <div className="invoices__list">
                        <div className="invoices__item">
                            <p className="invoices__item-text">July 2022 <span>- Statement of account</span></p>
                            <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                <span>Download PDF</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                </svg>
                            </a>
                        </div>

                        <div className="invoices__item">
                            <p className="invoices__item-text">June 2022 <span>- Statement of account</span></p>
                            <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                <span>Download PDF</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                </svg>
                            </a>
                        </div>

                        <div className="invoices__item">
                            <p className="invoices__item-text">May 2022 <span>- Statement of account</span></p>
                            <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                <span>Download PDF</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                </svg>
                            </a>
                        </div>

                        <div className="invoices__item">
                            <p className="invoices__item-text">April 2022 <span>- Statement of account</span></p>
                            <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                <span>Download PDF</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                </svg>
                            </a>
                        </div>

                        <div className="invoices__item">
                            <p className="invoices__item-text">March 2022 <span>- Statement of account</span></p>
                            <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                <span>Download PDF</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                </svg>
                            </a>
                        </div>

                        <div className="invoices__item">
                            <p className="invoices__item-text">February 2022 <span>- Statement of account</span></p>
                            <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                <span>Download PDF</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                </svg>
                            </a>
                        </div>

                        <div className="invoices__item">
                            <p className="invoices__item-text">January 2022 <span>- Statement of account</span></p>
                            <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                <span>Download PDF</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                </svg>
                            </a>
                        </div>

                        <div className="invoices__item">
                            <p className="invoices__item-text">December 2021 <span>- Statement of account</span></p>
                            <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                <span>Download PDF</span>
                                <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                    <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>    
            </div>
        </>
    );
};

export default Invoices;