import '../assets/css/ShowroomNewArtwork.scss';
import { useState } from 'react';
import config from '../config/project.config.js'
import useUserId from '../hooks/useUserId';
import useFetchWrapper from '../hooks/useFetchWrapper';
import { useNavigate } from 'react-router-dom';
import ArtworkFiller from './ArtworkFiller';
import Spinner from 'react-bootstrap/Spinner';
import useGlobalState from '../hooks/useGlobalState';

const Showroom_NewArtwork = () => {
    const [artworkInfo, setArtworkInfo] = useState({
        product_images: [],
        product_audio: "",
        product_name: "",
        product_artist: "",
        product_description: "",
        product_technique: "initial",
        product_theme: "",
        product_feeling: "",
        product_color: "",
        product_date: "",
        product_size: {
            product_width: "",
            product_height: "",
        },
        product_price: "",
        product_tag_color: {r:'255', g:'133', b:'28'},
        product_packed_weight: "",
        product_packed_size: {
            product_packed_width: "",
            product_packed_height: "",
        },
        product_limited: false,
    });
    const [formState, setFormState] = useState("default");
    const [submitForm, setSubmitForm] = useState(false);
    const globalState = useGlobalState();

    //Error and API petition variables
    const [error, setError] = useState(null);
    const user_id = useUserId();
    const fetchWrapper = useFetchWrapper();
    const navigate = useNavigate();
    const backend_url = `${config.backend_api.url}/product`;
    const files_api = `${config.files_api.url}/artwork/commit`;

    const getImagesToCommit = () => {
        let imagesToCommit = [];
        let areImagesCommitting = false;
        artworkInfo.product_images.forEach(image => {
            if(image.type === "loaded" || image.type === "update_fail") { 
                imagesToCommit.push(image.path);
            } else if(image.type === "loading" || image.type === "start_to_load") {
                areImagesCommitting = true;
            }         
        } );

        return {imagesToCommit, areImagesCommitting};
    };

    const submitNewArtwork = (e) => {
        e.preventDefault();
        setError(null);
        setFormState("loading");
        setSubmitForm(false);

        const imagesState = getImagesToCommit();

        if(imagesState.areImagesCommitting) {
            setError({attribute: "product_images", message: "Please wait until all images are loaded"});
            setFormState("default");
        } else if(artworkInfo.product_audio && artworkInfo.product_audio.type === "loading"){
            setError({attribute: "product_audio", message: "Please wait until the audio file is loaded"});
            setFormState("default");
        } else if(imagesState.imagesToCommit.length === 0) {
            setError({attribute: "product_images", message: "Please add at least one image of your artwork"});
            setFormState("default");
        }else {
            const body = {
                product: {
                    ...artworkInfo,
                    product_images: null,
                    product_audio: null
                },
                user_id
            }

            //IF the auxiliar flag state that we used for some logic is still in technique, that means that the user hasnt uploaded any technique, so we reset it to "" so that the backend send us an error
            body.product = artworkInfo.product_technique === "initial" ? {...body.product, product_technique: ""} : body.product;
            
            fetchWrapper.post(backend_url, {
                injectToken: true,
                injectJSON: true,
                body: JSON.stringify(body),
            })
            .then(response => {
                if(response.status === 201) {
                    response.json()
                    .then(data => {
                        const commitBody = {
                            artwork_id: data.product_id,
                            user_id,
                            images: imagesState.imagesToCommit,
                        }

                        if(artworkInfo.product_audio && artworkInfo.product_audio.type === "loaded") {
                            commitBody.audio = artworkInfo.product_audio.path;
                        }

                        fetchWrapper.post(files_api, {
                            injectToken: true,
                            injectJSON: true,
                            body: JSON.stringify(commitBody),
                        })
                        .then(response => {
                            if(response.status === 200) {
                                globalState.removeFromCache("my_artworks"); //to make the user fetch again
                                alert("Artwork successfully created");
                                navigate("/profile/gallery/artworks");
                            }
                        })
                        .catch(err=>{})
                    })
                } else if(response.status === 400) {
                    response.json()
                    .then(data => {
                        setError({attribute: data.attribute, message: data.reason});
                        setFormState("default");
                    })
                } else if([401, 403, 404].includes(response.status)) {
                    navigate("/login");
                } else {
                    navigate("/500-internal-server-error");
                }
            })
            .catch(err => {navigate("/500-internal-server-error");})
        }
    }

    return (
        <>
            <ArtworkFiller artworkInfo={artworkInfo} setArtworkInfo={setArtworkInfo} formHandler={submitNewArtwork} error={error} submitForm={submitForm}/>
            <div className="new-artwork__center-button">
                {
                    formState === "loading" ?
                        <>
                            <div className="new-artwork__loading-button">
                                <Spinner animation="border" variant="dark" />
                                <span>ADD</span>
                            </div>
                        </>
                    :
                        <button className="new-artwork__add-artwork" onClick={()=>{setSubmitForm(true)}}>ADD</button>
                }
                
            </div>
        </>
    );
};

export default Showroom_NewArtwork;