import logoHeader from '../assets/img/suarte-heading-logo.png';
import '../assets/css/ForgotPasswordForm.scss';
import { useRef, useEffect } from 'react';
import Utils from '../utils';
import useFetchWrapper from '../hooks/useFetchWrapper';

const ForgotPasswordForm = ({setSlide}) => {
    const API_URL = 'http://192.168.1.18:3001/auth/forgot-password';
    const textError = useRef();
    const errorContainer = useRef();
    const loginContainer = useRef();
    const successMessage = useRef();
    const fetchWrapper = useFetchWrapper();

    const displayError = (e, error) => {
        errorContainer.current.style.display = 'block';
        textError.current.textContent = error;
        e.target.email.focus();
    }

    const handleResetSubmit = async (e) => {
        e.preventDefault();

        if(!e.target.email.value) {
            displayError(e, "Enter your email");
        } else {
            //If the user had made a mistake, we dont want to keep the error message, so we hide it
            errorContainer.current.style.display = 'none';
            let resetInfo = {
                email: e.target.email.value,
            } 
    
            try {
                let response =  await fetchWrapper.post(API_URL, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': '*/*'
                    },
                    body: JSON.stringify(resetInfo) 
                })

                // Invalid username or password
                if(response.status===404) {
                    displayError(e, "Invalid email");
                }

                else if(response.status===500) {
                    displayError(e, "Internal server error");
                }

                else if(response.status===200) {
                    successMessage.current.style.display = "block";
                    let data = await response.json();
                }

            } catch(error) {
                console.error(error); //This should never happen, i'm a teapot
            }   
        }
    }

    return (
        <>
            <div className="login-center"  ref={loginContainer}>
                <div className="reset-container">
                    <img src={logoHeader} alt="Suarte main heading logo" className="login-form__image" />

                    <span className="reset-container__question">Forgot your password?</span>
                    <span className="reset-container__second-line">Do not worry!</span>
                    <span className="reset-container__third-line">Please, enter your email address below</span>

                    {/* <div className="resend-container">
                        <span className="resend-container__request">
                            Your request has been sent.
                        </span>

                        <div className="resend-logic">
                            <span className="resend-logic__text">
                                In case you didn't get anything after a minute, click
                            </span>

                            <span className="resend-logic__button">
                                send it again
                            </span>
                        </div>
                    </div> */}

                    <form onSubmit={handleResetSubmit} className="reset-form">
                        <div className="email-input email-input--reset">
                                <input type="email" name="email" className="email-input__email input-animation" autoComplete='on' onChange={Utils.handleInputLoginChange}/>
                                <span className='login-container__animation'>Email</span>
                        </div>

                        <div className="login-form__border"></div>

                        <div className="info-error-container" ref={errorContainer}>
                            <span className="info-error-container__icon">
                                <i className="fa-solid fa-circle-info"></i>
                            </span>
                            <span className="info-error-container__text-error" ref={textError} >
                            </span>
                        </div>

                        <button className="login-form__submit">
                                Request
                        </button> 
                    </form>

                    <span className="reset-container__success-message" ref={successMessage}>
                        Your request has been sent. Please, check your inbox.
                    </span>

                    <span className="reset-container__back-icon" onClick={()=> {setSlide('left');}}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </span>
                </div>
            </div>
        </>
    );
};

export default ForgotPasswordForm;