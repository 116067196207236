import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import useFetchWrapper from "./useFetchWrapper";

/**
 * Custom hook for get petitions that share the same .then, .catch and loading behaviour. 
 * This hook is only for fetching private data (that's why i inject the token)
 * 
 * If i want to fetch a resource in the api that everyone is allowed, i cant use this hook because no token will be needed. 
 * 
 * Flag is a value that will be used to re-fetch the data. Every time that value changes, that means that we want to refresh the data
 *
 * 
 */


const useFetch = (url, flag=null) => {
    const [data, setData] = useState("LOADING");
    const navigate = useNavigate();
    const fetchWrapper = useFetchWrapper();

    useEffect(() => {
        setData("LOADING");
        fetchWrapper.get(url, {
            injectToken: true
        })
        .then(response => {
            if(response.status === 200) {
                response.json()
                .then(data => {
                    setData(data);
                })
            } else if(response.status === 400) {
                alert("Something went wrong. If the problem persists, please contact us.");
            } else if(response.status === 401 || response.status === 403) {
                navigate("/login");
            } else if(response.status === 404) {
                navigate("/404-not-found");
            } else if(response.status === 500) {
                navigate("/500-internal-server-error");
            }
        }) 
        .catch(err=> navigate("/500-internal-server-error"));
    }, [flag]);

    return data;
}

export default useFetch;