import { Navigate, Outlet } from "react-router-dom"
import useCheckToken from "../hooks/useCheckToken";
import LoadingPage from "../containers/LoadingPage";


const ProtectedRoute = () => {
    const tokenStatus = useCheckToken();
    
    return (
        <>
            {
                tokenStatus === true ? 
                    <Outlet/> 
                : 
                tokenStatus === "ACCESS_DENIED" ?
                    <Navigate to="/login" replace/>
                :
                    <LoadingPage/>
            }
        </>
    );
}

export default ProtectedRoute;