import '../assets/css/ShowroomMasonry.scss';
import Masonry from 'react-masonry-css';
import ArtworkCard from './ArtworkCard';
import { useEffect, useState } from 'react';
import useGlobalState from '../hooks/useGlobalState';
import useFetchWrapper from '../hooks/useFetchWrapper';
import config from '../config/project.config';
import useUserId from '../hooks/useUserId';
import { useNavigate } from 'react-router-dom';

const ShowroomMasonry = ({artworks, mode, setShowroomState}) => {
    const breakpoints = {
        default: 3,
        1020: 2,
        550: 1
    };

    const fetchWrapper = useFetchWrapper();
    const [selectedArtwork, setSelectedArtwork] = useState(null);
    const navigate = useNavigate();
    const globalState = useGlobalState();
    const user_id = useUserId();
    const backend_url = config.backend_api.url;

    const isArtworkSelected = (artwork) => {
        return artwork && selectedArtwork && selectedArtwork.artwork.product_id === artwork.product_id;
    }

    useEffect(()=>{
        if(mode === "default") { //Everytime mode changes, we want to reset the selected artworks
            setSelectedArtwork(null);
        }
    }, [mode])

    const showroomFetchUpdate = (url, newShowroom) => {
        console.log(newShowroom)
        fetchWrapper.put(url, {
            injectToken: true,
            injectJSON: true,
            body: JSON.stringify({order:newShowroom})
        })
        .then(response => {
            if(response.status === 200) {
                response.json().then(data => {
                    globalState.storeInCache("showroom", data.products);  
                    setShowroomState("default");
                    setSelectedArtwork(null);
                })
            } else if([401, 403].includes(response.status)) {
                navigate("/login");
            } else if(response.status === 404) {
                navigate("/404-not-found");
            } else {
                navigate("/500-internal-server-error");
            }
        })
        .catch(err => {
            navigate("500-internal-server-error");
        })
    }


    const swapAction = (i) => {
        setShowroomState("loading");
        let newShowroom = [...artworks];
        [newShowroom[selectedArtwork.index], newShowroom[i]] = [newShowroom[i], newShowroom[selectedArtwork.index]];
        showroomFetchUpdate(`${backend_url}/gallery/${user_id}/showroom`, newShowroom);
    }

    const moveArtworkTo = (i) => {
        setShowroomState("loading");
        const newPosition = i < 0 ? 0 : i > artworks.length-1 ? artworks.length-1 : i;
        let newShowroom = [...artworks];
        const artworkToMove = newShowroom.splice(selectedArtwork.index, 1)[0];
        newShowroom.splice(newPosition, 0, artworkToMove);
        showroomFetchUpdate(`${backend_url}/gallery/${user_id}/showroom`, newShowroom);
    }

    return (
        <>
            <div className="showroom__masonry">
                <Masonry
                    breakpointCols={breakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                >
                    {
                        artworks.map((artwork, index)=> {
                            return (
                                <div key={index} className={`showroom__artwork-card-container ${mode} ${selectedArtwork !== null} ${"a"+isArtworkSelected(artwork)}`} onClick={mode === "edit" ? ()=>{
                                    if(isArtworkSelected(artwork)) {
                                        setSelectedArtwork(null);
                                    } else { //We only want to select an artwork if there's no one selected
                                        setSelectedArtwork({index, artwork});
                                    }
                                } : null}>
                                    <ArtworkCard artwork={artwork} displayEditOptions={(!isArtworkSelected(artwork) && selectedArtwork !== null)} disableLeft={selectedArtwork && isArtworkSelected(artworks[index - 1])} disableRight={selectedArtwork && isArtworkSelected(artworks[index + 1])} onSwap={()=>{swapAction(index)}} onLeft={()=>{moveArtworkTo(index < selectedArtwork.index ? index : index - 1)}} onRight={()=>{moveArtworkTo(index < selectedArtwork.index ? index + 1 : index )}}/>
                                    {
                                        (mode === "edit" && selectedArtwork === null) && (
                                            <div className="showroom__artwork-card-button" onClick={(e)=>{
                                                e.stopPropagation();
                                                setShowroomState("loading");
                                                fetchWrapper.delete(`${backend_url}/gallery/${user_id}/showroom/${artwork.product_id}`, {
                                                    injectToken: true
                                                })
                                                .then(response => {
                                                    if(response.status === 200) {
                                                        response.json().then(data => {
                                                            globalState.storeInCache("showroom", data.showroom.products);  
                                                            setShowroomState("default");
                                                        })
                                                    } else if([401, 403].includes(response.status)) {
                                                        navigate("/login");
                                                    } else if(response.status === 404) {
                                                        navigate("/404-not-found");
                                                    } else {
                                                        navigate("/500-internal-server-error");
                                                    }
                                                })
                                                .catch(err => {
                                                    navigate("500-internal-server-error");
                                                })
                                            }}>
                                                <i className="fa-solid fa-xmark"></i>
                                            </div>
                                        )
                                    }



                                    {
                                        (mode === "edit" && isArtworkSelected(artwork)) && (
                                            <div className="selected-artwork__backdrop"></div>
                                        ) 
                                    }  
                                   
                                </div>
                            )
                        })
                    }                   
                </Masonry>
            </div>
        </>
    );
};


export default ShowroomMasonry;