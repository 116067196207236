import '../assets/css/EditProfile.scss';
import EditChangable from './EditChangable';
import EditBusinessInfo from './EditBusinessInfo';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import useUserId from '../hooks/useUserId';
import config from '../config/project.config';
import Utils from '../utils';
import { useNavigate } from 'react-router-dom';
import useFetchWrapper from '../hooks/useFetchWrapper';
import useGlobalState from '../hooks/useGlobalState';

const EditProfile = () => {
    const globalState = useGlobalState();
    const state = globalState.get();
    const user_id = useUserId();
    const navigate = useNavigate();
    const fetchWrapper = useFetchWrapper();


    const [editOption, setEditOption] = useState('changable');   //VIEW OPTION IN EDIT
    const [profileState, setProfileState] = useState("default"); //Flags to determine the state of the form
    const [error, setError] = useState(null);


    const [staticGalleryInfo, setStaticGalleryInfo] = useState(null); //This data is only for comparing here
    const [updatedGalleryInfo, setUpdatedGalleryInfo] = useState(null);
    const [didUserUpdatePic, setDidUserUpdatePic] = useState(false);

    const BACKEND_URL = `${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/gallery`;
    const FILES_API = `${config.files_api.protocol}://${config.files_api.host}:${config.files_api.port}/profile/commit`;

    const parseDataFrontend = (obj) => {
        return {
            changable: {
                galleryInfo: {
                    gallery_pic: obj.gallery_pic,
                    gallery_name: obj.user.user_name,
                    gallery_email:  obj.user.user_email,
                    gallery_phone_prefix: obj.user.phone_numbers[0].phone_number_prefix,
                    gallery_phone:  obj.user.phone_numbers[0].phone_number_suffix
                },
                agentInfo: {
                    agent_last_name: obj.agents[0].agent_last_name,
                    agent_first_name: obj.agents[0].agent_first_name,
                    agent_honorific: obj.agents[0].agent_honorific,
                    agent_phone_prefix: obj.agents[0].phone_numbers[0].phone_number_prefix,
                    agent_phone: obj.agents[0].phone_numbers[0].phone_number_suffix
                }
            },
            businessInfo: {
                gallery_username: obj.user.user_login,
                gallery_nit: obj.gallery_nit,
                gallery_country: obj.user.addresses[0].address_country,
                gallery_region: obj.user.addresses[0].address_state,
                gallery_city: obj.user.addresses[0].address_city,
                gallery_postal_code: obj.user.addresses[0].address_zip,
                gallery_address: obj.user.addresses[0].address_address,
            }
        }
    }

    const parseDataBackend = (obj) => {
        return {
            user_id: user_id,
            user: {
                user_name: obj.gallery_name,
                user_email: obj.gallery_email,
                user_phone: {
                    phone_number_id: state.gallery_data.user.phone_numbers[0].phone_number_id,
                    phone_number_prefix: obj.gallery_phone_prefix,
                    phone_number_suffix: obj.gallery_phone
                }
            },

            agent: {
                agent_id: state.gallery_data.agents[0].agent_id,
                agent_last_name: obj.agent_last_name,
                agent_first_name: obj.agent_first_name,
                agent_honorific: obj.agent_honorific,
                agent_phone: {
                    phone_number_id: state.gallery_data.agents[0].phone_numbers[0].phone_number_id,
                    phone_number_prefix: obj.agent_phone_prefix,
                    phone_number_suffix: obj.agent_phone
                }
            }
        }  
    }

    useEffect(()=>{
        const parsedGalleryInfo = parseDataFrontend(state.gallery_data);
        setStaticGalleryInfo(parsedGalleryInfo);
        setUpdatedGalleryInfo(parsedGalleryInfo.changable);
    }, [state.gallery_data]);

    useEffect(()=>{
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });
    }, [editOption]);

    useEffect(()=>{
        if(updatedGalleryInfo && staticGalleryInfo) {
            if(Utils.areObjectsDifferent(staticGalleryInfo.changable, updatedGalleryInfo)) {
                if(staticGalleryInfo.changable.galleryInfo.gallery_pic !== updatedGalleryInfo.galleryInfo.gallery_pic) {
                    setDidUserUpdatePic(true);
                }
                setProfileState("edited");
            } else if(profileState !== "saved") {
                setProfileState("default");
            }
        }
    }, [updatedGalleryInfo]);


    const updateProfileFetch = () => {

        if(updatedGalleryInfo.galleryInfo.gallery_pic === "loading") {
            alert("Wait until the image is uploaded")
        } else {
            let updatedInfoParsed = parseDataBackend(Utils.flattenObject(updatedGalleryInfo));
            let staticInfoParsed = parseDataBackend(Utils.flattenObject(staticGalleryInfo.changable));
            let diff = Utils.getObjectDiff(updatedInfoParsed, staticInfoParsed);
            
            setProfileState("saving");
            setError(null);

            const body = {
                user_id: user_id,
                agent_id: state.gallery_data.agents[0].agent_id,
                user_phone_id: state.gallery_data.user.phone_numbers[0].phone_number_id,
                agent_phone_id: state.gallery_data.agents[0].phone_numbers[0].phone_number_id,
                ...diff
            }


            //IF THE USER CHANGED PROFILE PIC, WE HAVE TO COMMIT THE IMAGE
            if(didUserUpdatePic) {
                fetchWrapper.post(FILES_API, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': '*/*',
                    },
                    injectToken: true,
                    body: JSON.stringify({user_id: user_id, path: updatedGalleryInfo.gallery_pic})
                })
                .catch(err=>{})
            }

            setDidUserUpdatePic(false); //we reset the flag

            fetchWrapper.put(BACKEND_URL, {
                injectToken: true,
                injectJSON: true,
                body: JSON.stringify(body)
            })
            .then(res => {
                if(res.status === 200) {
                    res.json()
                    .then(data =>{
                        globalState.dispatch(globalState.actions.updateGalleryData(data.gallery, user_id))
                        globalState.storeInCache("gallery_data", data.gallery);
                        setProfileState("saved");
                    })    
                } else if(res.status === 400) {
                    res.json()
                    .then(data =>{
                        setError({attribute: data.attribute, message: data.reason})
                        setProfileState("edited");
                    })
                } else if(res.status === 409) {
                    setProfileState("edited");
                    setError({attribute: "user_email", message: "Email already exists."})
                } else if(res.status === 401 || res.status === 403 || res.status === 404) {
                    navigate("/login");
                } else if(res.status === 500) {
                    navigate("/500-internal-server-error");
                } 
            })
            .catch(err => {
                navigate("/500-internal-server-error");
            }) 
        }
    }

    return (
        <>
            <motion.div
                className="edit-profile__animated-container"
                initial={{ opacity: 0}}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.2}}
            >
                <div className="edit__center">
                    <div className="edit__box-info">
                        {
                            staticGalleryInfo && updatedGalleryInfo ? 
                                <>
                                    <div className={editOption === "changable" ? "edit__changable-container active" : "edit__changable-container"}>
                                        <EditChangable toggle={setEditOption} profileInfo={updatedGalleryInfo} setNewInfo={setUpdatedGalleryInfo} error={error}/>
                                        <div className={profileState === "default" ? "edit__changable-submit disabled" : "edit__changable-submit"} 
                                             onClick={profileState === "edited"  ? updateProfileFetch : null}>
                                            <div className={profileState === "default" ? "edit__changable-submit-inner" : "edit__changable-submit-inner color"}>
                                                {
                                                    profileState === "saving" ?
                                                        <span className="changable-submit__text"><Spinner animation="border" />Saving...</span>
                                                    :

                                                    profileState === "saved" ?
                                                        <span className="changable-submit__text color">Saved!</span>
                                                    :
                                                        <span className="changable-submit__text">Save changes</span>
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>

                                    <div className={editOption === "business" ? "edit__business-container active" : "edit__business-container"}>
                                        <EditBusinessInfo businessInfo={staticGalleryInfo.businessInfo}/>
                                        <span className="edit__back-to-changable" onClick={()=>{setEditOption("changable")}}>
                                            <i className="fa-solid fa-chevron-left"></i>
                                        </span>
                                    </div>
                                </>
                            : 
                                <>
                                    <div className="edit__loading">
                                        <Spinner animation="grow" />
                                    </div> 
                                </>
                        } 
                    </div>
                </div>
            </motion.div>
        </>
    );
};


export default EditProfile;