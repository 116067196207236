import '../assets/css/ProfileTemplate.scss';
import { Outlet } from "react-router-dom";
import ProfileNavigation from "../components/ProfileNavigation";
import ProfileMobileNav from "../components/ProfileMobileNav";
import useUserId from "../hooks/useUserId";
import { useDispatch } from "react-redux";
import ProfileActions from "../actions/profile.actions";
import config from '../config/project.config';
import LoadingPage from "./LoadingPage";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import useCache from "../hooks/useCache";
import PinAccess from "./PinAccess";
import { useLocation } from 'react-router-dom';

const ProfileTemplate = () => {
    const user_id = useUserId();
    const dispatch = useDispatch();
    const profile_info = useCache("gallery_data", `${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/gallery/${user_id}`);
    const state = useSelector(state => state);
    const location = useLocation();

    useEffect(()=>{
        if(profile_info !== "LOADING" && profile_info) {
            dispatch(ProfileActions.storeGalleryData(profile_info, user_id));
        }
    }, [profile_info]);

    useEffect(()=>{
        return () => {
            dispatch(ProfileActions.denyAccess());  
        }
    }, [])

    return (
        <>
            <div className="profile-template">
                {
                    profile_info && profile_info !== "LOADING" && state.gallery_data ?
                        state.profile_access !== "ACCESS_GRANTED" ? 
                            <PinAccess /> 
                        :
                            <>
                                <div className="profile__container">
                                    <div className={["/profile/gallery/showroom"].includes(location.pathname) ? "profile__navigation" : "profile__navigation box-shadow"}>
                                        <ProfileNavigation />
                                    </div>

                                    <div className="profile__navigation-mobile">
                                        <ProfileMobileNav />
                                    </div>
                        
                                    <div className="profile__content">
                                            <Outlet/>
                                    </div>
                                </div>
                            </>
                    :
                        <>
                            <LoadingPage/>
                        </>
                }
            </div>
        </>
    );
};

export default ProfileTemplate;