import '../assets/css/Contact.scss';
import FadeIn from './FadeIn';
import contactImage from '../assets/img/profile/contact.png';
import config from '../config/project.config';
import useFetchWrapper from '../hooks/useFetchWrapper';
import useUserId from '../hooks/useUserId';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import ErrorDivDisplayer from './ErrorDivDisplayer';
import Spinner from 'react-bootstrap/Spinner';

const Contact = () => {
    const fetchWrapper = useFetchWrapper();
    const user_id = useUserId();
    const api_url = `${config.stats_api.url}/contact/`;
    const navigate = useNavigate();
    const [contactState, setContactState] = useState("default");
    const [error, setError] = useState(null);

    const sendContactMessage = (e) => {
        e.preventDefault();
        setError(null);
        setContactState("loading");

        const body = {
            contact_subject: e.target.contact_subject.value,
            contact_message: e.target.contact_message.value,
            user_id
        }

        fetchWrapper.post(api_url, {
            injectToken: true,
            injectJSON: true,
            body: JSON.stringify(body),
        })
        .then(response => {
            if(response.status === 200) {
                alert("Message sent successfully");
                e.target.contact_subject.value = "";
                e.target.contact_message.value = "";
                setContactState("default");

            } else if(response.status === 400) {
                response.json()
                .then(data => {
                    setError({attribute: data.attribute, message: data.reason});
                    setContactState("default");
                })
            } else if([401, 403, 404].includes(response.status)) {
                navigate("/login");
            } else {
                navigate("/500-internal-server-error");
            }       
        })
        .catch(err => navigate("/500-internal-server-error")) 
    };

    return (
        <>
            <FadeIn>
                <div className="contact__container">
                    <div className="contact__wrap">
                        <div className="contact__container-inner">
                            <form onSubmit={sendContactMessage} className="contact__form">
                                <p className="contact__form-heading">Send us a message</p>
                                <p className="contact__form-text">We would love hearing from you.</p>
                                <p className="contact__form-text bottom">Please let us know any doubt or suggestion.</p>
                                <input type="text" name="contact_subject" className="contact__form-input" placeholder='Subject'/>
                                <ErrorDivDisplayer attribute="contact_subject" error={error}/>
                                <textarea name="contact_message" className="contact__form-input" placeholder='Message'></textarea>
                                <ErrorDivDisplayer attribute="contact_message" error={error}/>
                                {
                                    contactState === "loading" ?
                                        <>
                                            <div className="contact__form-button loading">
                                                <Spinner animation="border" variant="dark" />
                                                <span>Send</span> 
                                            </div>
                                        </>
                                    :
                                        <button type="submit" className="contact__form-button">Send</button>  
                                }
                            </form>

                            <div className="contact__meeting">
                                <img src={contactImage} alt="contact us logo" className="contact__meeting-image"/>
                                <div className="contact__meeting-group">
                                    <p className="contact__meeting-text">Would you like to make a zoom appointment?</p>
                                    <a href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" target="_blank" className="contact__meeting-link">Click here</a>
                                    <p className="contact__meeting-text smaller">Remember you can also find us on:</p>
                                    <div className="contact__social-media">
                                        <a href="https://www.facebook.com/wearesuarte" target="_blank" className="contact__social-media-icon">
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path
                                                    d="M281.56,413.03c0,25.83-.04,51.67,.02,77.5,.02,9.28,1.19,10.69,10.37,8.65,14.84-3.3,29.85-6.5,44.13-11.59,48.54-17.29,87.97-47.45,117.61-89.35,50.32-71.14,60.73-148.8,32.9-231.07-8.85-26.15-47.86-81.6-50.91-84.65-9.64-9.6-19.19-19.41-29.77-27.91-10.34-8.31-21.67-15.47-33.01-22.42-7.37-4.51-15.4-8.03-23.36-11.46C304.39,1.27,257.56-4.41,208.99,3.35c-28.85,4.61-55.77,14.66-81.33,28.4-22.06,11.87-41.31,27.55-58.77,45.53C25.83,121.63,2.62,175.12-1.17,236.29c-4.03,65.23,15.17,123.93,56.75,174.62,23.75,28.96,52.49,51.66,86.85,67.5,17.84,8.23,35.9,14.98,55.03,19.03,8.01,1.7,9.28,.67,9.29-7.42,.06-51.67,.06-103.34,0-155.01,0-8.79-1.34-9.99-10.43-10.05-13.62-.09-27.24-.16-40.86-.02-6.06,.06-9.24-2.24-9.2-8.63,.1-18.08,.11-36.17,0-54.25-.04-6.66,3.22-9.23,9.57-9.15,10.33,.13,20.66,.01,30.99,0,21.45-.03,19.59,1.58,20.08-19.67,.42-18.49,1.16-37.02,2.91-55.42,1.68-17.68,10.62-33.22,22.13-45.57,7.82-8.39,20.41-13.17,31.7-17.31,26.86-9.83,54.74-5.4,82.32-3.6,5.56,.36,7.42,4.05,7.4,9.23-.06,15.27,.07,30.53-.02,45.8-.05,9.18-1.35,10.38-10.81,10.48-11.27,.12-22.57-.32-33.8,.32-17.85,1.02-26.85,10.7-27.11,28.5-.19,12.44-.13,24.9-.02,37.34,.08,8.43,1.45,9.8,9.95,9.86,16.44,.12,32.87-.02,49.31,.11,8.39,.06,9.63,1.41,8.62,9.53-2.2,17.71-4.87,35.36-6.8,53.1-.79,7.25-4.18,9.56-11.03,9.4-12.91-.29-25.83-.11-38.74-.05-10.32,.05-11.32,1.01-11.34,11.28-.05,25.6-.02,51.2-.01,76.8Z" />
                                                <path
                                                    d="M281.56,413.03c0-25.6-.04-51.2,.01-76.8,.02-10.27,1.02-11.23,11.34-11.28,12.91-.06,25.83-.24,38.74,.05,6.85,.15,10.24-2.15,11.03-9.4,1.93-17.74,4.61-35.39,6.8-53.1,1.01-8.12-.24-9.47-8.62-9.53-16.44-.13-32.87,.01-49.31-.11-8.5-.06-9.87-1.43-9.95-9.86-.11-12.45-.17-24.9,.02-37.34,.27-17.8,9.26-27.48,27.11-28.5,11.24-.64,22.53-.2,33.8-.32,9.46-.1,10.76-1.3,10.81-10.48,.09-15.27-.04-30.53,.02-45.8,.02-5.19-1.83-8.87-7.4-9.23-27.58-1.8-55.47-6.23-82.32,3.6-11.29,4.13-23.88,8.92-31.7,17.31-11.52,12.36-20.45,27.9-22.13,45.57-1.75,18.4-2.48,36.93-2.91,55.42-.49,21.25,1.36,19.64-20.08,19.67-10.33,.02-20.66,.13-30.99,0-6.35-.08-9.62,2.49-9.57,9.15,.11,18.08,.1,36.17,0,54.25-.03,6.39,3.15,8.69,9.2,8.63,13.62-.14,27.24-.08,40.86,.02,9.09,.06,10.42,1.26,10.43,10.05,.06,51.67,.06,103.34,0,155.01,0,8.09-1.27,9.12-9.29,7.42-19.12-4.05-37.19-10.8-55.03-19.03-34.36-15.84-63.1-38.54-86.85-67.5C14,360.22-5.2,301.52-1.17,236.29c3.78-61.17,26.99-114.66,70.06-159.01,17.46-17.98,36.71-33.66,58.77-45.53,25.55-13.75,52.47-23.8,81.33-28.4,48.57-7.76,95.4-2.08,140.53,17.39,7.96,3.44,16,6.95,23.36,11.46,11.34,6.94,22.67,14.11,33.01,22.42,10.58,8.49,20.13,18.31,29.77,27.91,12.26,13.21,42.07,58.5,50.91,84.65,27.83,82.27,17.42,159.93-32.9,231.07-29.63,41.9-69.06,72.06-117.61,89.35-14.28,5.09-29.28,8.29-44.13,11.59-9.18,2.04-10.34,.63-10.37-8.65-.06-25.83-.02-51.67-.02-77.5Z" />
                                            </svg>
                                        </a>

                                        <a href="https://www.youtube.com/channel/UCbSzdsFftszuIaPMScCfAFQ" target="_blank" className="contact__social-media-icon">
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path
                                                    d="M245.22,72.08c43.03,1.06,81.27,1.49,119.46,3.14,23.67,1.02,47.4,2.83,70.83,6.21,31.8,4.59,50.84,24.76,56.37,56.36,4.93,28.19,6.57,56.66,7.59,85.19,1.36,38,.02,75.91-3.96,113.7-1.28,12.16-3,24.47-6.4,36.16-7.5,25.81-25.29,41.41-51.77,45.44-19.48,2.96-39.21,4.64-58.9,5.71-70.02,3.82-140.11,4.33-210.19,1.93-31.17-1.07-62.31-3.39-93.43-5.68-12.54-.92-24.9-3.46-35.93-10.26-17.89-11.03-27.23-27.49-30.79-47.81-5.17-29.46-6.75-59.2-7.71-89.02-1.17-36.57,.19-73.04,4.04-109.4,1.3-12.32,3.01-24.8,6.44-36.64,7.55-26.03,25.54-41.53,52.23-45.53,18.7-2.8,37.63-4.69,56.52-5.5,43.44-1.87,86.91-2.83,125.59-4.02Zm-44.86,253.72c43.4-25.39,86.15-50.4,129.59-75.81-43.56-25.48-86.32-50.49-129.59-75.79v151.6Z" />
                                            </svg>
                                        </a>

                                        <a href="https://twitter.com/wearesuarte" target="_blank" className="contact__social-media-icon">
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path
                                                    d="M150.66,364.06c-10.55,8.43-21.81,15.59-33.78,21.64-11.97,6.05-24.4,10.91-37.32,14.53-12.97,3.64-26.18,5.95-39.61,7.06C26.62,408.39,13.33,408.02,0,406.82c.56,.6,1.15,1.02,1.76,1.4,30.95,19.28,64.31,32.39,100.14,39.22,14.96,2.85,30.05,4.5,45.26,5.02,12.57,.43,25.13,.12,37.67-1,10.77-.96,21.46-2.41,32.08-4.45,22.72-4.36,44.6-11.33,65.58-21.09,33.65-15.66,62.88-37.33,87.92-64.69,17.3-18.9,31.83-39.74,43.76-62.4,12.21-23.18,21.33-47.52,27.35-73.02,3.98-16.87,6.49-33.95,7.43-51.24,.46-8.43,.76-16.88,.14-25.33-.13-1.81,.39-2.93,1.88-4.02,17.06-12.55,31.78-27.44,44.31-44.52,1.62-2.2,3.15-4.47,4.71-6.71-9.38,3.72-18.76,7.08-28.39,9.72-9.65,2.64-19.43,4.62-29.44,5.61,10.4-6.6,19.43-14.64,27.08-24.24,7.27-9.13,16.7-27.03,16.77-32.01-.52,.29-.99,.54-1.45,.8-11.5,6.63-23.52,12.13-36.07,16.46-8.37,2.89-16.91,5.18-25.55,7.06-1.25,.27-2.23,.18-3.01-.94-.34-.49-.84-.88-1.27-1.3-18.3-17.95-40.29-27.78-65.81-29.63-10.02-.73-19.99,.07-29.79,2.39-23.16,5.5-42.28,17.49-57.23,36.02-10.85,13.46-17.77,28.76-20.89,45.78-2.12,11.54-2.25,23.09-.4,34.67,.37,2.31,.81,4.61,1.25,7.12-26.67-1.36-52.54-6.16-77.69-14.63-53.54-18.04-98.16-49.06-134.25-92.74-9.63,16.84-14.52,34.78-13.82,53.99,1.27,35.07,16.75,62.63,45.29,83.22-8.3-.24-16.14-1.5-23.85-3.62-7.73-2.12-15.09-5.23-22.42-9.13-.05,21.6,5.65,41.09,17.56,58.64,15.57,22.95,37.17,37.15,64.13,43.47-14.98,4.14-30.11,4.57-45.61,1.76,5.85,17.74,15.42,32.64,29.04,44.85,18.88,16.92,41.14,25.6,66.49,26.76Z" />
                                                <path
                                                    d="M150.66,364.06c-25.34-1.16-47.6-9.84-66.49-26.76-13.62-12.21-23.19-27.11-29.04-44.85,15.5,2.82,30.63,2.39,45.61-1.76-26.96-6.32-48.55-20.52-64.13-43.47-11.91-17.55-17.61-37.04-17.56-58.64,7.33,3.9,14.68,7,22.42,9.13,7.71,2.12,15.56,3.37,23.85,3.62-28.54-20.59-44.02-48.15-45.29-83.22-.69-19.21,4.19-37.15,13.82-53.99,36.09,43.68,80.71,74.7,134.25,92.74,25.15,8.47,51.01,13.28,77.69,14.63-.44-2.51-.88-4.81-1.25-7.12-1.85-11.58-1.72-23.14,.4-34.67,3.12-17.02,10.04-32.32,20.89-45.78,14.94-18.53,34.06-30.52,57.23-36.02,9.8-2.33,19.77-3.12,29.79-2.39,25.52,1.85,47.51,11.68,65.81,29.63,.43,.43,.93,.81,1.27,1.3,.78,1.12,1.76,1.21,3.01,.94,8.65-1.89,17.18-4.18,25.55-7.06,12.56-4.33,24.57-9.83,36.07-16.46,.46-.27,.93-.52,1.45-.8-.08,4.98-9.51,22.89-16.77,32.01-7.64,9.6-16.68,17.64-27.08,24.24,10-.99,19.78-2.97,29.44-5.61,9.63-2.63,19.01-5.99,28.39-9.72-1.57,2.24-3.1,4.51-4.71,6.71-12.52,17.07-27.25,31.96-44.31,44.52-1.49,1.09-2.01,2.21-1.88,4.02,.62,8.44,.31,16.89-.14,25.33-.93,17.3-3.45,34.37-7.43,51.24-6.02,25.51-15.15,49.84-27.35,73.02-11.93,22.66-26.46,43.5-43.76,62.4-25.04,27.36-54.27,49.03-87.92,64.69-20.98,9.76-42.86,16.73-65.58,21.09-10.62,2.04-21.31,3.49-32.08,4.45-12.54,1.12-25.1,1.43-37.67,1-15.21-.52-30.29-2.17-45.26-5.02-35.84-6.83-69.19-19.94-100.14-39.22C1.15,407.83,.56,407.42,0,406.82c13.33,1.2,26.62,1.57,39.95,.47,13.44-1.1,26.64-3.42,39.61-7.06,12.91-3.62,25.35-8.48,37.32-14.53,11.97-6.05,23.23-13.22,33.78-21.64Z" />
                                            </svg>
                                        </a>

                                        <a href="https://www.tiktok.com/@wearesuarte" target="_blank" className="contact__social-media-icon">
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path
                                                    d="M264.79,165.38c0,39.76,.32,79.53-.12,119.28-.25,22.34-.84,44.74-2.91,66.97-2.71,29.14-18.64,49.87-45.47,61.14-21.08,8.86-41.81,6.15-61.82-5.11-26.64-14.99-40.53-37.15-40.47-67.85,.06-29.5,14.11-50.76,41.68-61.08,14.83-5.55,30.23-9.6,45.41-14.2,4.14-1.25,7.21-3.08,7.21-7.95,0-24.04,0-48.08,.02-72.11,0-3.47-1.61-4.88-5.14-4.48-7.81,.89-15.63,1.58-23.45,2.39-46.12,4.78-83.74,25.07-112.34,61.73-18.88,24.2-28.68,52.11-31.86,82.23-1.8,17.01,.38,33.97,4.18,50.82,9.51,42.25,30.99,76.01,67.72,99.62,20.14,12.95,42.43,19.96,65.93,22.58,18,2,35.62-.87,53.19-6.07,46.16-13.64,79.43-42.19,101.64-84.39,14.53-27.62,19.22-57.61,19.66-88.18,.72-49.28,.21-98.57,.25-147.86,0-6.98,1.12-7.51,7.32-4.23,18.75,9.9,37.7,19.38,58.94,22.76,14.3,2.28,28.64,4.48,43.04,5.97,6.43,.67,7.71-1.29,7.72-7.64,.03-21.62,.03-43.24,0-64.86,0-6.15-.59-6.42-6.7-7.02-44.25-4.36-76.67-26.37-97.24-65.74-7.4-14.16-10.24-29.61-12.16-45.3-.76-6.24-1.62-6.77-7.86-6.78-17.99-.03-35.98-.02-53.97-.01-5.9,0-11.79,.02-17.69,.06-2.55,.02-4.42,.8-4.43,3.87-.02,5.13-.24,10.26-.25,15.39-.02,48.68-.01,97.36-.01,146.05Z"/>
                                                <path
                                                    d="M264.79,165.38c0-48.68,0-97.36,.01-146.05,0-5.13,.22-10.26,.25-15.39,.01-3.07,1.88-3.85,4.43-3.87,5.9-.04,11.79-.06,17.69-.06,17.99,0,35.98-.02,53.97,.01,6.24,.01,7.1,.54,7.86,6.78,1.91,15.69,4.75,31.14,12.16,45.3,20.57,39.37,52.99,61.38,97.24,65.74,6.11,.6,6.69,.87,6.7,7.02,.03,21.62,.03,43.24,0,64.86,0,6.34-1.29,8.3-7.72,7.64-14.4-1.49-28.74-3.69-43.04-5.97-21.23-3.38-40.19-12.86-58.94-22.76-6.2-3.27-7.31-2.75-7.32,4.23-.04,49.29,.47,98.58-.25,147.86-.45,30.57-5.13,60.56-19.66,88.18-22.2,42.2-55.47,70.75-101.64,84.39-17.57,5.19-35.2,8.07-53.19,6.07-23.5-2.62-45.79-9.63-65.93-22.58-36.73-23.61-58.2-57.38-67.72-99.62-3.8-16.86-5.97-33.81-4.18-50.82,3.18-30.13,12.99-58.03,31.86-82.23,28.6-36.66,66.22-56.94,112.34-61.73,7.82-.81,15.64-1.51,23.45-2.39,3.53-.4,5.14,1.01,5.14,4.48-.01,24.04-.03,48.08-.02,72.11,0,4.87-3.07,6.7-7.21,7.95-15.18,4.59-30.58,8.64-45.41,14.2-27.57,10.32-41.63,31.58-41.68,61.08-.06,30.7,13.84,52.86,40.47,67.85,20.02,11.27,40.74,13.97,61.82,5.11,26.83-11.27,42.76-32.01,45.47-61.14,2.07-22.22,2.66-44.63,2.91-66.97,.44-39.76,.12-79.52,.12-119.28Z" />
                                            </svg>
                                        </a>

                                        <a href="https://www.reddit.com/r/SUARTE/" target="_blank" className="contact__social-media-icon">
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path
                                                    d="M94.04,212.21c41.3-27.26,86.35-42.31,135.6-43.91,8.26-.27,11.44-3.76,13.03-11.59,7.78-38.28,16.29-76.41,24.04-114.69,2.36-11.68,7.79-14.89,19.34-12.16,25.39,6.02,50.98,11.26,76.63,16.07,3.61,.68,9.22-1.77,11.84-4.66,15.86-17.47,39.17-20.06,56.89-5.61,16.55,13.5,18.67,39.86,4.14,57.08-9.28,11-21.74,15.3-35.75,13.1-14.98-2.36-26.52-10.53-30.84-25.52-2.72-9.45-7.9-12.96-17.03-14.57-21.54-3.8-42.9-8.62-65.4-13.25-8.11,38.43-15.95,75.61-24.02,113.88,15.13,1.95,29,3.22,42.68,5.6,33.41,5.8,64.89,17.02,93.25,35.97,5.57,3.72,9.46,4.1,15.37,.04,25.54-17.52,57.62-11.4,75.83,13.78,17.21,23.81,12.65,57.79-11.18,75.72-7.18,5.4-9.6,10.56-9.52,19.47,.4,45.92-22.2,80.34-57.54,106.45-60.27,44.53-128.81,56.61-201.83,47.43-46.92-5.9-90.34-21.36-127.19-51.97-33.25-27.62-53.41-62.01-51.87-106.97,.13-3.75-2.41-9.28-5.45-11.18-24.52-15.28-34.52-52.95-13.99-80.29,19.06-25.39,49.85-29.18,79.66-9.77,.69,.45,1.49,.71,3.32,1.55Zm108.03,80.65c.01-20.56-18.04-38.79-38.08-38.45-20.27,.34-38.04,18.74-37.67,39.01,.37,20.21,17.62,37.4,37.6,37.46,20.87,.07,38.13-17.14,38.14-38.01Zm171.48,.01c-.19-20.53-17.75-38.3-37.97-38.42-20.01-.11-37.65,17.8-37.82,38.4-.17,20.64,17.14,38.07,37.81,38.06,20.51,0,38.17-17.69,37.99-38.04Zm-123.96,132.27c18.3,0,32.42-2.8,47.58-7.56,15.07-4.72,29.05-13.32,42.85-21.34,1.01-.59,1.45-2.17,1.68-4.13,.56-5-2.98-9.52-7.99-9.97-1.76-.16-3.33-.03-4.44,.75-37.3,26.17-78.29,26.42-120.31,17.43-11.07-2.37-21.69-7.82-31.88-13.1-6.71-3.48-13.83-11.65-19.66-2.56-5.2,8.12-.03,10.82,5.5,15.66,2.76,2.41,10.8,6.59,14.13,8.23,22.8,11.24,47.1,15.24,72.54,16.59Z" />
                                            </svg>
                                        </a>

                                        <a href="https://www.pinterest.es/wearesuarte/" target="_blank" className="contact__social-media-icon">
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path
                                                    d="M402.49,335.76c-25.75,22.85-57.09,33.58-91.51,33.27-28.31-.25-52.68-10.09-70.71-34.26-7.13,26.74-13.54,53.06-21.24,79-7.77,26.19-8.35,22.09-22.76,46.72l-27.94,38.63c-2.18,1.2-4.86-.34-4.93-2.83l-1.78-17.26-.07-.02c-.64-10.1-1.2-20.19-1.95-30.28-1.89-25.99,1.7-51.3,7.96-76.52,10.58-42.62,20.41-85.43,30.34-128.2,.66-2.86,.23-6.33-.73-9.16-9.76-28.96-11.29-57.71,3.36-85.48,9.34-17.74,23.26-30.04,44.74-30.29,20.92-.24,35.5,12.7,38.48,33.5,2.25,15.68-1.42,30.62-5.56,45.44-6.12,21.91-13.13,43.59-18.83,65.62-6.85,26.53,8.07,49.64,34.83,55.16,13.36,2.74,26.32,.64,38.73-4.45,22.58-9.26,38.14-26.2,48.66-47.59,19.14-38.94,25.12-80.04,14.95-122.38-11.59-48.2-51.55-82.39-100.76-88.46-38-4.69-73.93,.64-105.79,23.08-32.93,23.19-51.06,55.47-55.13,95.47-3.1,30.59,4.04,58.6,22.09,83.79,1.79,2.49,2.13,6.9,1.48,10.09-2.14,10.56-5,20.97-7.66,31.42-1.92,7.54-4.45,8.73-11.59,5.18-16.1-8-27.97-20.58-37.15-35.68-28.9-47.63-31.42-98-12.25-149.19C113.09,57.75,159.89,21.43,223.75,6.74c47.48-10.91,94.67-9.44,139.8,10.71,54.78,24.47,92.21,65.29,102.58,124.9,13.01,74.73-5.13,141.52-63.63,193.41Z" />
                                            </svg>
                                        </a>

                                        <a href="https://www.linkedin.com/company/83495832" target="_blank" className="contact__social-media-icon">
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path
                                                    d="M499.9,43.59V456.02c-.42,1.35-1.11,2.68-1.23,4.06-1.68,18.57-17.67,35.82-35.77,38.54-2.34,.35-4.65,.91-6.97,1.38H43.59c-2.96-.64-5.95-1.15-8.87-1.94C13.03,492.25,.07,475.31,.05,452-.02,317.15-.02,182.29,.08,47.43c0-10.56,2.57-20.55,9.18-29.23C19.4,4.87,33.29,.01,49.38,.01,179.98,0,310.57,0,441.17,.04c6,0,12.01,.4,18,.82,19.46,1.35,35.02,16.27,39.25,35.25,.55,2.48,.99,4.99,1.48,7.49Zm-225.35,170.64c0-6.64,.03-11.81,0-16.98-.06-9.03-5.23-14.26-14.17-14.28-18.07-.03-36.15-.02-54.23,0-10.22,0-15.33,5.03-15.33,15.2-.02,77.37-.02,154.73,0,232.1,0,9.98,5.38,15.3,15.42,15.32,17.59,.04,35.17,.03,52.76,0,10.45-.02,15.54-5.11,15.54-15.54,.01-39.42,.03-78.83-.01-118.25-.01-13.06,2.73-25.44,8.42-37.21,7.94-16.45,22.61-24.81,41.29-23.38,17.4,1.33,29.95,11.54,34.44,29.54,2.3,9.21,3.69,18.89,3.78,28.38,.4,40.23,.15,80.46,.16,120.69,0,11.06,4.68,15.77,15.64,15.78,17.91,.02,35.82,.02,53.74,0,10.94-.01,16.21-5.13,16.21-16.09,0-41.04,.14-82.09-.24-123.13-.18-19.98-1.21-39.97-6.4-59.47-8.44-31.64-25.62-55.31-58.83-64.11-19.85-5.27-39.91-5.08-59.9-.89-19.76,4.14-35.73,14.36-48.3,32.34Zm-203.01,100.02c0,38.26,0,76.52,0,114.77,0,11.4,5.2,16.54,16.77,16.55,15.95,.02,31.9,0,47.85,0,14.36,0,19.07-4.67,19.07-18.92,0-75.05,0-150.1,0-225.15,0-1.3,.04-2.61-.03-3.91-.45-9.24-6.04-14.62-15.32-14.64-17.42-.03-34.83-.02-52.25,0-11,0-16.1,5.07-16.1,16.03,0,38.42,0,76.84,0,115.26ZM167.06,108.37c.07-29.83-24.09-54.46-53.73-54.26-29.24,.2-53.72,22.62-53.97,54.06-.23,29.56,24.45,53.88,53.78,53.9,29.43,.02,53.86-24.3,53.93-53.7Z" />
                                            </svg>
                                        </a>

                                        <a href="https://www.instagram.com/wearesuarte/" target="_blank" className="contact__social-media-icon">
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path
                                                    d="M371.99,499.64H128.31c-1.58-.29-3.14-.69-4.73-.86-13.64-1.5-27-4.52-39.37-10.37C30.6,463.05,1.7,420.61,.87,361.23c-1.03-74.08-.29-148.18-.23-222.27,.02-24,5.3-46.87,17.51-67.57C45.66,24.75,86.88,.88,141.02,.59c72.77-.39,145.55-.19,218.33,.01,9.37,.03,18.96,.52,28.07,2.53,56.52,12.47,92.65,46.86,108.16,102.64,2.03,7.29,2.76,14.95,4.09,22.43v243.72c-.81,5.1-1.35,10.26-2.48,15.29-12.71,56.55-46.94,92.82-102.82,108.33-7.28,2.02-14.92,2.76-22.39,4.09ZM250.52,44.74c-36.22,0-72.44-.03-108.66,.02-11.41,.01-22.67,1.18-33.52,5.06-39.5,14.14-63.37,48.15-63.44,91.07-.12,72.78-.24,145.55,.19,218.33,.06,10.86,1.62,22.22,5.08,32.47,13.41,39.72,47.86,63.57,90.71,63.66,72.76,.14,145.53,.24,218.28-.18,10.86-.06,22.22-1.58,32.48-5.03,39.59-13.32,63.58-47.51,63.72-90.17,.25-73.26,.18-146.53,.02-219.79-.07-32.72-13.26-59.09-39.89-78.34-16.9-12.23-36.12-17.14-56.8-17.11-36.06,.06-72.11,.02-108.17,.02Z" />
                                                <path
                                                    d="M378.76,250.11c-.06,70.7-58.24,128.8-128.77,128.59-70.79-.21-128.8-58.44-128.44-128.92,.36-70.71,57.98-128.24,128.53-128.35,70.59-.1,128.75,58.05,128.69,128.68Zm-128.84-84.3c-46.66,.32-84.33,38.21-84.01,84.48,.32,46.66,38.2,84.35,84.46,84.03,46.65-.32,84.33-38.21,84.01-84.48-.32-46.66-38.21-84.35-84.47-84.03Z" />
                                                <path
                                                    d="M384.37,148.74c-17.85,.12-32.72-14.6-32.71-32.38,.01-17.64,14.48-32.14,32.25-32.31,17.87-.17,32.71,14.5,32.71,32.34,0,17.59-14.6,32.23-32.24,32.35Z" />
                                            </svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </FadeIn>
        </>
    );
};


export default Contact;