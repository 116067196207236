import '../assets/css/AdminHeaderAccordeon.scss';
import { useState, useEffect, useRef } from 'react';

const AdminHeaderAccordeon = ({options, default_option, optionRemoval, accordeonState, setAccordeonState}) => {
    const [isOpen, setIsOpen] = useState(false);
    const accordeon = useRef();
    const selectedOption = useRef();

    useEffect(() => {
        if(isOpen) {
            accordeon.current.classList.add("active");
        } else {
            accordeon.current.classList.remove("active");
        }
    }, [isOpen]);

    useEffect(()=>{
        if(accordeonState.type === "default") {
            selectedOption.current.classList.remove("selected");
        } else {
            selectedOption.current.classList.add("selected");
        }
    }, [accordeonState]);

    return (
        <>
            <div tabIndex={0} className="admin-header-accordeon" ref={accordeon} onClick={()=>{setIsOpen(!isOpen)}} onBlur={()=>{setTimeout(()=>{setIsOpen(false)}, 100)}}>
                <div className="admin-accordeon__selected-option" ref={selectedOption}>
                    <span className="admin-accordeon-selected-option__option">{
                        accordeonState.type === "default" ? default_option : accordeonState.option
                    }</span>
                    <span className="admin-accordeon-selected-option__icon">
                        <i className="fas fa-caret-down"></i>
                    </span>
                </div>
                <div className="admin-accordeon__options">
                    {
                        options.map((item, index) => {
                            return (
                                <p className="admin-accordeon-options__option" onClick={()=>{setAccordeonState({type: 'selected', option: item});}} key={index}>{item}</p>
                            )
                        })
                    }
                    <p className="admin-accordeon-options__option" onClick={()=>{setAccordeonState({type: 'default'});}}>{optionRemoval}</p>
                </div>
                <div className="control-overflow"></div> {/* im a genius xd (sarcasm) */}
            </div>
        </>
    );
};

export default AdminHeaderAccordeon;