import { useLocation } from "react-router-dom"
import { useEffect } from "react"

const usePageTitle = () => {
    const location = useLocation()

    useEffect(() => {
        switch(location.pathname) {
            case "/login": {
                document.title = "Login"
                break;
            }

            case "/register": {
                document.title = "Register"
                break;
            }

            case "/suarteworld": {
                document.title = "Suarteworld"
                break;
            }

            case "/wishlist": {
                document.title = "Wishlist"
                break;
            }

            case "/checkout": {
                document.title = "Checkout"
                break;
            }

            case "/search": {
                document.title = "Search artworks"
                break;
            }

            case "/profile/edit": {
                document.title = "Edit profile"
                break;
            }

            case "/profile/dashboard": {
                document.title = "Dashboard"
                break;
            }

            case "/profile/gallery/showroom": {
                document.title = "My showroom"
                break;
            }

            case "/profile/gallery/artworks": {
                document.title = "My artworks"
                break;
            }

            case "/profile/gallery/artworks/new-artwork": {
                document.title = "New artwork"
                break;
            }

            case "/profile/gallery/imported-artworks": {
                document.title = "Imported artworks"
                break;
            }

            case "/profile/gallery/artists": {
                document.title = "My artists"
                break;
            }

            case "/profile/gallery/collectors": {
                document.title = "My collectors"
                break;
            }

            case "/profile/orders": {
                document.title = "Orders"
                break;
            }

            case "/profile/notifications": {
                document.title = "Notifications"
                break;
            }

            case "/profile/invoices": {
                document.title = "Invoices"
                break;
            }

            case "/profile/referrals": {
                document.title = "My referrals"
                break;
            }

            case "/profile/support": {
                document.title = "Support"
                break;
            }

            case "/profile/support/faqs": {
                document.title = "Frequently asked questions"
                break;
            }

            case "/profile/support/terms": {
                document.title = "Terms and conditions"
                break;
            }

            case "/profile/support/contact": {
                document.title = "Contact us"
                break;
            }

            case "/404-not-found": {
                document.title = "Page not found"
                break;
            }

            case "/500-internal-server-error": {
                document.title = "Internal server error"
                break;
            }

            default: {
                //here we can apply logic to url params (because we have to use includes and not an exact match)
                document.title = "Suarte - First online platform to connect galleries"
            }
        }
    }, [location]);
}

export default usePageTitle;