import config from "../config/project.config"
import jwt_decode from "jwt-decode"
import AuthActionCreator from "../actions/auth.actions";
import React from "react";
import { useSelector } from "react-redux"
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";

//CUSTOM HOOK STATES:
// FALSE: initial state. This will mean that the token is being validated or being refreshed.
//True: Token is valid or you have succesfully generated another, you have access
// ACCESS_DENIED: You dont have access. Because you either didnt have a token, it was not a valid token, 
//                or because the token u provided to refresh was not a suarte one or because the token u provided was indeed a suarte one but was not the last.



const useCheckToken = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const [tokenStatus, setTokenStatus] = useState(false);
    const API_URL = `${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/auth/refresh_token`;

    const isTokenExpired = (token) => {
        if(token.exp) {
            return (token.exp < (Date.now() / 1000));
        } else {
            return false;
        }  
    }

    useEffect(()=>{
        // if(state.token) {
        //     try {
        //         const t = jwt_decode(state.token); 

        //         //If the user has a valid token, we just check if it needs to be refreshed (backend will also check that this token is from suarte)
        //         if(isTokenExpired(t)) {
        //             fetch(`${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/auth/refresh_token`, {
        //                 method: "POST",
        //                 headers: {
        //                     "Content-Type": "application/json",
        //                     'Accept': '*/*'
        //                 },
        //                 body: JSON.stringify({token: state.token})
        //             })
        //             .then(response => {
        //                 if(response.status===404 || response.status===401 || response.status===500) {
        //                     setTokenStatus("ACCESS_DENIED");
        //                 }
        
        //                 else if(response.status===200) {
        //                      response.json()
        //                      .then(data=>{
        //                         let action = AuthActionCreator.refresh_token(data.token);
        //                         dispatch(action);
        //                         setTokenStatus(true);
        //                      });
        //                 }
        //             })
        //             .catch(e => {
        //                 setTokenStatus("ACCESS_DENIED")
        //             })
        //         } else {
        //             setTokenStatus(true); //If the token is valid and doesnt need to be refreshed, we give the user access (not really true because token will be validated on server side on the first petition. We do this to avoid unnecessary requests to the server)
        //         }
        //     } catch (e) {
        //         setTokenStatus("ACCESS_DENIED");
        //     } 
        // } else {
        //     setTokenStatus("ACCESS_DENIED");
        // }

        setTokenStatus(true);           
    });

    return tokenStatus;
};

export default useCheckToken;