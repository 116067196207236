//THIS COMPONENT WAS CREATED BECAUSE EVERY VIEW NEEDED A FEW OPERATIONS SO, INSTEAD OF DOING IT IN EVERY VIEW, WE DO IT HERE AND THAT'S IT.
import '../assets/css/PageTemplate.scss';
import {useEffect } from 'react';
import FadeIn from '../components/FadeIn';

const PageTemplate = ({ignoreFade, children}) => {

    useEffect(() => {
        //Scroll removal on touch devices
        document.addEventListener('gesturestart', function (e) {
            e.preventDefault();
        });

        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant',
        });

        return () => {
            document.removeEventListener('gesturestart', function (e) {
                e.preventDefault();
            });
        }
    }, []);

    return (
        <>
            <div className="page-template-container">
                <FadeIn>
                    {children}
                </FadeIn>
            </div>
        </>
    );
};

export default PageTemplate;