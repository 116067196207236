//Receives data and initializes the Artwork form. Will be used for both editing and creating new artwork.
import '../assets/css/ArtworkFiller.scss';
import FadeIn from './FadeIn';
import { ChromePicker } from 'react-color';
import { Link } from 'react-router-dom';
import {useEffect, useRef, useState} from 'react';
import TagTemplate from './TagTemplate';
import NewArtworkAddImage from './NewArtworkAddImage';
import ErrorDivDisplayer from './ErrorDivDisplayer';
import NewArtworkAddAudio from './NewArtworkAddAudio';
import config from '../config/project.config.js'

const ArtworkFiller = ({artworkInfo, setArtworkInfo, formHandler, error, submitForm}) => {

    //Refs
    const techniqueInputRef = useRef();
    const techniqueInput = useRef();
    const submitButton = useRef();

    //Auxiliar states for audio and images
    const [audio, setAudio] = useState(artworkInfo.product_audio);
    const [images, setImages] = useState(artworkInfo.product_images);

    const [isFocused, setIsFocused] = useState(false);

    useEffect(()=>{
        setArtworkInfo(prevArtworkInfo => ({...prevArtworkInfo, product_audio: audio, product_images: images}));
    }, [images, audio])

    const isInOurTechniques = (technique) => {
        const techniques = config.artwork_registration.techniques;
        for(let i = 0; i < techniques.length; i++) {
            if(techniques[i] === technique) {
                return true;
            }
        }
        return false;
    }

    useEffect(()=>{
        if(!isInOurTechniques(artworkInfo.product_technique) && artworkInfo.product_technique !== "initial") {
            const isVisible = techniqueInputRef.current.classList.contains("show");
            techniqueInputRef.current.classList.add("show");

            if(!isVisible) { //If the input ws not visible, that means it's the first time we enter so we focus. If the useEffect is triggered just because of some isFocused change, we dont want to auto focus.
                techniqueInput.current.focus();
            }
           
        } else if(isFocused === false) {
            techniqueInputRef.current.classList.remove("show");
        }
    }, [artworkInfo.product_technique, isFocused]);

    useEffect(()=> {
        if(submitForm === true) {
            submitButton.current.click();
        }
    }, [submitForm])

    return (
        <>
            <FadeIn>
                <div className="new-artwork">
                    <Link to="/profile/gallery/artworks" className='new-artwork__back-link'>
                        <i className="fa-solid fa-chevron-left"></i>
                    </Link>
                    <h1 className="new-artwork__heading">REGISTER A NEW ARTWORK</h1>

                    <div className="new-artwork__wrap">
                        <form onSubmit={formHandler} className="new-artwork__form">
                            <p className="new-artwork__subheading">Artwork Details</p>

                            {/* PICTURE BLOCK  */}
                            <p className="new-artwork__label">Pictures*</p>
                            <NewArtworkAddImage images={images} setImages={setImages} />
                            <ErrorDivDisplayer attribute="product_images" error={error}/>

                            <p className="new-artwork__label">Title*</p>
                            <input type="text" name="product_name" autoComplete="off" className="new-artwork__input" value={artworkInfo.product_name} onChange={(e)=>{setArtworkInfo({...artworkInfo, [e.target.name]: e.target.value})}} />
                            <ErrorDivDisplayer attribute="product_name" error={error}/>

                            <p className="new-artwork__label">Artist*</p>
                            <input type="text" name="product_artist" autoComplete="off" className="new-artwork__input" value={artworkInfo.product_artist} onChange={(e)=>{setArtworkInfo({...artworkInfo, [e.target.name]: e.target.value})}}/>
                            <ErrorDivDisplayer attribute="product_artist" error={error}/>

                            <p className="new-artwork__label">Description*</p>
                            <textarea name="product_description" className="new-artwork__input textarea" value={artworkInfo.product_description} onChange={(e)=>{setArtworkInfo({...artworkInfo, [e.target.name]: e.target.value})}}></textarea>
                            <ErrorDivDisplayer attribute="product_description" error={error}/>

                            {/* Technique, theme, feeling and color groups */}
                            <div className="new-artwork__group">
                                <div className="new-artwork__group-item">
                                    <p className="new-artwork__label">Technique*</p>
                                    <div className="new-artwork__group-item-list">
                                        {
                                            config.artwork_registration.techniques.map((technique, index) => {
                                                return (
                                                    <p key={index} className={(artworkInfo.product_technique === technique) || (!isInOurTechniques(artworkInfo.product_technique) && artworkInfo.product_technique !== "initial" && technique === "Other") ? "new-artwork__group-item-list__item selected" : "new-artwork__group-item-list__item"} onClick={()=>{
                                                        //If we click a technique, we update the technique only if it's a normal one or it's the Other option but the technique we previously had is ours.
                                                        //Why? Because if we already had an special one we dont want to remove that value
                                                        if(technique !== "Other") {
                                                            setArtworkInfo({...artworkInfo, product_technique: technique});
                                                        } else if(isInOurTechniques(artworkInfo.product_technique) || artworkInfo.product_technique === "initial") {
                                                            setArtworkInfo({...artworkInfo, product_technique: ""});
                                                        }}}>{technique}</p>
                                                )
                                            })
                                        }                                   
                                    </div>
                                    <ErrorDivDisplayer attribute="product_technique" error={error}/>
                                </div>

                                <div className="new-artwork__group-item">
                                    <p className="new-artwork__label">Theme*</p>
                                    <div className="new-artwork__group-item-list">
                                        {
                                            config.artwork_registration.themes.map((theme, index) => {
                                                return (
                                                    <p key={index} className={artworkInfo.product_theme === theme ? "new-artwork__group-item-list__item selected" : "new-artwork__group-item-list__item"} onClick={()=>{setArtworkInfo({...artworkInfo, product_theme: theme});}}>{theme}</p>
                                                )
                                            })
                                        }                                   
                                    </div>
                                    <ErrorDivDisplayer attribute="product_theme" error={error}/>
                                </div>

                                <div className="new-artwork__group-item">
                                    <p className="new-artwork__label">Feeling*</p>
                                    <div className="new-artwork__group-item-list">
                                        {
                                            config.artwork_registration.feelings.map((feeling, index) => {
                                                return (
                                                    <p key={index} className={artworkInfo.product_feeling === feeling ? "new-artwork__group-item-list__item selected" : "new-artwork__group-item-list__item"} onClick={()=>{setArtworkInfo({...artworkInfo, product_feeling: feeling});}}>{feeling}</p>
                                                )
                                            })
                                        }                                   
                                    </div>
                                    <ErrorDivDisplayer attribute="product_feeling" error={error}/>
                                </div>

                                <div className="new-artwork__group-item">
                                    <p className="new-artwork__label">Color*</p>
                                    <div className="new-artwork__group-item-list">
                                        {
                                            config.artwork_registration.colors.map((color, index) => {
                                                return (
                                                    <p key={index} className={artworkInfo.product_color === color ? "new-artwork__group-item-list__item selected" : "new-artwork__group-item-list__item"} onClick={()=>{setArtworkInfo({...artworkInfo, product_color: color});}}>{color}</p>
                                                )
                                            })
                                        }                                   
                                    </div>
                                    <ErrorDivDisplayer attribute="product_color" error={error}/>
                                </div>
                            </div>

                            {/* Hidden input in case user wants to put another value */}
                            <div className="new-artwork__technique-input-container" ref={techniqueInputRef}>
                                <p className="new-artwork__label">Technique*</p>
                                <input ref={techniqueInput} onFocus={()=>{setIsFocused(true)}} onBlur={()=>{setIsFocused(false)}} type="text" name="product_technique" value={artworkInfo.product_technique} onChange={(e)=>{setArtworkInfo({...artworkInfo, [e.target.name]: e.target.value})}} autoComplete="off" className="new-artwork__input" />
                                <ErrorDivDisplayer attribute="product_technique" error={error}/>
                            </div>

                            {/* Year and size*/}
                            <div className="new-artwork__group year">
                                <div className="new-artwork__group-year">
                                    <p className="new-artwork__label">Year*</p>
                                    <input type="text" name="product_date" value={artworkInfo.product_date} onChange={(e)=>{setArtworkInfo({...artworkInfo, [e.target.name]: e.target.value})}} autoComplete="off" className="new-artwork__input" />
                                    <ErrorDivDisplayer attribute="product_date" error={error}/>
                                </div>

                                <div>
                                    <p className="new-artwork__label">Size*</p>
                                    <div className="new-artwork-size__inputs-container">
                                        <div className="new-artwork-size__input">
                                            <input type="text" name="product_width" value={artworkInfo.product_size.product_width} onChange={(e)=>{setArtworkInfo({...artworkInfo, product_size: {...artworkInfo.product_size, [e.target.name]: e.target.value }})}} autoComplete="off" className="new-artwork__input" />
                                        </div>

                                        <span> cm x </span>

                                        <div className="new-artwork-size__input">
                                            <input type="text" name="product_height" value={artworkInfo.product_size.product_height} onChange={(e)=>{setArtworkInfo({...artworkInfo, product_size: {...artworkInfo.product_size, [e.target.name]: e.target.value }})}} autoComplete="off" className="new-artwork__input" />
                                        </div>

                                        <span> cm</span>
                                    </div>
                                    <ErrorDivDisplayer attribute="product_size" error={error} includes={true}/>
                                </div>
                            </div>

                            {/* Audio */}
                            <div className="new-artwork__audio-container">
                                <p className="new-artwork__label">Audio (optional)</p>
                                <NewArtworkAddAudio audio={audio} setAudio={setAudio}/>
                                <ErrorDivDisplayer attribute="product_audio" error={error}/>
                            </div>

                            <p className="new-artwork__subheading">Price Details</p>

                            {/* Price */}
                            <div className="new-artwork-price__container">
                                <p className="new-artwork__label">Price*</p>
                                <input type="text" name="product_price" autoComplete="off" className="new-artwork__input" value={artworkInfo.product_price} onChange={(e)=>{setArtworkInfo({...artworkInfo, [e.target.name]: e.target.value})}} />
                                <ErrorDivDisplayer attribute="product_price" error={error}/>
                            </div>

                            {/* Tag */}
                            <div className="new-artwork__tag-container">
                                <div className="new-artwork__tag-displayer">
                                    <p className="new-artwork__label">Pricetag color*</p>
                                    <div className="new-artwork__price-tag__container">
                                        <TagTemplate color={artworkInfo.product_tag_color} price={artworkInfo.product_price}/>
                                    </div>
                                </div>

                                <div className="new-artwork__tag-color-picker">
                                    <ChromePicker color={artworkInfo.product_tag_color} onChange={(color, event)=>{setArtworkInfo({...artworkInfo, product_tag_color: color.rgb})}}/>
                                </div>
                            </div>

                            <p className="new-artwork__subheading">Shipping Details</p> 

                            <div>
                                <p className="new-artwork__label">Packed weight*</p>
                                <div className="new-artwork__packed-weight">
                                    <input type="text" name="product_packed_weight" value={artworkInfo.product_packed_weight} onChange={(e)=>{setArtworkInfo({...artworkInfo, [e.target.name]: e.target.value})}} autoComplete="off" className="new-artwork__input" />
                                    <span>kg</span>
                                </div>
                                <ErrorDivDisplayer attribute="product_packed_weight" error={error}/>
                            </div>

                            <div>
                                <p className="new-artwork__label">Packed size*</p>
                                <div className="new-artwork-size__inputs-container">
                                    <div className="new-artwork-size__input">
                                        <input type="text" name="product_packed_width" value={artworkInfo.product_packed_size.product_packed_width} onChange={(e)=>{setArtworkInfo({...artworkInfo, product_packed_size: {...artworkInfo.product_packed_size, [e.target.name]: e.target.value }})}} autoComplete="off" className="new-artwork__input" />
                                    </div>

                                    <span> cm x </span>

                                    <div className="new-artwork-size__input">
                                        <input type="text" name="product_packed_height" value={artworkInfo.product_packed_size.product_packed_height} onChange={(e)=>{setArtworkInfo({...artworkInfo, product_packed_size: {...artworkInfo.product_packed_size, [e.target.name]: e.target.value }})}} autoComplete="off" className="new-artwork__input" />
                                    </div>

                                    <span> cm</span>
                                </div>
                                <ErrorDivDisplayer attribute="product_packed_size" error={error} includes={true}/>
                            </div>

                            <div className="new-artwork__request-limited">
                                <span className="new-artwork__request-limited-text">Request limited edition</span>
                                <input className="new-artwork__request-limited-check" checked={artworkInfo.product_limited} onChange={(e)=>{setArtworkInfo({...artworkInfo,  [e.target.name]: e.target.checked})}} type="checkbox" name="product_limited"/>
                            </div>

                            <button type="submit" style={{display: 'none'}} ref={submitButton}></button>
                        </form>
                        
                    </div>

                </div>
            </FadeIn>
        </>
    );
};


export default ArtworkFiller;