import '../assets/css/ErrorDivDisplayer.scss';
import { useEffect, useRef } from 'react';
import Utils from '../utils';


const ErrorDivDisplayer = ({attribute, error, includes=null}) => {
    const errorDiv = useRef();

    useEffect(() => {
        if(error) {
            const condition = includes ? error.attribute.includes(attribute) : error.attribute === attribute;

            if(condition) {
                errorDiv.current.children[1].innerHTML = error.message;
                errorDiv.current.classList.add("show");
    
                const currentErrorDivSibling = errorDiv.current.previousElementSibling;
    
                if(currentErrorDivSibling.tagName === 'INPUT' || currentErrorDivSibling.tagName === 'TEXTAREA') {
                    currentErrorDivSibling.focus();
                }
    
                Utils.scrollToJustAbove(currentErrorDivSibling, 100);
            } else {
                errorDiv.current.classList.remove("show");
            }

        } else {
            errorDiv.current.classList.remove("show")
        }
    }, [error]);

    return (
        <>
            <div id={attribute} className="error-div__container" ref={errorDiv}>
                <span className="error-div__icon">
                    <i className="fa-solid fa-circle-info"></i>
                </span>
                <span className="error-div__text">
                </span>
            </div>
        </>
    );
};


export default ErrorDivDisplayer;