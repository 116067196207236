import '../assets/css/ShowroomMyArtworksMobileMenu.scss';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState, useRef, useEffect } from 'react';
import AdminHeaderAccordeon from './AdminHeaderAccordeon';

const ShowroomMyArtworksMobileMenu = ({show, setShow, accordeonState, setAccordeonState}) => {
    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const searchContainer = useRef(null);
    const submitForm = useRef(null);
    const searchInput = useRef();

    useEffect(()=>{
        if(show){
            if(displaySearchBar) {
                searchContainer.current.classList.add("active");
                searchInput.current.focus();
            } else {
                searchContainer.current.classList.remove("active");
            }
        }
    },[displaySearchBar]);

    const searchInMyArtworks = (e) => {
        e.preventDefault();
        if( searchInput.current.value.length > 0) {
            console.log("a buscar");
        }
    }

    return (
        <>
            <Offcanvas  show={show} onHide={()=>{setShow(false); setDisplaySearchBar(false)}} placement={'end'} className="my-artworks__offcanvas profile-mobilemenu__width">
                <Offcanvas.Header closeButton>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="my-artworks__mobile-menu-item">
                        <form onSubmit={searchInMyArtworks} className="artworks-header__search-container" ref={searchContainer} >
                            <input type="text" ref={searchInput} className="artworks-header__search-input" onBlur={()=>{
                                if(searchInput.current.value.length === 0) {
                                    setDisplaySearchBar(false);
                                }
                            }} placeholder='Search artwork...' />
                            <span className="artworks-header__search-icon" onClick={()=>{
                                if(displaySearchBar && searchInput.current.value.length > 0) {
                                    submitForm.current.click();
                                } else {
                                    setDisplaySearchBar(true);
                                }
                            }}>
                                <i className="fa-solid fa-magnifying-glass"></i>
                            </span>

                            <button style={{display: 'none'}} type="submit" ref={submitForm}></button>
                        </form>
                    </div>

                    <div className="my-artworks__mobile-menu-item">
                        <AdminHeaderAccordeon options={["Recently added", "Price: low to high", "Price: high to low", "A to Z", "Z to A"]} default_option={"Filter by"} optionRemoval={"No filters"} accordeonState={accordeonState} setAccordeonState={setAccordeonState}/>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};
   
export default ShowroomMyArtworksMobileMenu;