import '../assets/css/Notifications.scss';
import convertHtmlToReact from '@hedgedoc/html-to-react';
import { useState, useEffect, useRef } from 'react';
import {RiSearch2Line} from 'react-icons/ri';
import suarte_logo from '../assets/img/suarte_main_logo.png';
import alargada from '../assets/img/alargada.jpg';

import NotificationsList from './NotificationsList';

const Notifications = () => {
    const [notifications, setNotifications] = useState([{
        notification_id: 1,
        notification_image: suarte_logo, //have image template loading. Well just put a background image hehe
        notification_name: 'Nuno Sacramento ha enviado su solicitud',
        notification_preview: 'Acabamos de recibir la solicitud de registro de Nuno sacramento a través de tu link de referidos',
        notification_content: '',
        notification_date: '14:00',
        notification_status: 'seen'
    }, {
        notification_id: 2,
        notification_image: suarte_logo, //have image template loading. Well just put a background image hehe
        notification_name: '¿Nos conocemos?',
        notification_preview: 'Me llamo Pablo y soy el CEO de Suarte. Lorem ipsum  is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.',
        notification_content: '',
        notification_date: '4 sep',
        notification_status: 'unseen'
    }, ]); //This should be a useCache value! Leave it like this just for now
    const [notificationsToDisplay, setNotificationsToDisplay] = useState(null);
    const [openNotification, setOpenNotification] = useState(null); //Used to store the selected notification. 
    const searchContainer = useRef();

    const searchNotifications = (value) => {
        //Search notifications based on that value and set the new notifications to display. If value === "" return all notifications. 
        //We should hightlight the matches.
        //If notifications havent loaded, dont do anything!
    }

    useEffect(()=>{ //load notifications to display only when notifications are received
        setTimeout(()=>{
            setNotificationsToDisplay(notifications);
        }, 2000)
    }, [notifications]);

    const html = `<div className="invoices__container">
                    <div className="invoices__wrap">
                        <h1 className="invoices__title">Invoices</h1>

                        <div className="invoices__list">
                            <div className="invoices__item">
                                <p className="invoices__item-text">July 2022 <span>- Statement of account</span></p>
                                <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                    <span>Download PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                    </svg>
                                </a>
                            </div>

                            <div className="invoices__item">
                                <p className="invoices__item-text">June 2022 <span>- Statement of account</span></p>
                                <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                    <span>Download PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                    </svg>
                                </a>
                            </div>

                            <div className="invoices__item">
                                <p className="invoices__item-text">May 2022 <span>- Statement of account</span></p>
                                <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                    <span>Download PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                    </svg>
                                </a>
                            </div>

                            <div className="invoices__item">
                                <p className="invoices__item-text">April 2022 <span>- Statement of account</span></p>
                                <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                    <span>Download PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                    </svg>
                                </a>
                            </div>

                            <div className="invoices__item">
                                <p className="invoices__item-text">March 2022 <span>- Statement of account</span></p>
                                <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                    <span>Download PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                    </svg>
                                </a>
                            </div>

                            <div className="invoices__item">
                                <p className="invoices__item-text">February 2022 <span>- Statement of account</span></p>
                                <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                    <span>Download PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                    </svg>
                                </a>
                            </div>

                            <div className="invoices__item">
                                <p className="invoices__item-text">January 2022 <span>- Statement of account</span></p>
                                <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                    <span>Download PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                    </svg>
                                </a>
                            </div>

                            <div className="invoices__item">
                                <p className="invoices__item-text">December 2021 <span>- Statement of account</span></p>
                                <a href="/invoice_example.pdf" className="invoices__item-download" download>
                                    <span>Download PDF</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
                                        <path d="M11 40q-1.2 0-2.1-.9Q8 38.2 8 37v-7.15h3V37h26v-7.15h3V37q0 1.2-.9 2.1-.9.9-2.1.9Zm13-7.65-9.65-9.65 2.15-2.15 6 6V8h3v18.55l6-6 2.15 2.15Z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>    
                  </div>`;                 
    return (
        <>
            <div className="notifications__container">
                <div className="notifications__box">
                    <div className="notifications__notifications">
                        <div className="notifications__notifications-wrap">
                            <div className="notifications__search-container" ref={searchContainer}>
                                    <RiSearch2Line className="notifications__search-icon"/>
                                    <input onFocus={(e)=>{searchContainer.current.classList.add("active")}} onBlur={(e)=>{if(e.target.value.length === 0)searchContainer.current.classList.remove("active")}} 
                                        type="text" className="notifications__search-input" placeholder='Search'/>
                            </div>
                        </div>
                        <NotificationsList notifications={notificationsToDisplay} openNotification={openNotification} setOpenNotification={setOpenNotification}/>
                    </div>

                    {openNotification!==null && (<><h1>aqui tendra que ir el componente de notifications. Cuando se cierre, setear openNotification a null again</h1></>)}
                </div>
            </div>

            {/* {convertHtmlToReact(html)} */}
        </>
    );
};


export default Notifications;