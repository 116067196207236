import '../assets/css/Dashboard.scss';
import { motion } from 'framer-motion';

const Dashboard = () => {
    return (
        <>
            <motion.div
                initial={{ opacity: 0, x: 100 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -100 }}
                transition={{ duration: 1 }}
            >
                <div className="cajaLOL">
                </div>
            </motion.div>
        </>
    );
};


export default Dashboard;