import AuthActionCreator from "./auth.actions";
import GlobalActions from "./global.actions";
import CacheActions from "./cache.actions";
import ProfileActions from "./profile.actions";
import UIActionCreator from "./ui.actions";
import UserActions from "./user.actions";

const actions = {
    ...AuthActionCreator,
    ...GlobalActions,
    ...CacheActions,
    ...ProfileActions,
    ...UIActionCreator,
    ...UserActions,
}

export default actions;
