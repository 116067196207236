import '../assets/css/LimitedEditionPopup.scss';
import { useSelector } from 'react-redux/es/exports';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux/es/exports';
import UIActionCreator from '../actions/ui.actions';
import Popup from './Popup';

const LimitedEditionPopup = () => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    useEffect(()=>{
        return () => {
            dispatch(UIActionCreator.toggleOffLimitedEdition()) //In case we leave the view with limited edition popup open, we close it
        }
    }, []);

    return (
        <>
            <Popup
                show={state.limitedEdition}
                setShow={() => dispatch(UIActionCreator.toggleOffLimitedEdition())}
                className="limited-edition-popup"
            >
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis facilis inventore suscipit ipsam, accusantium deleniti cumque totam nihil harum itaque ipsa, aperiam exercitationem rem quos mollitia accusamus quis soluta a.</p> 
            </Popup>
        </>
    );
};

export default LimitedEditionPopup;