import '../assets/css/ArtworkList.scss';
import RemoveArtworkPopup from './RemoveArtworkPopup';
import {useState} from 'react';
import config from '../config/project.config';
import Utils from '../utils';
import { useNavigate } from 'react-router-dom';

const ArtworkList = ({artworks}) => {

    const [show, setShow] = useState(false);
    const [artworkToRemove, setArtworkToRemove] = useState(null);
    const files_server = config.files_server.url;
    const navigate = useNavigate();

    return (
        <>
            <div className="artwork-list">
                {
                    artworks.map((artwork, index) => {
                        return (
                            <div className={index % 2 === 0 ? "artwork-list__item" : "artwork-list__item colored"} key={index}>
                                <div className="artwork-list__pic-name">
                                    <img src={`${files_server}/artwork/${artwork.product_id}/0.large.webp`} alt="artwork preview" className="artwork-list__pic template" onLoad={(e)=>{e.target.classList.remove("template")}} />
                                    <img src={`${files_server}/artwork/${artwork.product_id}/0.large.webp`} alt="artwork preview" className="artwork-list__pic-alternative" />
                                    <p className="artwork-list__name">{artwork.product_name}</p>
                                </div>

                                <div className="artwork-list__separator"></div>

                                <p className="artwork-list__column">{artwork.metas.filter(meta => meta.meta_key === 'width')[0].meta_value} x {artwork.metas.filter(meta => meta.meta_key === 'height')[0].meta_value} cm</p>
                                <p className="artwork-list__column">${Utils.numberWithCommas(artwork.product_price)}</p>

                                <div className="artwork-list__group">
                                    <p className="artwork-list__column">{artwork.product_date}</p>
                                    <div className="artwork-list__buttons">
                                        <span className="artwork-list__button" onClick={()=>{navigate(`/profile/gallery/artworks/edit/${artwork.product_id}`)}}>
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path id="Icon_material-edit"
                                                    d="M.31,395.7v103.93H104.24L411.16,192.92l-103.93-103.93L.31,395.7ZM491.61,112.48c10.78-10.82,10.78-28.31,0-39.12L426.68,8.45c-10.82-10.78-28.31-10.78-39.12,0l-50.81,50.81,103.93,103.93,50.81-50.81,.12,.09h0Z"/>
                                            </svg>
                                        </span>

                                        <span className="artwork-list__button margin" onClick={()=>{setShow(true); setArtworkToRemove(artwork)}}>
                                            <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                                <path id="Icon_metro-cross"
                                                    d="M495.49,401.78h0l-151.78-151.78L495.49,98.22h0c6.02-6.02,6.02-15.96,0-21.98L423.76,4.51c-6.02-6.02-15.96-6.02-21.98,0h0L250,156.29,98.22,4.51h0c-6.02-6.02-15.96-6.02-21.98,0L4.51,76.24c-6.02,6.02-6.02,15.96,0,21.98h0L156.29,250,4.51,401.78h0c-6.02,6.02-6.02,15.96,0,21.98l71.73,71.73c6.02,6.02,15.96,6.02,21.98,0h0l151.78-151.78,151.78,151.78h0c6.02,6.02,15.96,6.02,21.98,0l71.73-71.73c6.02-6.02,6.02-15.96,0-21.98h0Z"/>
                                            </svg>
                                        </span>
                                    </div>
                                </div>  
                            </div>
                        )
                    })
                }
            </div>

            {artworkToRemove && (<RemoveArtworkPopup show={show} setShow={setShow} artwork={artworkToRemove}/>)}
        </>
    );
};


export default ArtworkList;