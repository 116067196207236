import config from '../config/project.config';

const ProfileActions =  {
    storeGalleryData: (gallery, user_id) => {
        return {
            type: '@profile/store_gallery_data',
            payload: {
                gallery_data: {
                    ...gallery,
                    gallery_pic: `${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}/user/${user_id}/profile.webp`
                }
            }
        }
    },

    updateGalleryData: (newData, user_id) => {
        return {
            type: '@profile/update_gallery_data',
            payload: {
                newData: {
                    ...newData,
                    gallery_pic: `${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}/user/${user_id}/profile.webp`
                }  
            }
        }
    },

    allowAccess: () => {
        return {
            type: '@profile/allow_access'
        }
    },

    denyAccess: () => {
        return {
            type: '@profile/deny_access'
        }
    }
}
  
  
  export default ProfileActions;