import '../assets/css/EditArtwork.scss';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useFetchWrapper from '../hooks/useFetchWrapper';
import config from '../config/project.config';
import { useNavigate } from "react-router-dom";
import LoadingPage from "../containers/LoadingPage";
import ArtworkFiller from './ArtworkFiller';
import Spinner from 'react-bootstrap/Spinner';


const EditArtwork = () => {
    const { artwork_id } = useParams();
    const location = useLocation();
    const fetchWrapper = useFetchWrapper();
    const [error, setError] = useState(null);
    const [submitForm, setSubmitForm] = useState(false);
    const [formState, setFormState] = useState("default");
    const api_url = `${config.backend_api.url}/product/${artwork_id}`;
    const files_url = `${config.files_api.url}/artwork/${artwork_id}`;
    const navigate = useNavigate();

    //Data structures to load info
    const [artworkInfo, setArtworkInfo] = useState(null);
    const [artworkFiles, setArtworkFiles] = useState(null);

    //Data structures to apply edit logic
    const [originalInfo, setOriginalInfo] = useState(null);
    const [editInfo, setEditInfo] = useState(null);


    //USE EFFECT TO LOAD ARTWORK INFO AND ARTWORK FILES
    useEffect(()=>{
        if(location.state) { //If we have a state, that means that we have cached the artwork info so there's no need to fetch it again
            setArtworkInfo(location.state);
        } else {
            fetchWrapper.get(api_url)
            .then(response => {
                if(response.status === 200) {
                    response.json()
                    .then(data => {
                        setArtworkInfo(data);
                    })
                } else if(response.status === 400) {
                    alert("Something went wrong. If the problem persists, please contact us.");
                } else if(response.status === 401 || response.status === 403) {
                    navigate("/login");
                } else if(response.status === 404) {
                    navigate("/404-not-found");
                } else if(response.status === 500) {
                    navigate("/500-internal-server-error");
                }
            })
            .catch(err=> navigate("/500-internal-server-error"));
        }

        fetchWrapper.get(files_url)
        .then(response => {
            if(response.status === 200) {
                response.json()
                .then(data => {
                    setArtworkFiles(data);
                })
            } else if(response.status === 400) {
                alert("Something went wrong. If the problem persists, please contact us.");
            } else if(response.status === 401 || response.status === 403) {
                navigate("/login");
            } else if(response.status === 404) {
                navigate("/404-not-found");
            } else if(response.status === 500) {
                navigate("/500-internal-server-error");
            }
        })
        .catch(err=> navigate("/500-internal-server-error"));
    }, []);

    useEffect(()=>{
        if(artworkInfo && artworkFiles) {
            //We set the info in the same format as the one in add artwork 
            //setOriginal and setStatic
        }
    }, [artworkInfo, artworkFiles]);

    useEffect(()=>{
        //this will check if edit info is different from original info
    }, [editInfo]);

    const editArtwork = (e) => {
        e.preventDefault();
        console.log("a editar")
    }

    return (
        <>
            {
                editInfo ?
                    <>
                        <ArtworkFiller artworkInfo={editInfo} setArtworkInfo={setEditInfo} formHandler={editArtwork} error={error} submitForm={submitForm}/>
                        <div className="new-artwork__center-button">
                            {
                                formState === "editing" ?
                                    <>
                                        <div className="new-artwork__loading-button">
                                            <Spinner animation="border" variant="dark" />
                                            <span>Edit artwork</span>
                                        </div>
                                    </>
                                :
                                formState === "default" ?
                                    <div className="new-artwork__loading-button">
                                        <span>Edit artwork</span>
                                    </div>
                                :
                                formState === "edited" ?
                                    <div className="new-artwork__loading-button">
                                        <span>Edited!</span>
                                    </div>
                                :
                                    <button className="new-artwork__add-artwork" onClick={()=>{setSubmitForm(true)}}>Edit artwork</button>
                            }
                        </div>
                    
                    </>
                :
                    <LoadingPage/>
            }
        </>
    );
};


export default EditArtwork;