import { useState, useEffect, useRef } from 'react';
import {useNavigate} from 'react-router-dom';
import useFetchWrapper from './useFetchWrapper';
import useGlobalState from './useGlobalState';

/**
 * 
 * Custom hook that fetches info and stores it into cache. If the info is already cached, it wont fetch.
 * 
 * @flag is a value that will control cache check. Cache will be checked whenever flag changes.
 * 
 */

const useCache = (attribute, url, flag=null) => {
    const [data, setData] = useState("LOADING");
    const globalState = useGlobalState();
    const ignoreUseEffect = useRef(true); //We use ref because it's synchronous
    const navigate = useNavigate();
    const fetchWrapper = useFetchWrapper();
    const cache = globalState.getCache();

    const checkCache = () => {
        if(globalState.isInCache(attribute)) {
            setData(globalState.getCacheValue(attribute));
        } else {
            fetchWrapper.get(url, {
                injectToken: true
            })
            .then(response => {
                if(response.status === 200) {
                    response.json()
                    .then(data => {
                        globalState.storeInCache(attribute, data)
                        setData(data);
                    })
                } else if(response.status === 400) {
                    alert("Something went wrong. If the problem persists, please contact us.");
                } else if(response.status === 401 || response.status === 403) {
                    navigate("/login");
                } else if(response.status === 404) {
                    navigate("/404-not-found");
                } else if(response.status === 500) {
                    navigate("/500-internal-server-error");
                }
            }) 
            .catch(err=> navigate("/500-internal-server-error"));
        }
    }

    useEffect(()=>{
        if(ignoreUseEffect.current === false && globalState.isCacheEmpty()) {
            //If we are here, that means that we have already cleared the cache, so we can do the fetching now
            ignoreUseEffect.current = true; 
            checkCache();
        }
    }, [cache]); 

    useEffect(()=>{
        setData("LOADING");
        if(globalState.isCacheExpired()) {
            ignoreUseEffect.current = false; 
            globalState.clearCache();
        } else {
            checkCache();
        }
    }, [flag]);

    return data;
}

export default useCache;