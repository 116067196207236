import '../assets/css/EditChangable.scss';
import Spinner from 'react-bootstrap/Spinner';
import SelectAccordeon from './SelectAccordeon';
import config from '../config/project.config';
import useUserId from '../hooks/useUserId';
import {useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useFetchWrapper from '../hooks/useFetchWrapper';
import ErrorDivDisplayer from './ErrorDivDisplayer';

const EditChangable = ({toggle, profileInfo, setNewInfo, error}) => {
    const FILES_API_URL = `${config.files_api.protocol}://${config.files_api.host}:${config.files_api.port}/profile/upload`;
    const fileInput = useRef(null);
    const submitImageForm = useRef(null);
    const user_id = useUserId();
    const navigate = useNavigate();
    const [imageState, setImageState] = useState("loaded");
    const fetchWrapper = useFetchWrapper();

    const uploadImage = (e) => {
        e.preventDefault();
        setImageState("loading");

        const oldPic = profileInfo.galleryInfo.gallery_pic;
        updateProfile("galleryInfo", "gallery_pic", 'loading');

        let imageBody = new FormData();
        imageBody.append('main_file', fileInput.current.files[0]);
        imageBody.append('user_id', user_id);

        fetchWrapper.post(FILES_API_URL, {body: imageBody, injectToken: true})
        .then(response => {
            if(response.status === 200) {
                response.json().then(data =>{
                    updateProfile("galleryInfo", "gallery_pic", `${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}${data.path}`);
                    setImageState("start_to_load");
                })
               
            } else {
                fileInput.current.value = null;
                updateProfile("galleryInfo", "gallery_pic", oldPic);
                setImageState("loaded");
                alert("Something went wrong while uploading the image");
            }
        })
        .catch(error => {setImageState("loaded"); fileInput.current.value = null; updateProfile("galleryInfo", "gallery_pic", oldPic); alert("Something went wrong while uploading the image");});  
    }

    const updateProfile = (type, key, value) => {
        setNewInfo({
            ...profileInfo,
            [type]: {
                ...profileInfo[type],
                [key]: value
            }
        })
    }

    const updateGalleryPhonePrefix = (value) => {
        updateProfile("galleryInfo", "gallery_phone_prefix", value);
    };

    const updateAgentHonorific = (value) => { 
        updateProfile("agentInfo", "agent_honorific", value);
    };

    const updateAgentPhonePrefix = (value) => {
        updateProfile("agentInfo", "agent_phone_prefix", value);
    };

    return (
        <>
            <div className="edit__changable-info">
                <form onSubmit={uploadImage} style={{display:'none'}}>
                    <input type="file" accept="image/*" name="edit_image" ref={fileInput}
                        onChange={(e)=>{
                            const file = e.target.files[0];
                            if(file && file.type.substring(0,5) === 'image') //Prevent users to remove the prop and upload other things
                                submitImageForm.current.click(); //We force the submit
                        }}/>
                    
                    <button type="submit" ref={submitImageForm}></button>
                </form>

                <div className="edit__change-pic-container">
                    {
                        imageState === "loaded" || imageState === "start_to_load" ?
                            <img src={`${profileInfo.galleryInfo.gallery_pic}`} onError={()=>{setImageState("no_pic_found"); fileInput.current.value = null;}} 
                             onLoad={()=>{setImageState("loaded")}} alt="gallery profile pic" className={imageState === "start_to_load" ? "edit__change-pic hide" : "edit__change-pic"}/>
                        :
                            null
                    }
                    {
                        imageState === "loading" || imageState === "start_to_load" ?
                            <>
                                <div className="edit__change-pic loading">
                                    <Spinner animation="border" variant="dark" />
                                </div>
                            </>
                        :
                        imageState === "no_pic_found" ?
                            <>
                                <div className="edit__change-pic add" onClick={()=>{fileInput.current.click()}}>
                                    <i className="pic fa-solid fa-image"></i>
                                    <i className="plus fa-solid fa-plus"></i>
                                </div>
                            </>
                        :
                        imageState === "loaded" ?
                            <>
                                <div className="edit__choose-pic" onClick={()=>{fileInput.current.click()}}>
                                    <svg id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                        <path
                                            d="M136.82,194.55c-6.21,11.46-9.91,23.8-11.72,36.61-3.74,26.52,.11,51.91,12.31,75.9,.42,.82,.34,2.55-.24,3.14-9.48,9.64-19.08,19.17-28.67,28.71-.15,.15-.45,.15-1.65,.51-4.26-8.71-9.12-17.35-12.82-26.47-12.93-31.87-15.72-64.77-8.69-98.46,3.59-17.23,10-33.38,18.74-48.62,.73-1.27,1.19-2.69,1.78-4.04,5.95-7.74,11.59-15.76,17.92-23.18,19.02-22.28,42.81-37.67,70.24-47.57,15.59-5.62,31.65-8.81,48.24-9.3,2.46-.07,4.91-.29,7.72-.46V19.78l78.59,78.77c2.4,2.41,2.41,6.3,0,8.71-25.68,25.73-51.89,51.99-78.29,78.45v-62.28c-6.22,.44-11.99,.49-17.66,1.31-29.13,4.19-53.94,17.05-74.19,38.38-8.8,9.28-16.41,19.57-21.61,31.42Z"/>
                                        <path
                                            d="M363.18,305.45c6.21-11.46,9.91-23.8,11.72-36.61,3.74-26.52-.11-51.91-12.31-75.9-.42-.82-.34-2.55,.24-3.14,9.48-9.64,19.08-19.17,28.67-28.71,.15-.15,.45-.15,1.65-.51,4.26,8.71,9.12,17.35,12.82,26.47,12.93,31.87,15.72,64.77,8.69,98.46-3.59,17.23-10,33.38-18.74,48.62-.73,1.27-1.19,2.69-1.78,4.04-5.95,7.74-11.59,15.76-17.92,23.18-19.02,22.28-42.81,37.67-70.24,47.57-15.59,5.62-31.65,8.81-48.24,9.3-2.46,.07-4.91,.29-7.72,.46v61.53l-78.59-78.77c-2.4-2.41-2.41-6.3,0-8.71,25.68-25.73,51.89-51.99,78.29-78.45v62.28c6.22-.44,11.99-.49,17.66-1.31,29.13-4.19,53.94-17.05,74.19-38.38,8.8-9.28,16.41-19.57,21.61-31.42Z"/>
                                    </svg>
                                </div>
                            </>
                        :
                            null
                    }
                </div>

                <div className="edit__change-forms-container">
                    <div className="edit__change-gallery-info">
                        <h1 className="edit__change-heading">GALLERY INFORMATION</h1>

                        <p className="edit__change-subheading">Gallery name</p>
                        <div className="edit__change-gallery-info-input-container">
                            <input type="text" id="gallery_name" onChange={(e)=>{updateProfile("galleryInfo", e.target.name, e.target.value)}} name="gallery_name" autoComplete="off" value={profileInfo.galleryInfo.gallery_name} className="edit-input" />
                            <div className="edit-input-border"></div>
                            <ErrorDivDisplayer attribute="user_name" error={error}/>
                        </div>

                        <p className="edit__change-subheading">Email</p>
                        <div className="edit__change-gallery-info-input-container">
                            <input type="text" id="email" onChange={(e)=>{updateProfile("galleryInfo", e.target.name, e.target.value)}} name="gallery_email" autoComplete="off" value={profileInfo.galleryInfo.gallery_email} className="edit-input" />
                            <div className="edit-input-border"></div>
                            <ErrorDivDisplayer attribute="user_email" error={error}/>
                            
                        </div>

                        <p className="edit__change-subheading">Phone number</p>
                        <div className="edit__change-input-group">
                            <div className="edit__change-select-container phone">
                                <SelectAccordeon options={config.prefixOptions} type={"PREFIX"} option={profileInfo.galleryInfo.gallery_phone_prefix} setOption={updateGalleryPhonePrefix} />
                            </div>
                            <div className="edit__change-phone-container">
                                <input type="text" id="phone" onChange={(e)=>{updateProfile("galleryInfo", e.target.name, e.target.value)}} name="gallery_phone" autoComplete="off" value={profileInfo.galleryInfo.gallery_phone} className="edit-input" />
                                <div className="edit-input-border"></div>
                            </div>
                        </div>
                        <ErrorDivDisplayer attribute="user_phone" error={error}/>

                        <p className="edit__change-subheading underline" onClick={()=>{toggle("business")}}>Business info</p>

                    </div>

                    <div className="edit__change-personal-info">
                        <h1 className="edit__change-heading">PERSONAL INFORMATION</h1>
                        <p className="edit__change-subheading">Last name</p>
                        <div className="edit__change-gallery-info-input-container">
                            <input type="text" id="last_name" onChange={(e)=>{updateProfile("agentInfo", e.target.name, e.target.value)}} name="agent_last_name" autoComplete="off" value={profileInfo.agentInfo.agent_last_name} className="edit-input" />
                            <div className="edit-input-border"></div>
                            <ErrorDivDisplayer attribute="agent_last_name" error={error}/>
                        </div>

                        <p className="edit__change-subheading">First name</p>
                        <div className="edit__change-input-group">
                            <div className="edit__change-select-container">
                                <SelectAccordeon options={config.firstNameOptions} option={profileInfo.agentInfo.agent_honorific} setOption={updateAgentHonorific}/>
                            </div>
                            <div className="edit__change-first-name-container">
                                <input type="text" id="first_name" onChange={(e)=>{updateProfile("agentInfo", e.target.name, e.target.value)}} name="agent_first_name" autoComplete="off" value={profileInfo.agentInfo.agent_first_name} className="edit-input" />
                                <div className="edit-input-border"></div>
                            </div>
                        </div>
                        <ErrorDivDisplayer attribute="agent_first_name" error={error}/>

                        <p className="edit__change-subheading">Phone number</p>
                        <div className="edit__change-input-group">
                            <div className="edit__change-select-container phone">
                                <SelectAccordeon options={config.prefixOptions} type={"PREFIX"} option={profileInfo.agentInfo.agent_phone_prefix} setOption={updateAgentPhonePrefix}/>
                            </div>
                            <div className="edit__change-phone-container">
                                <input type="text" id="phone" onChange={(e)=>{updateProfile("agentInfo", e.target.name, e.target.value)}} name="agent_phone" autoComplete="off" value={profileInfo.agentInfo.agent_phone} className="edit-input" />
                                <div className="edit-input-border"></div>
                            </div>
                        </div>
                        <ErrorDivDisplayer attribute="agent_phone" error={error}/>

                        <p className="edit__change-subheading underline" onClick={()=>{navigate("/forgot-password")}}>Change password</p>

                    </div>
                </div>
                
            </div>
        </>
    );
};


export default EditChangable;