import '../assets/css/Login.scss';
import LoginForm from '../components/LoginForm';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import { useState, useEffect, useRef } from 'react';

const Login = () => {
    const [slideDirection, setSlideDirection] = useState('');
    const slider = useRef();
    const loginHeight = useRef();
    const currentLoginHeight = useRef(0);

    const controlResizeHeight = () => {
        const height = window.innerHeight;

        //If the current height is superior, we scale it
        if (height > currentLoginHeight.current) {
            currentLoginHeight.current = height;
            loginHeight.current.style.height = height + "px";    
        } 
    };
    
    useEffect(() => {
        switch(slideDirection) {
            case 'left':
                slider.current.classList.remove('slide-right');
                break;
            case 'right':
                slider.current.classList.add('slide-right');
                break;
            default:
                break;
        }
    }, [slideDirection]);

    // Avoids mobile problem of vertical height being decreased with the display of the keyboard! This will keep the height of the form the same always
    useEffect(() => {
        loginHeight.current.style.height = window.innerHeight + "px";
        currentLoginHeight.current = window.innerHeight;

        //In order to control the height. This will help a little bug cause by the display of the keyboard on devices
        window.addEventListener('resize', controlResizeHeight);

        return () => {
            window.removeEventListener('resize', controlResizeHeight);
        }
    }, []);

    
    return (
        <>
            <div className="login-slider" ref={loginHeight}>

                <div className="login-slide" ref={slider}>

                    <div className="login-slider__item">
                        <LoginForm setSlide={setSlideDirection}/>
                    </div>
                    <div className="login-slider__item">
                        <ForgotPasswordForm setSlide={setSlideDirection}/>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Login;
