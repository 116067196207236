import '../assets/css/ShowroomAddAccordeon.scss';
import { useState, useRef, useEffect } from 'react';

const ShowroomAddAccordeon = () => {
    const addAccordeon = useRef();
    const [isOpen, setIsOpen] = useState(false);

    useEffect(()=>{
        if(isOpen) {
            addAccordeon.current.classList.add("active");
        } else {
            addAccordeon.current.classList.remove("active");
        }
    }, [isOpen]);
    return (
        <>
            <div tabIndex={0}  onClick={()=>{setIsOpen(!isOpen)}} onBlur={()=>{setTimeout(()=>{setIsOpen(false)}, 100)}} className="add-accordeon" ref={addAccordeon}>
                <div className="add-accordeon__icon">
                    <i className="fa-solid fa-plus"></i>
                </div>
                <div className="add-accordeon__control-overflow"></div>
                <div className="add-accordeon__options">
                    <div className="add-accordeon__options-wrap">
                        <p className="add-accordeon__options_option heading">Add from...</p>
                        <p className="add-accordeon__options_option">My artworks</p>
                        <p className="add-accordeon__options_option">Imported artworks</p>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ShowroomAddAccordeon;