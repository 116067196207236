import '../assets/css/NotFound.scss';

const NotFound = () => {
    return (
        <>
            <h1>ERROR 404: NOT FOUND</h1>
        </>
    );
};

export default NotFound;