import '../assets/css/Page.scss';
import usePageTitle from '../hooks/usePageTitle';

const Page = ({children}) => {
    usePageTitle();

    return (
        <>
            <div className="page__container">{children}</div>
        </>
    );
};


export default Page;