import '../assets/css/ShowroomMyArtworks.scss';
import { Link } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import AdminHeaderAccordeon from './AdminHeaderAccordeon';
import ArtworkList from './ArtworkList';
import ShowroomMyArtworksMobileMenu from './ShowroomMyArtworksMobileMenu';
import useCache from "../hooks/useCache";
import config from '../config/project.config';
import useUserId from '../hooks/useUserId';
import LoadingPage from "../containers/LoadingPage";
import useGlobalState from '../hooks/useGlobalState';

const ShowroomMyArtworks = () => {

    const [displaySearchBar, setDisplaySearchBar] = useState(false);
    const searchContainer = useRef(null);
    const submitForm = useRef(null);
    const searchInput = useRef();
    const [show, setShow] = useState(false);
    const user_id = useUserId();
    const cache = useGlobalState().getCache();
    const my_artworks = useCache("my_artworks", `${config.backend_api.url}/gallery/${user_id}/products`, cache);

    const [accordeonOption, setAccordeonOption] = useState({
        type: 'default'
    });

    useEffect(()=>{
        if(searchContainer.current) {
            if(displaySearchBar) {
                searchContainer.current.classList.add("active");
                searchInput.current.focus();
            } else {
                searchContainer.current.classList.remove("active");
            }
        }
    },[displaySearchBar]);

    const searchInMyArtworks = (e) => {
        e.preventDefault();
        if( searchInput.current.value.length > 0) {
            console.log("a buscar");
        }
    }
    
    return (
        <>
            {
                my_artworks && my_artworks !== "LOADING" ? 
                    <>
                        <div className="my-artworks__container">
                            <div className="my-artworks__wrap">
                                <div className="my-artworks__header">
                                    <Link to="new-artwork" className="new-artwork__link">
                                        <i className="fa-solid fa-plus"></i>
                                    </Link>

                                    <div className="my-artworks__header-right">
                                        <form onSubmit={searchInMyArtworks} className="artworks-header__search-container" ref={searchContainer} >
                                            <input type="text" ref={searchInput} className="artworks-header__search-input" onBlur={()=>{
                                                if(searchInput.current.value.length === 0) {
                                                    setDisplaySearchBar(false);
                                                }
                                            }} placeholder='Search artwork...' />
                                            <span className="artworks-header__search-icon" onClick={()=>{
                                                if(displaySearchBar && searchInput.current.value.length > 0) {
                                                    submitForm.current.click();
                                                } else {
                                                    setDisplaySearchBar(true);
                                                }
                                            }}>
                                                <i className="fa-solid fa-magnifying-glass"></i>
                                            </span>

                                            <button style={{display: 'none'}} type="submit" ref={submitForm}></button>
                                        </form>

                                        <div className="artworks-header__filter-container">
                                            <AdminHeaderAccordeon options={["Recently added", "Price: low to high", "Price: high to low", "A to Z", "Z to A"]} default_option={"Filter by"} optionRemoval={"No filters"} accordeonState={accordeonOption} setAccordeonState={setAccordeonOption}/>
                                        </div>

                                        
                                    </div>

                                    <span className="my-artworks__menu-mobile-button" onClick={()=>{setShow(true);}}>
                                        <i className="fa-solid fa-ellipsis"></i>
                                    </span>
                                    
                                </div>

                                <div className="my-artworks">
                                    <ArtworkList artworks={my_artworks}/>
                                </div>
                            </div>
                        </div>

                        <ShowroomMyArtworksMobileMenu show={show} setShow={setShow} accordeonState={accordeonOption} setAccordeonState={setAccordeonOption}/>
                    </>
                :
                    <LoadingPage color="white"/>
            }
        </>
    );
};


export default ShowroomMyArtworks;