import '../assets/css/SearchInput.scss';
import SearchSuggestions from './SearchSuggestions';
import { useState } from 'react';
import { useSelector } from 'react-redux/es/exports';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux/es/exports';
import UIActionCreator from '../actions/ui.actions';
import {motion, AnimatePresence} from 'framer-motion';

const SearchInput = () => {
    const [dynamicValue, setDynamicValue] = useState(null); //This will track what the user is currently writing
    const [searchValue, setSearchValue] = useState(null); //This will track what the user searched.
    const state = useSelector(state => state); 
    const dispatch = useDispatch();

    const searchReceived = (e) => {
        e.preventDefault();
        setSearchValue(e.target.search__input.value);
    }

    useEffect(()=>{
        if(dynamicValue && dynamicValue.length > 0) {
            dispatch(UIActionCreator.openSearchSuggestions());
        } else {
            dispatch(UIActionCreator.closeSearchSuggestions());
        }

    }, [dynamicValue]);

    return (
        <>
            <form onSubmit={searchReceived} className="search__form">
                <div className="search__input-container">
                    <input type="text" className="search__input" name="search__input" autoComplete="off" placeholder="Type anything" onChange={(e)=>{
                        setDynamicValue(e.target.value);
                    }} onFocus={()=>{
                        if(dynamicValue && dynamicValue.length > 0) {
                            dispatch(UIActionCreator.openSearchSuggestions());
                        }
                    }} onBlur={()=>{
                        dispatch(UIActionCreator.closeSearchSuggestions());
                    }}/>


                    <AnimatePresence>
                        {state.search_suggestions && <SearchSuggestions value={dynamicValue}/>}
                    </AnimatePresence>
                   
                </div>

                <button type="submit" className="search__button">
                    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                        <g id="b">
                            <path d="M350.79,342.08c2.55,1.56,5.18,2.56,6.99,4.37,45.71,45.56,91.33,91.21,136.97,136.85,3.61,3.61,7.54,7.21,3.61,12.83-2.98,4.24-6.9,5.1-11.26,2.08-1.59-1.1-2.86-2.66-4.24-4.04-45.64-45.63-91.29-91.26-136.89-136.93-1.83-1.83-3.22-4.1-5.2-6.66-54.26,48.57-116.44,65.07-185.82,49.56-52.3-11.69-93.17-42-122.35-86.79C-20.77,231.48-8.18,118.28,67.25,51.15c77.25-68.74,191.3-66.44,265.2-3.67,43.84,37.25,67.98,85,71.71,142.53,3.7,57.12-12.83,107.59-53.37,152.06Zm38.97-138.97c-1.52-103.22-78.69-187.7-187.38-187.75C96.23,15.32,14.35,95.84,14.41,202.94c.05,106.86,81.53,187.13,187.48,187.43,107.57,.31,185.97-82.88,187.87-187.26Z" />
                        </g>
                    </svg>
                </button>
            </form>
        </>
    );
};


export default SearchInput;