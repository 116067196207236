import PageTemplate from './PageTemplate';
import Navbar from '../components/Navbar';
import MasonryGallery from '../components/MasonryGallery';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import UIActionCreator from '../actions/ui.actions';
import useFetch from "../hooks/useFetch";
import useUserId from "../hooks/useUserId";
import config from '../config/project.config';
import MasonryGalleryTemplate from '../components/MasonryGalleryTemplate';

const SuarteWorld = () => {
    const dispatch = useDispatch();
    const user_id = useUserId();
    const suarteworld_artworks = useFetch(`${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/gallery/${user_id}/suarteworld`);
    

    useEffect(()=>{
        dispatch(UIActionCreator.enterView('suarteworld'));
    }, []);

    return (
        <>
            <PageTemplate>
                {
                    suarteworld_artworks && suarteworld_artworks !== "LOADING" ?
                        <>
                            <Navbar type="SUARTEWORLD" />
                            <MasonryGallery artworks={suarteworld_artworks}/>
                        </>
                    :
                        <>
                            <Navbar type="TEMPLATE"/>
                            <MasonryGalleryTemplate/>
                        </>  
                } 
            </PageTemplate>
        </>
    );
};

export default SuarteWorld;

