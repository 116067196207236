import '../assets/css/Tag.scss';
import { useEffect } from 'react';
import { useRef } from 'react';
import Utils from '../utils';
import { useDispatch } from 'react-redux';
import UserActions from '../actions/user.actions';
import useIsInCart from '../hooks/useIsInCart';

//price, width, height, status, type, color

const Tag = ({artwork}) => {
    //SVG programatic values
    const digits = ("" + artwork.product_price).length;
    const svgViewBox = digits < 4 ? 4 * 100 : digits === 4 ? 5*100 :  digits * 100;
    const svgH = svgViewBox - 10;

    //SVG container div variables
    const labelContainerWidth = (svgViewBox / 10);
    let   tagLabelWidth = "" + labelContainerWidth + "%";
    const tagLabel = useRef();
    const ALFA_VALUE = 3.8; //HARDCODED VALUE CALCULATED

    //PRICE POSITION VARIABLES
    const priceWithCommas = Utils.numberWithCommas(artwork.product_price);
    const priceSpan = useRef();
    const svgContainer = useRef();
    const circle = useRef();

    const dispatch = useDispatch();
    const isInCart = useIsInCart(artwork.product_id, artwork.product_type);
    
    useEffect(()=> {
        //Event listener for resize
        window.addEventListener('resize', adjustLabelWidth);

        if(digits > 6) {
            tagLabel.current.classList.add("tag-million");
        }

        //MEDIA QUERY IN JAVASCRIPT TO DETERMINE ALFA_VALUE!
        if (window.innerWidth <= 868) {
            tagLabel.current.style.maxWidth = "" + (labelContainerWidth*(ALFA_VALUE*0.8)) + "px";
        } else {
            tagLabel.current.style.maxWidth = "" + (labelContainerWidth*ALFA_VALUE) + "px";  
        }

        //Price SPAN SIZE ADJUSTMENT (we want it to be the same as svg)
        priceSpan.current.style.width = "" + (svgContainer.current.clientWidth - 8) + "px"; //8 px is the margin-left 
        priceSpan.current.style.height = "" + svgContainer.current.clientHeight + "px";

        //Label colors based on gallery choice
        svgContainer.current.style.fill = `rgba(${artwork.product_tag_color.r}, ${artwork.product_tag_color.g}, ${artwork.product_tag_color.b}, 0.2)`;
        svgContainer.current.style.stroke = `rgba(${artwork.product_tag_color.r}, ${artwork.product_tag_color.g}, ${artwork.product_tag_color.b}, 1)`;

        //This will be check in cart condition
        if(isInCart) {
            priceSpan.current.classList.add("already-in-cart");
            svgContainer.current.style.fill = `rgba(${artwork.product_tag_color.r}, ${artwork.product_tag_color.g}, ${artwork.product_tag_color.b}, 1)`;
            circle.current.classList.add("label-selected");
        }

        if(artwork.product_status === "sold" || artwork.product_status === "reserved") {
            priceSpan.current.classList.add("disabled");
            svgContainer.current.style.fill = `rgba(${artwork.product_tag_color.r}, ${artwork.product_tag_color.g}, ${artwork.product_tag_color.b}, 1)`;
            circle.current.classList.add("label-selected");
        }

        return () => {
            window.removeEventListener('resize', adjustLabelWidth);
        }
    }, [artwork]);

    function adjustLabelWidth() {
        if (window.innerWidth <= 868) {
            tagLabel.current.style.maxWidth = "" + (labelContainerWidth*(ALFA_VALUE*0.8)) + "px";
        } else {
            tagLabel.current.style.maxWidth = "" + (labelContainerWidth*ALFA_VALUE) + "px"; 
        }

        //Price SPAN SIZE ADJUSTMENT (we want it to be the same as svg)
        priceSpan.current.style.width = "" + (svgContainer.current.clientWidth - 8) + "px"; //8 px is the margin-left
        priceSpan.current.style.height = "" + svgContainer.current.clientHeight + "px";
    }

    const addToCart = (event) =>{
        if(!priceSpan.current.classList.contains("already-in-cart") && !priceSpan.current.classList.contains("clicked")) {
            priceSpan.current.classList.remove("removed");
            priceSpan.current.classList.add("clicked");

            dispatch(UserActions.addToCart(artwork.product_cart_details));

        } else if(priceSpan.current.classList.contains("already-in-cart") || priceSpan.current.classList.contains("clicked")) {
            priceSpan.current.classList.remove("clicked");
            priceSpan.current.classList.remove("already-in-cart");
            priceSpan.current.classList.add("removed");

            dispatch(UserActions.removeFromCart(artwork.product_cart_details));
        }
    }

    return (
        <>
            <div className="tag-container">          
                <svg className="cuerda" id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.72 82.22">
                    <path d="M1.07,75.54l-.02-23.36c.92,0,91.96-.11,190.5-9.64,71.75-6.94,145.2-13.72,185.72-13.72v23.35c-39.48,0-112.28,6.72-183.47,13.61C94.1,75.43,2,75.54,1.07,75.54Z"/>
                    <path d="M377.23,60.34c-16.88-.07-42.38-2.3-67.05-4.45-16.41-1.43-33.39-2.92-47.36-3.72C127.12,44.34,2.27,36.07,1,35.98l.06-23.4c1.26,.08,127.5,8.45,263.1,16.26,14.32,.83,31.47,2.32,48.05,3.77,24.19,2.11,49.21,4.3,65.12,4.37l-.1,23.35Z"/>
                    <path d="M446.25,45.23c-2.61,0-5.23-.87-7.4-2.65-38.89-31.92-71.43-12.12-72.79-11.26-5.43,3.41-12.63,1.83-16.09-3.57-3.45-5.4-1.95-12.55,3.43-16.05,1.93-1.26,47.8-30.22,100.27,12.83,4.98,4.09,5.71,11.45,1.62,16.43-2.31,2.81-5.66,4.27-9.03,4.27Z"/>
                    <path d="M369.13,81.22c-4.34,0-7.03-.2-7.33-.22-6.43-.5-11.23-6.12-10.73-12.55,.5-6.42,6.13-11.2,12.53-10.73,.27,.03,15.68,.61,34.29-4.31,5.96-1.58,15.14-5.15,21.07-6.91,14.86-4.41,23.39-4.73,30.08-4.73s11.68,5.23,11.68,11.68-5.23,11.68-11.68,11.68c-3.64,0-14.41,1.96-23.65,4.7-6.09,1.81-13.11,5.13-19.41,6.79-14.77,3.9-28.72,4.61-36.86,4.61Z"/>
                </svg>

                <div className="tag-label" style={{width: tagLabelWidth}} ref={tagLabel}>
                    <div ref={priceSpan} className="tag__price" onMouseEnter={()=>{
                        svgContainer.current.style.fill = `rgba(${artwork.product_tag_color.r}, ${artwork.product_tag_color.g}, ${artwork.product_tag_color.b}, 1)`;
                        circle.current.classList.add("label-selected");
                    }} onMouseLeave={()=>{
                        if(!priceSpan.current.classList.contains("clicked") && !priceSpan.current.classList.contains("already-in-cart") && !priceSpan.current.classList.contains("disabled")) {
                            svgContainer.current.style.fill = `rgba(${artwork.product_tag_color.r}, ${artwork.product_tag_color.g}, ${artwork.product_tag_color.b}, 0.2)`;
                            circle.current.classList.remove("label-selected");
                        }
                    }} onClick={artwork.product_status==="available" ? addToCart : null}>
                        
                        <span className="tag__price-price">{
                            artwork.product_status==="available" ?
                                <>
                                    ${priceWithCommas}
                                </>
                            :
                            artwork.product_status==="reserved" ? 
                                    "Reserved"
                            :
                            artwork.product_status==="sold" ?
                                artwork.product_type === "Original" ? 
                                    "Sold"
                                :
                                    "Sold out" 
                            :  
                                ""
                        }</span>
                       

                        <span className="tag__price-added">Added</span>

                        <i className="fas fa-shopping-cart tag__price-cart"></i>
                       
                        <i className="fas fa-box tag__price-box"></i>
        
                    </div>

                    <svg ref={svgContainer} className="tag" xmlns="http://www.w3.org/2000/svg" viewBox={`0 0 ${svgViewBox} 250`}>
                        <g id="a">
                            <g id="b" transform="translate(1.5 1.5)">
                                <path id="c" d={`M 0, 50
                                                L 100, 10
                                                H ${svgH} 
                                                V 240
                                                  H 100
                                                L 0, 200
                                                V 50
                                                Z`} />
                            </g>
                        </g>
                        <g id="circle" ref={circle} className="tag-circle">
                            <circle cx="61.62" cy="128.59" r="19.79"/>
                        </g>
                    </svg>
                </div>

                <span className="tag__dimensions">{artwork.product_size.product_width} x {artwork.product_size.product_height} cm</span>
            </div>
        </>
    );
};


export default Tag;