import '../assets/css/TagTemplate.scss';
import { useEffect, useState, useRef } from 'react';
import Utils from '../utils';

const TagTemplate = ({color, price}) => {
    const svgContainer = useRef();
    const circle = useRef();

    useEffect(()=>{
        //Label colors based on gallery choice
        svgContainer.current.style.fill = `rgba(${color.r}, ${color.g}, ${color.b}, 0.2)`;
        svgContainer.current.style.stroke = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
    }, [color])

    return (
        <>
            <div className="tag-template__container">
                <svg className="tag-template__cuerda" id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 461.72 82.22">
                    <path d="M1.07,75.54l-.02-23.36c.92,0,91.96-.11,190.5-9.64,71.75-6.94,145.2-13.72,185.72-13.72v23.35c-39.48,0-112.28,6.72-183.47,13.61C94.1,75.43,2,75.54,1.07,75.54Z"/>
                    <path d="M377.23,60.34c-16.88-.07-42.38-2.3-67.05-4.45-16.41-1.43-33.39-2.92-47.36-3.72C127.12,44.34,2.27,36.07,1,35.98l.06-23.4c1.26,.08,127.5,8.45,263.1,16.26,14.32,.83,31.47,2.32,48.05,3.77,24.19,2.11,49.21,4.3,65.12,4.37l-.1,23.35Z"/>
                    <path d="M446.25,45.23c-2.61,0-5.23-.87-7.4-2.65-38.89-31.92-71.43-12.12-72.79-11.26-5.43,3.41-12.63,1.83-16.09-3.57-3.45-5.4-1.95-12.55,3.43-16.05,1.93-1.26,47.8-30.22,100.27,12.83,4.98,4.09,5.71,11.45,1.62,16.43-2.31,2.81-5.66,4.27-9.03,4.27Z"/>
                    <path d="M369.13,81.22c-4.34,0-7.03-.2-7.33-.22-6.43-.5-11.23-6.12-10.73-12.55,.5-6.42,6.13-11.2,12.53-10.73,.27,.03,15.68,.61,34.29-4.31,5.96-1.58,15.14-5.15,21.07-6.91,14.86-4.41,23.39-4.73,30.08-4.73s11.68,5.23,11.68,11.68-5.23,11.68-11.68,11.68c-3.64,0-14.41,1.96-23.65,4.7-6.09,1.81-13.11,5.13-19.41,6.79-14.77,3.9-28.72,4.61-36.86,4.61Z"/>
                </svg>

                <div className="tag-template__price"  onMouseEnter={()=>{
                        svgContainer.current.style.fill = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
                        circle.current.classList.add("label-selected");
                    }} onMouseLeave={()=>{
                        svgContainer.current.style.fill = `rgba(${color.r}, ${color.g}, ${color.b}, 0.2)`;
                        circle.current.classList.remove("label-selected");
                    }}>
                    <svg ref={svgContainer} className="tag-template__svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 253.89">
                        <g id="a">
                            <g id="b">
                                <path id="c" d="M8.4,63.65L103.38,13.66H486.9V240.46H103.38L8.4,189.22V63.65Z"/>
                            </g>
                        </g>
                        <g ref={circle} className="tag-template__svg-circle" id="d">
                            <circle cx="61.62" cy="128.59" r="19.79" />
                        </g>
                    </svg>

                    <span className="tag-template__price-text">{price ? `$${Utils.numberWithCommas(price)}` : null}</span>
                </div>
                
            </div>
        </>
    );
};


export default TagTemplate;