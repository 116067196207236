import '../assets/css/PriceSection.scss';
import Tag from './Tag';
import { useDispatch } from 'react-redux/es/exports';
import UIActionCreator from '../actions/ui.actions';
import config from '../config/project.config';

const PriceSection = ({artworkDetails}) => {
    const dispatch = useDispatch();

    return (
        <>
            <div className="tags-container">
                <h1 className="tag-section__heading margin-tag">ORIGINAL</h1>

                <Tag
                    artwork={{
                        product_id: artworkDetails.product_id, 
                        product_price: artworkDetails.product_price,
                        product_size: {
                            product_width: artworkDetails.metas.filter(meta => meta.meta_key === 'width')[0].meta_value,
                            product_height: artworkDetails.metas.filter(meta => meta.meta_key === 'height')[0].meta_value
                        },
                        product_status: artworkDetails.metas.filter(meta => meta.meta_key === "status")[0].meta_value,
                        product_tag_color: JSON.parse(artworkDetails.metas.filter(meta => meta.meta_key === "color_tag")[0].meta_value),
                        product_type: "Original",
                        product_cart_details: { //THIS WILL CONTAIN EVERYTHING THAT I NEED IN THE CART, THIS IS BASICALLY WHAT I WILL STORE IN CART OBJECT
                            product_id: artworkDetails.product_id, 
                            product_pic: `${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}/artwork/${artworkDetails.product_id}/small.webp`,
                            product_name: artworkDetails.product_name,
                            product_artist: artworkDetails.metas.filter(meta => meta.meta_key === 'artist')[0].meta_value ?? 'Unknown artist',
                            product_price: artworkDetails.product_price,
                            product_size: {
                                product_width: artworkDetails.metas.filter(meta => meta.meta_key === 'width')[0].meta_value,
                                product_height: artworkDetails.metas.filter(meta => meta.meta_key === 'width')[0].meta_value
                            },
                            product_type: "Original",
                            product_quantity: 1,
                            product_original_id: artworkDetails.product_id,
                        }
                    }} 
                />

                {
                    artworkDetails.limited_editions.length > 0 ?
                        <>
                            <div className="tags-limited-container margin-tag">
                                <h1 className="tag-section__heading">LIMITED EDITION</h1>
                                <i className="fa-solid fa-circle-info" onClick={() => {
                                    dispatch(UIActionCreator.toggleOnLimitedEdition());
                                }}>
                                </i>
                            </div>

                            {
                                artworkDetails.limited_editions.map((limitedEdition, index) => {
                                    return (
                                        <Tag
                                            key={index} 
                                            artwork={{
                                                product_id: limitedEdition.limited_id, 
                                                product_price: limitedEdition.limited_price,
                                                product_size: {
                                                    product_width: limitedEdition.limited_width,
                                                    product_height: limitedEdition.limited_height
                                                },
                                                product_status: limitedEdition.limited_max_quantity <= limitedEdition.limited_produced_quantity ? "sold" : "available",
                                                product_tag_color: JSON.parse(artworkDetails.metas.filter(meta => meta.meta_key === "color_tag")[0].meta_value),
                                                product_type: "Limited edition",
                                                product_cart_details: { //THIS WILL CONTAIN EVERYTHING THAT I NEED IN THE CART, THIS IS BASICALLY WHAT I WILL STORE IN CART OBJECT                                                                                                                                                                                                     
                                                    product_id: limitedEdition.limited_id,
                                                    product_pic: `${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}/artwork/${artworkDetails.product_id}/small.webp`, 
                                                    product_name: artworkDetails.product_name,
                                                    product_artist: artworkDetails.metas.filter(meta => meta.meta_key === 'artist')[0].meta_value ?? 'Unknown artist',
                                                    product_price: limitedEdition.limited_price,
                                                    product_size: {
                                                        product_width: limitedEdition.limited_width,
                                                        product_height: limitedEdition.limited_height
                                                    },
                                                    product_type: "Limited edition",
                                                    product_quantity: 1,
                                                    product_max_quantity: limitedEdition.limited_max_quantity - limitedEdition.limited_produced_quantity,
                                                    product_original_id: artworkDetails.product_id,
                                                    product_limited_id: limitedEdition.limited_id
                                                }
                                            }} 
                                        />
                                    );
                                })
                            }
                        </>
                    : 
                        null
                }
            </div>
        </>
    );
};


export default PriceSection;