import '../assets/css/SearchFilter.scss';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux/es/exports';
import UIActionCreator from '../actions/ui.actions';

const SearchFilter = ({color, type, filterOptions}) => {
    const dispatch = useDispatch();
    const filterContainer = useRef();
    const filterAccordeon = useRef();
    const [activeFilter, setActiveFilter] = useState(null);

    useEffect(() => {
        if(activeFilter) {
            activeFilter.classList.add('active');
        }
    }, [activeFilter]);

    return (
        <>
            <div className="search-filter-container" ref={filterContainer}>
                <div className="search-filter__option-outter" style={{backgroundColor:color}}>
                    <div className="search-filter__option-inner" onClick={(e)=>{
                        if(filterContainer.current) {
                            if(!filterContainer.current.classList.contains("active")) {
                                filterAccordeon.current.scrollTop = 0;
                            }

                            filterContainer.current.classList.toggle('active');
                        }
                        
                    }}>
                        {type}
                    </div>
                </div>

                <div className="search-filter__options" ref={filterAccordeon} style={{borderLeft: `3px solid ${color}`}}>
                    {
                        filterOptions.map((option, index) => {
                            return (
                                <p key={index} className="search-filter__options-item" onClick={(e)=>{
                                    if(activeFilter) {
                                        if(activeFilter !== e.target) {
                                            activeFilter.classList.remove('active');
                                            dispatch(UIActionCreator.removeFilter(type, activeFilter.innerText));
                                            setActiveFilter(e.target);
                                            dispatch(UIActionCreator.addFilter(type, option));
                                        }
                                    } else {
                                        setActiveFilter(e.target);
                                        dispatch(UIActionCreator.addFilter(type, option));
                                    }
                                }}>{option}</p>
                            )
                        })
                    }
                  
                </div>


            </div>
        </>
    );
};


export default SearchFilter;