const AuthActionCreator =  {
  login: (data) => {
    return {
      type: '@auth/login',
      payload : {
          token: data.token,
          gallery_data: data.gallery
      }
    }
  },

  logout: () => {
    return {
      type: '@auth/logout',
    }
  },

  refresh_token: (newToken) => {
    return {
      type: '@auth/refresh_token',
      payload: {
          token: newToken,
      }
    }
  }
}


export default AuthActionCreator;