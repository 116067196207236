import '../assets/css/SearchFilters.scss';
import SearchFilter from './SearchFilter';
import config from '../config/project.config';

const SearchFilters = () => {
    return (
        <>
            <div className="search-filters-container">
                {
                    config.filters.map((filter, index)=>{
                        return (
                            <SearchFilter key={index} {...filter} />
                        )
                    })
                }
            </div>
        </>
    );
};


export default SearchFilters;