import '../assets/css/EditBusinessInfo.scss';
import { useNavigate } from 'react-router-dom';

const EditBusinessInfo = ({businessInfo}) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="edit__business-info">
                <h1 className="business__heading">Business info</h1>
                <div className="business-info__wrap">
                    <p className="business-info__subheading">Username</p>
                    <div className="business-info__info-section">{businessInfo.gallery_username}</div>

                    <p className="business-info__subheading">NIT</p>
                    <div className="business-info__info-section">{businessInfo.gallery_nit}</div>

                    <div className="business-info__group">
                        <div className="business-info__group-item">
                             <p className="business-info__subheading">Country</p>
                            <div className="business-info__info-section">{businessInfo.gallery_country}</div>
                        </div>

                        <div className="business-info__group-item">
                             <p className="business-info__subheading">Region/Province</p>
                            <div className="business-info__info-section">{businessInfo.gallery_region}</div>
                        </div> 
                    </div>

                    <div className="business-info__group">
                        <div className="business-info__group-item">
                             <p className="business-info__subheading">City</p>
                            <div className="business-info__info-section">{businessInfo.gallery_city}</div>
                        </div>

                        <div className="business-info__group-item">
                             <p className="business-info__subheading">Postal code</p>
                            <div className="business-info__info-section">{businessInfo.gallery_postal_code}</div>
                        </div> 
                    </div>

                    <p className="business-info__subheading">Address</p>
                    <div className="business-info__info-section">{businessInfo.gallery_address}</div>
                </div>
                <div className="business-info__warning-container">
                    <p className="business-info__warning-text">In case this information needs to be updated, please <span className="business-info__warning-contact-us" onClick={()=>{navigate("/profile/support/contact")}}>contact us</span> </p>
                </div>
            </div>
        </>
    );
};


export default EditBusinessInfo;