import PageTemplate from './PageTemplate';
import '../assets/css/Search.scss';
import Navbar from '../components/Navbar';
import SearchInput from '../components/SearchInput';
import SearchFilters from '../components/SearchFilters';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import UIActionCreator from '../actions/ui.actions';
import { useSelector } from 'react-redux';

const Search = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);


    useEffect(()=>{
        dispatch(UIActionCreator.enterView('search'));
        dispatch(UIActionCreator.clearFilters());
    }, []);

    return (
        <>
            <PageTemplate>
                <Navbar type="SEARCH"/>
                <div className="search__wrap">
                    <SearchInput />
                    <SearchFilters />
                    
                </div>
            </PageTemplate>
     
        </>
    );
};

export default Search;