import '../assets/css/ArtworkBodySlider.scss';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import UIActionCreator from '../actions/ui.actions';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import config from '../config/project.config.js';

const ArtworkBodySlider = ({artworkImages, product_id}) => {
    const dispatch = useDispatch();
    const controlsContainer = useRef();
    const [controls, setControls] = useState(null);
    const [currentControl, setCurrentControl] = useState(null)
    const [artworkSlider, setArtworkSlider] = useState(null);
    const files_server = `${config.files_server.url}/artwork/${product_id}`

    //THE FOLLOWING THREE USEEFFECTS ARE FOR INITIALIZATION
    useEffect(()=>{
        if(controlsContainer.current) {
            setControls(controlsContainer.current.children);
        }
    }, []);

    useEffect(()=>{
        if(!currentControl && controls) {
            setCurrentControl(controls[0]);
        }
    }, [controls]);

    useEffect(()=> {
        if(currentControl) {
            currentControl.classList.add('active');
        }
    }, [currentControl]);

    const getControlIndex = (control) => {
        return [...controls].indexOf(control);
    }

    return (
        <>
            <div className="artwork-slider-container">
                <Swiper 
                    autoHeight={true}
                    simulateTouch={false}
                    onInit={(swiper)=>{ //BEST WAY TO GET SLIDER REFERENCE
                        setArtworkSlider(swiper); 
                    }}
                    onSlideChange={(swiper)=>{
                        if(currentControl) {
                            currentControl.classList.remove('active');
                            setCurrentControl(controls[swiper.activeIndex]);
                        }
                    }}
                    className="artwork-slider">
                        {
                            artworkImages.map((image, index) => {
                                return (
                                    //TODO: CHANGE FOR DATABASE ID
                                    <SwiperSlide key={index}>
                                        <div className="artwork-slider__item">
                                            <img src={`${files_server}/${image.original}`} alt="artwork image" className="artwork-slider__item-image"  loading="lazy" onClick={()=>{
                                                dispatch(UIActionCreator.openArtworkZoom(`${files_server}/${image.dzi}`));
                                            }}/>
                                        </div> 
                                    </SwiperSlide>
                                )
                            })
                        }
                </Swiper>

            
                <div className="artwork-slider__controls" ref={controlsContainer}>
                    {
                        artworkImages.map((image, index) => {
                            return (
                                //TODO: CHANGE FOR DATABASE ID
                                <div className="artwork-slider__controls-item" key={index} style={{backgroundImage: `url(${files_server}/${image.small})`}} onClick={(event)=>{
                                    if(currentControl != event.target) {
                                        currentControl.classList.remove('active');
                                        setCurrentControl(event.target);
                                        artworkSlider.slideTo(getControlIndex(event.target), 0);
                                    }
                                }}>
                                </div>

                            )
                        })
                    }
                </div>

            </div>
        </>
    );
};


export default ArtworkBodySlider;