import logoHeader from '../assets/img/suarte-heading-logo.png';
import { useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../assets/css/ChangePassword.scss';
import Utils from '../utils';
import useFetchWrapper from '../hooks/useFetchWrapper';

const ChangePassword = () => {
    const API_URL = 'http://192.168.1.18:3001/auth/reset-password';
    const textError = useRef();
    const errorContainer = useRef();
    const loginContainer = useRef();
    const {token} = useParams();
    const fetchWrapper = useFetchWrapper();

    const displayError = (e, error) => {
        errorContainer.current.style.display = 'block';
        textError.current.textContent = error;
        e.target.new.focus();
    }

    const handleChangeSubmit = async (e) => {
        e.preventDefault();

        if(!e.target.new.value || !e.target.confirm.value) {
            displayError(e, "Enter a new password and confirm it");
        } else if(e.target.new.value !== e.target.confirm.value) {
            displayError(e, "Passwords don't match");     
        } else {
            //If the user had made a mistake, we dont want to keep the error message, so we hide it
            errorContainer.current.style.display = 'none';
        
            let changeInfo = {
               password: e.target.new.value,
               token: token
            } 
            
            try {
                let response =  await fetchWrapper.post(API_URL, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': '*/*'
                    },
                    body: JSON.stringify(changeInfo) 
                })

                // Invalid username or password
                if(response.status===404){
                    displayError(e, "Something went wrong, request a new pasword change");
                }

                else if(response.status===500) {
                    displayError(e, "Link expired. Request a new password change");
                }

                else if(response.status===200) {
                    let data = await response.json();
                }

            } catch(error) {
                console.error(error); //This should never happen, i'm a teapot
            } 
        }
    }

    //Avoids mobile problem of vertical height being decreased with the display of the keyboard! This will keep the height of the form the same always
    useEffect(() => {
        loginContainer.current.style.minHeight = window.innerHeight + "px";
    }, []);

    return (
        <>
        <div className="login-center" ref={loginContainer}>
            <div className="reset-container">
                <img src={logoHeader} alt="Suarte main heading logo" className="login-form__image" />

                <form onSubmit={handleChangeSubmit} className="change-form">

                    <div className="new-input">
                        <input type="password" name="new" id="new" className="new-input__new input-animation" autoComplete='off' onChange={Utils.handleInputLoginChange}/>
                        <span className='login-container__animation login-container__animation--change'>New password</span>
                    </div>

                    <div className="login-form__border"></div>

                    <div className="confirm-input">
                        <input type="password" name="confirm" id="confirm" className="confirm-input__confirm input-animation" autoComplete='off' onChange={Utils.handleInputLoginChange}/>
                        <span className='login-container__animation login-container__animation--change'>Confirm new password</span>
                    </div>

                    <div className="login-form__border"></div>

                    <div className="info-error-container" ref={errorContainer}>
                            <span className="info-error-container__icon">
                                <i className="fa-solid fa-circle-info"></i>
                            </span>
                            <span className="info-error-container__text-error" ref={textError} >
                            </span>
                    </div>

                    <button className="login-form__submit">
                        Change password
                    </button>

                </form>
                
            </div>
        </div>

        </>
    );
}

export default ChangePassword;