import '../assets/css/CartDetails.scss';
import CartProduct from './CartProduct';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import UserActions from '../actions/user.actions';

const CartDetails = () => {
    const state = useSelector(state => state);
    const dispatch = useDispatch();

    const getTotalPrice = () => {
        let total = 0;
        state.user_data.cart.forEach(product => {
            total += product.product_price * product.product_quantity;
        } );
        return total;
    }

    return (
        <>
            <div className="cart-details">
                {
                    //TODO: CHANGE FOR DB INDEX
                    state.user_data.cart.map((product, index) => {
                        return (
                            <CartProduct key={index} product={product} />
                        );
                    })
                }

                <div className="cart__clear-button" onClick={()=>{dispatch(UserActions.clearCart())}}>Clear checkout</div>

                <div className="cart-ticket">
                    <p className="cart-ticket__shipping">SHIPPING $ 89</p>
                    <p className="cart-ticket__total">TOTAL $ {getTotalPrice()}</p>
                </div>

                <div className="center-cart-button">
                    <div className="cart-button-background">
                        <div className="cart-button">
                            Purchase
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default CartDetails;