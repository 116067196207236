import '../assets/css/ServerError.scss';

const ServerError = () => {
    return (
        <>
            <h1>ERROR 500: INTERNAL SERVER ERROR</h1>
        </>
    );
};

export default ServerError;