import '../assets/css/ProfileNavigationOptions.scss';
import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import {motion, AnimatePresence} from 'framer-motion';

const ProfileNavigationOptions = () => {
    const location = useLocation();

    return (
        <>
            <div className="profile-navigation-options">
                <NavLink className={({ isActive }) => isActive ? "profile-navigation-options__link active dashboard" : "profile-navigation-options__link"} draggable={false} to="dashboard">
                    <div className="profile-navigation-link__container">
                        <span className="profile-navigation__icon">
                            <svg style={{fill: 'none', stroke: '#000', strokeMiterlimit: '10', strokeWidth: '10px'}}  id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                <rect x="38.19" y="38.24" width="101.06" height="101.06" rx="15.64"/>
                                <rect x="160.75" y="38.24" width="101.06" height="101.06" rx="15.64"/>
                                <rect x="38.19" y="160.7" width="101.06" height="101.06" rx="15.64"/>
                                <rect x="160.75" y="160.7" width="101.06" height="101.06" rx="15.64"/>
                            </svg>
                        </span>
                        <span className="profile-navigation__text">Dashboard</span>
                    </div>
                </NavLink>

                <NavLink className={({ isActive }) => isActive ? "profile-navigation-options__link active showroom" : "profile-navigation-options__link"} draggable={false} to="gallery">
                    <div className="profile-navigation-link__container">
                        <span className="profile-navigation__icon">
                            <svg style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '10px'}} id="Capa_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                <line
                                    x1="42.48"
                                    y1="125.36"
                                    x2="42.48"
                                    y2="246.66"
                                />
                                <line
                                    x1="71.29"
                                    y1="125.36"
                                    x2="71.29"
                                    y2="246.66"
                                />
                                <line
                                    x1="135.61"
                                    y1="125.36"
                                    x2="135.61"
                                    y2="246.66"
                                />
                                <line
                                    x1="164.43"
                                    y1="125.36"
                                    x2="164.43"
                                    y2="246.66"
                                />
                                <line
                                    x1="228.75"
                                    y1="125.36"
                                    x2="228.75"
                                    y2="246.66"
                                />
                                <line
                                    x1="257.57"
                                    y1="125.36"
                                    x2="257.57"
                                    y2="246.66"
                                />
                                <path
                                    d="M21.94,93.16H278.11v14.63c0,9.43-7.65,17.08-17.08,17.08H39.02c-9.43,0-17.08-7.65-17.08-17.08v-14.63h0Z"
                                />
                                <path
                                    d="M11.18,246.66H288.87v19.01c0,10.83-8.8,19.63-19.63,19.63H30.81c-10.83,0-19.63-8.8-19.63-19.63v-19.01h0Z"
                                    transform="translate(300.05 531.97) rotate(180)"
                                />
                                <polyline
                                    points="30.91 93.16 11.13 93.16 11.13 72.52 150 21.62"
                                />
                                <polyline
                                    points="269.09 93.16 288.87 93.16 288.87 72.52 150 21.62"
                                />
                            </svg>
                        </span>
                        <span className="profile-navigation__text">My gallery</span>
                    </div>
                </NavLink>

                {
                    <AnimatePresence>
                        {
                            location.pathname.includes("gallery") && (
                                <motion.div className="profile-navigation-options__accordeon"
                                    initial={{maxHeight: 0}}
                                    animate={{maxHeight: '400px'}}
                                    transition={{duration: 0.5}}
                                    exit={{maxHeight: 0, transition: {duration: 0.3, delay: 0}}}
                                >
                                    <NavLink className={({ isActive }) => isActive ? "profile-navigation__suboption active showroom-sub" : "profile-navigation__suboption"} draggable={false} to="gallery/showroom">
                                        Showroom
                                    </NavLink>

                                    <NavLink className={({ isActive }) => isActive ? "profile-navigation__suboption active showroom-sub" : "profile-navigation__suboption"} draggable={false} to="gallery/artworks">
                                        My artworks
                                    </NavLink>

                                    <NavLink className={({ isActive }) => isActive ? "profile-navigation__suboption active showroom-sub" : "profile-navigation__suboption"} draggable={false} to="gallery/imported-artworks">
                                        Imported artworks
                                    </NavLink>

                                    <NavLink className={({ isActive }) => isActive ? "profile-navigation__suboption active showroom-sub" : "profile-navigation__suboption"} draggable={false} to="gallery/artists">
                                        Artists
                                    </NavLink>

                                    <NavLink className={({ isActive }) => isActive ? "profile-navigation__suboption active showroom-sub" : "profile-navigation__suboption"} draggable={false} to="gallery/collectors">
                                        Collectors
                                    </NavLink>
                                </motion.div>
                            )
                        }

                    </AnimatePresence>
                }

                <NavLink className={({ isActive }) => isActive ? "profile-navigation-options__link active orders" : "profile-navigation-options__link"} draggable={false} to="orders">
                    <div className="profile-navigation-link__container">
                        <span className="profile-navigation__icon">
                            <svg style={{fill:'none', stroke:'#000', strokeLinecap:'round', strokeLinejoin:'round', strokeWidth:'10px'}} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                <path d="M292.45,220.21a.85.85,0,0,1,0,1.52L151.47,295a.85.85,0,0,1-.76,0L7.55,226.47a.86.86,0,0,1,0-1.53" />
                                <path d="M7.54,79.54,148.53,5" />
                                <path d="M54.21,102.71l141-74.55" />
                                <path d="M95.31,121.25l139.59-74" />
                                <path d="M55.12,103.84,7.55,81.06a.85.85,0,0,1,0-1.52" />
                                <path d="M148.53,5a.87.87,0,0,1,.77,0L292.45,74.8a.85.85,0,0,1,0,1.52l-141,73.29a.85.85,0,0,1-.76,0l-52.17-25" />
                                <polyline points="149.11 151.48 149.7 199.26 150.89 295.03" />
                                <line x1="292.93" y1="77.3" x2="292.93" y2="220.52" />
                                <line x1="7.07" y1="81.75" x2="7.07" y2="224.98" />
                                <polyline points="54.22 102.71 54.22 172.78 71.5 161.28 95.31 191.32 95.31 121.25" />
                            </svg>
                        </span>
                        <span className="profile-navigation__text">Orders</span>
                    </div>
                </NavLink>

                <NavLink className={({ isActive }) => isActive ? "profile-navigation-options__link active notifications" : "profile-navigation-options__link"} draggable={false} to="notifications">
                    <div className="profile-navigation-link__container">
                        <span className="profile-navigation__icon">
                            <svg style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '12px'}} id="Capa_3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                <path d="M265.72,242.54H34.47c0-19.69,10.88-36.84,26.97-45.75V114.73c0-42.66,30.16-78.29,70.3-86.74-.05-.64-.09-1.27-.09-1.91,0-10.17,8.24-18.44,18.44-18.44s18.45,8.26,18.45,18.44c0,.64-.04,1.27-.09,1.91,17.11,3.6,32.41,12.14,44.32,24.06,16.05,16.03,25.97,38.2,25.97,62.68v82.04c4.29,2.38,8.23,5.35,11.67,8.79,9.46,9.46,15.32,22.53,15.32,36.98Z"/>
                                <path d="M181.26,257.37c0,17.21-13.95,31.16-31.16,31.16s-31.16-13.95-31.16-31.16h62.32Z"/>
                            </svg>
                        </span>
                        <span className="profile-navigation__text">Notifications</span>
                    </div>
                </NavLink>

                <NavLink className={({ isActive }) => isActive ? "profile-navigation-options__link active notifications" : "profile-navigation-options__link"} draggable={false} to="invoices">
                    <div className="profile-navigation-link__container">
                        <span className="profile-navigation__icon">
                            <svg style={{fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '10px'}} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                <polygon
                                    points="242.25 295.09 207.99 271.03 170.93 295.09 137.99 271.03 104.16 295.09 73.06 271.03 33.72 295.09 33.72 5 180.52 5 242.25 70.38 242.25 295.09"
                                />
                                <polyline
                                    points="180.52 5 180.52 70.38 242.25 70.38"
                                />
                                <line
                                    x1="79.2"
                                    y1="102.71"
                                    x2="206.29"
                                    y2="102.71"
                                />
                                <line
                                    x1="79.2"
                                    y1="135.04"
                                    x2="206.29"
                                    y2="135.04"
                                />
                                <line
                                    x1="79.2"
                                    y1="199.69"
                                    x2="206.29"
                                    y2="199.69"
                                />
                            </svg>
                        </span>
                        <span className="profile-navigation__text">Invoices</span>
                    </div>
                </NavLink>

                <NavLink className={({ isActive }) => isActive ? "profile-navigation-options__link active referrals" : "profile-navigation-options__link"} draggable={false} to="referrals">
                    <div className="profile-navigation-link__container">
                        <span className="profile-navigation__icon">
                            <svg style={{fill:'none', stroke:'#000', strokeMiterlimit:10, strokeWidth:'11px'}} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                                <circle cx="150.87" cy="124.31" r="45.08" />
                                <path
                                    d="M80.34,268.3H218.25s21.24-1.54,17-19.76c-5.58-23.83-15.71-51.14-53.86-53.59H123.82s-48.21,0-57.53,55.34C63.4,267.44,80.34,268.3,80.34,268.3Z"/>
                                <circle cx="240.24" cy="60.51" r="28.81" />
                                <path
                                    d="M215.54,153.44l69.51-.9s12.63-1,10.09-12.63c-3.32-15.23-9.35-32.69-32-34.25-21.62,0-41.31-.44-41.31-.44s3.74,12.62,0,31.33C219.6,147.41,215.54,153.44,215.54,153.44Z"/>
                                <circle cx="59.76" cy="60.51" r="28.81" />
                                <path
                                    d="M84.88,153.44,15,152.54s-12.7-1-10.15-12.63c3.34-15.23,9.4-32.69,32.2-34.25,21.74,0,41.53-.44,41.53-.44s-3.75,12.62,0,31.33C80.8,147.41,84.88,153.44,84.88,153.44Z"/>
                            </svg>
                        </span>
                        <span className="profile-navigation__text">Referrals</span>
                    </div>
                </NavLink>

                <NavLink className={({ isActive }) => isActive ? "profile-navigation-options__link active support" : "profile-navigation-options__link"} draggable={false} to="support">
                    <div className="profile-navigation-link__container">
                        <span className="profile-navigation__icon">
                            <svg style={{top: '-4px', left: '-5px', fill: 'none', stroke: '#000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '10px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 250 250">
                                <g id="Capa_3" data-name="Capa 3">
                                    <path
                                        d="M133.39,78.89A83.25,83.25,0,0,0,86.88,66.42c-44.2,1.29-80.11,37.47-81.1,81.68a83.36,83.36,0,0,0,10.66,42.82l-3.85,34a1.55,1.55,0,0,0,1.84,1.7l30.25-5.92a83.67,83.67,0,0,0,89.82-.27"/>
                                    <path
                                        d="M282.2,193.24a83.27,83.27,0,0,0,12-46.36c-1.61-44.1-37.85-79.66-82-80.48A83.61,83.61,0,0,0,127,151.59c.82,44.22,36.55,80.51,80.76,82a83.26,83.26,0,0,0,46.53-12.26l31.32,5.77a1.49,1.49,0,0,0,1.75-1.7Z"/>
                                    <line x1="50.23" y1="134.57" x2="103.56" y2="134.57"/>
                                    <line x1="50.23" y1="164.48" x2="103.56" y2="164.48"/>
                                    <path
                                        d="M195.89,124.37s3.63-10.42,14.17-10.66a14.38,14.38,0,0,1,7.75,2,15.67,15.67,0,0,1,5.94,20.34,28.29,28.29,0,0,1-4.38,6.32c-9.07,10.07-11.28,12.52-11.28,16.93"/>
                                    <circle cx="207.98" cy="183.33" r="2.08"/>
                                </g>
                            </svg>
                        </span>
                        <span className="profile-navigation__text">Support</span>
                    </div>
                </NavLink>

                {
                    <AnimatePresence>
                        {
                            location.pathname.includes("support") && (
                                <motion.div className="profile-navigation-options__accordeon"
                                    initial={{maxHeight: 0}}
                                    animate={{maxHeight: '400px'}}
                                    transition={{duration: 0.5}}
                                    exit={{maxHeight: 0, transition: {duration: 0.3, delay: 0}}}
                                >
                                    <NavLink className={({ isActive }) => isActive ? "profile-navigation__suboption active support-sub" : "profile-navigation__suboption"} draggable={false} to="support/faqs">
                                        FAQS
                                    </NavLink>

                                    <NavLink className={({ isActive }) => isActive ? "profile-navigation__suboption active support-sub" : "profile-navigation__suboption"} draggable={false} to="support/terms">
                                        Terms
                                    </NavLink>

                                    <NavLink className={({ isActive }) => isActive ? "profile-navigation__suboption active support-sub" : "profile-navigation__suboption"} draggable={false} to="support/contact">
                                        Contact us
                                    </NavLink>
                                </motion.div>
                            )
                        }

                    </AnimatePresence>
                }
            </div>
        </>
    );
};


export default ProfileNavigationOptions;