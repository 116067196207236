
const GlobalActions = {
    set: (attribute, value) => {
        return {
            type: '@global/set',
            payload: {
                attribute,
                value
            }
        }
    },

    remove: (attribute) => {
        return {
            type: '@global/remove',
            payload: {
                attribute
            }
        }
    },
}

export default GlobalActions;