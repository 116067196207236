import '../assets/css/Footer.scss';
const Footer = () => {
    let currentYear = (new Date()).getFullYear();

    return (
        <>
        <footer className="footer">
            <div className="footer__copyright-part">
                <small>&copy; Copyright {currentYear} - SUARTE</small>
            </div>
        </footer>
        </>
    );
};

export default Footer;