import '../assets/css/RemoveArtworkPopup.scss';
import Popup from './Popup';
import useFetchWrapper from '../hooks/useFetchWrapper';
import config from '../config/project.config';
import useGlobalState from '../hooks/useGlobalState';
import { useNavigate } from 'react-router-dom';

const RemoveArtworkPopup = ({show, setShow, artwork}) => {
    const fetchWrapper = useFetchWrapper();
    const globalState = useGlobalState();
    const api_url = `${config.backend_api.url}/product/${artwork.product_id}`;
    const files_url = `${config.files_api.url}/artwork/${artwork.product_id}`;
    const navigate = useNavigate();

    const removeArtwork = () => {
        fetchWrapper.delete(api_url, {
            injectToken: true,
        })
        .then(response => {
            if(response.status === 200) {
                globalState.removeFromCache("my_artworks");  //to make the user fetch again
                fetchWrapper.delete(files_url, {
                    injectToken: true,
                })
                .then(response => {
                    if(response.status === 404) {
                        alert("Artwork already deleted")
                    } else if(response.status === 500) {
                        navigate("/500-internal-server-error");
                    }
                })
            } else if(response.status === 401 || response.status === 403) {
                navigate("/login");
            } else if(response.status === 404) {
                alert("Artwork already deleted");
            } else if(response.status === 500) {
                navigate("/500-internal-server-error");
            }
        })
        .catch(err=>{navigate("/500-internal-server-error")})
    }

    return (
        <>
            <Popup
                show={show}
                setShow={setShow}
                className="remove-artwork__popup"
            >
                <div className="remove-artwork__content">
                    <h1>Artwork removal</h1>
                    <p>You have chosen to remove {artwork ? artwork.product_name : ""}</p>
                    <p>This artwork is under contract, which ends in: 3 days </p>
                    <p>By removing this artwork, you will have to pay a 15% commission</p>
                    <p>Are you sure you want to remove this artwork?</p>
                    <h1>//TODO: DISEÑAR ESTE POPUP Y SU COMPORTAMIENTO</h1>
                    <button onClick={removeArtwork}>Remove i dont give a fuck</button>
                </div>
            </Popup>
        </>
    );
};


export default RemoveArtworkPopup;