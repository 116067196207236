import '../assets/css/HowToEditShowroom.scss';
import Popup from './Popup';

const HowToEditShowroom = ({show, setShow}) => {
    return (
        <>
            <Popup
                show={show}
                setShow={setShow}
                className="showroom__tutorial-popup"
                backdrop={false}
            >
                <h1 className="showroom__tutorial-heading">How do i edit my showroom?</h1>
                <div className="showroom__tutorial-wrap">
                    <p className="showroom__tutorial-text">To <span className="showroom__tutorial-orange">remove</span> an artwork, click on 
                        <span className="showroom__tutorial-x-container">
                            <i className="fa-solid fa-xmark"></i>
                        </span>
                    </p>
                    <p className="showroom__tutorial-text minor">Note that all artworks removed from your showroom are still at "My artworks" or "Imported artworks".</p>
                    <p className="showroom__tutorial-text">To <span className="showroom__tutorial-orange">arrange</span> the artworks:</p>

                    <div className="showroom__tutorial-inner-wrap">
                        <div className="showroom__tutorial-actions-container">
                            <div className="showroom__tutorial-action">
                                <p className="showroom__tutorial-action-text">
                                    <span className="showroom__tutorial-action-circle">
                                        <span className="showroom__tutorial-action-inner-circle">1</span>
                                    </span>
                                    Select any artwork you would like to move
                                </p>

                                <div className="showroom__tutorial-action-items">
                                    <div className="showroom__tutorial-action-item"></div>
                                    <div className="showroom__tutorial-action-item selected">
                                        <i className="fa-solid fa-hand-pointer showroom__tutorial-point"></i>
                                    </div>
                                    <div className="showroom__tutorial-action-item"></div>
                                </div>
                            </div>

                            <div className="showroom__tutorial-action">
                                <p className="showroom__tutorial-action-text">
                                    <span className="showroom__tutorial-action-circle">
                                        <span className="showroom__tutorial-action-inner-circle">2</span>
                                    </span>
                                    Choose the destination
                                </p>

                                <div className="showroom__tutorial-action-items">
                                    <div className="showroom__tutorial-action-item">
                                        <span className="showroom__tutorial-item-options"></span>
                                    </div>
                                    <div className="showroom__tutorial-action-item selected opacity"></div>
                                    <div className="showroom__tutorial-action-item">
                                        <span className="showroom__tutorial-item-options"></span>
                                        <i className="fa-solid fa-hand-pointer showroom__tutorial-hand"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="showroom__tutorial-actions-explain-container">
                            <div className="showroom__tutorial-actions-explain">
                                <span className="showroom__tutorial-action-explain-button">Left</span>
                                <p className="showroom__tutorial-action-text">Move up/before</p>
                            </div>

                            <div className="showroom__tutorial-actions-explain">
                                <span className="showroom__tutorial-action-explain-button">Swap</span>
                                <p className="showroom__tutorial-action-text">Switch artworks</p>
                            </div>

                            <div className="showroom__tutorial-actions-explain">
                                <span className="showroom__tutorial-action-explain-button">Right</span>
                                <p className="showroom__tutorial-action-text">Move down/after</p>
                            </div>
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    );
};


export default HowToEditShowroom;