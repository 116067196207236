import '../assets/css/Navbar.scss';
import suarteLogoDarkColor from '../assets/img/gallery-view/logo-mode-dark-color.png';
import suarteLogoDarkNoColor from '../assets/img/gallery-view/logo-mode-dark-no-color.png';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useRef } from 'react';
import UIActionCreator from '../actions/ui.actions';
import MobileMenu from './MobileMenu';
import useUserId from '../hooks/useUserId';
import config from '../config/project.config';
import { useNavigate } from 'react-router-dom';

// Gallery name can be undefined if it's not a gallery page
const Navbar = ({type, galleryName, artworkDetails}) => {
    const navbar = useRef();
    const menuButton = useRef();
    const [currentLogo, setCurrentLogo] = useState(suarteLogoDarkNoColor);
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const user_id = useUserId();
    const navigate = useNavigate();

    const checkoutNotification = useRef();
    const wishlistNotification = useRef();

    const [firstRender, setFirstRender] = useState(true);

    const scrollHandler = () => {
        if (window.scrollY >= 20 && navbar.current) {
            navbar.current.classList.add("navbar-painted");
        } else if (window.scrollY < 20 && navbar.current) {
            navbar.current.classList.remove("navbar-painted");
        }
    } 

    useEffect(()=> {
        if(state.mobile_menu) {
            menuButton.current.classList.add("active");
        } else {
            menuButton.current.classList.remove("active");
        }
    }, [state.mobile_menu])

    useEffect(() => {
        window.addEventListener("scroll", scrollHandler);
        return () => {//When it dismounts
            window.removeEventListener("scroll", scrollHandler)
        }
    }, []);

    useEffect(()=>{
        if(state.user_data.cart.length > 0) {
            checkoutNotification.current.classList.add("notification-active");
        } else {
            checkoutNotification.current.classList.remove("notification-active");
        }
        if(state.user_data.wishlist.length > 0) {
            wishlistNotification.current.classList.add("notification-active");
        } else {
            wishlistNotification.current.classList.remove("notification-active");
        }
    }, [state.user_data.cart, state.user_data.wishlist]);

    return (
        <>
            <div className="navbar" ref={navbar} data-navbar={type}>
                <div className="navbar--wrap">

                    {/* ICON BLOCK */}
                    <div className="navbar__icon">
                        {
                            type === "GALLERY" ? 
                                <Link to="/suarteworld">
                                    <img onMouseOver={()=> {setCurrentLogo(suarteLogoDarkColor);}} src={currentLogo} alt="Suarteworld logo" className="navbar__logo" onMouseLeave={()=> {setCurrentLogo(suarteLogoDarkNoColor);}}/> 
                                </Link>
                            : 
                            type === "TEMPLATE" ?
                                <div className="navbar-pic__template"></div>
                            :
                                <Link to="/main">
                                    <img src={`${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}/user/${user_id}/profile.webp`} alt="Suarteworld logo" className="navbar__gallery-logo" />
                                </Link>                            
                        }
                    </div>
                    
                    {/* NAME BLOCK */}
                    {
                        type === "SUARTEWORLD" ? 
                            <div className="navbar__center --suarte-world">
                                <img src={suarteLogoDarkColor} alt="suarte-logo" className="navbar-suarte__logo-name"/>
                                UARTEWORLD</div>
                        : 
                        type === "ARTWORK" ?
                            <div className="navbar__center artwork" onClick={()=>{
                                window.scrollTo({
                                    top: 0,
                                    left: 0,
                                  });
                            }}>
                                <div className="navbar-artwork__name">
                                    {artworkDetails.product_name}
                                </div>
                                <div className="navbar-artwork__artist">
                                    {artworkDetails.metas.filter(meta => meta.meta_key === 'artist')[0].meta_value ?? 'Unknown artist'}
                                </div>
                            </div>
                        :
                        type === "TEMPLATE" ?
                            <div className="navbar__center template"></div>       
                        :
                            <div className="navbar__center">{galleryName || type}</div>                          
                    }
                

                    <div className="navbar__options"> 
                        <Link to="/search">
                            <span className="navbar__search">
                                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                    <g id="b">
                                        <path d="M350.79,342.08c2.55,1.56,5.18,2.56,6.99,4.37,45.71,45.56,91.33,91.21,136.97,136.85,3.61,3.61,7.54,7.21,3.61,12.83-2.98,4.24-6.9,5.1-11.26,2.08-1.59-1.1-2.86-2.66-4.24-4.04-45.64-45.63-91.29-91.26-136.89-136.93-1.83-1.83-3.22-4.1-5.2-6.66-54.26,48.57-116.44,65.07-185.82,49.56-52.3-11.69-93.17-42-122.35-86.79C-20.77,231.48-8.18,118.28,67.25,51.15c77.25-68.74,191.3-66.44,265.2-3.67,43.84,37.25,67.98,85,71.71,142.53,3.7,57.12-12.83,107.59-53.37,152.06Zm38.97-138.97c-1.52-103.22-78.69-187.7-187.38-187.75C96.23,15.32,14.35,95.84,14.41,202.94c.05,106.86,81.53,187.13,187.48,187.43,107.57,.31,185.97-82.88,187.87-187.26Z"/>
                                    </g>
                                </svg>
                            </span>
                        </Link>
                        <Link to="/wishlist">
                            <span className="navbar__heart">
                                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 584.6 500">
                                    <g id="b">
                                        <path d="M292.12,78.28c9.11-10.82,16.92-21.23,25.87-30.53C357.51,6.72,405.89-6.93,461.14,3.2c42.48,7.79,75.06,31.21,98.52,67.15,13.67,20.94,21.14,44.31,23.74,69.36,2.52,24.34,1.08,48.31-4.52,72.04-7.95,33.63-26.71,60.78-51.36,84.1-66.89,63.26-133.89,126.4-200.96,189.46-20.83,19.59-47.48,19.57-68.29,0-67.43-63.42-134.81-126.9-202.08-190.49C26.61,266.87,7.36,233.24,2.14,192.32-3.21,150.3,.6,109.47,23.55,72.97,53.52,25.3,97.47,.73,153.85,.16c54.66-.55,96.75,24.23,129.52,66.82,2.76,3.59,5.54,7.16,8.75,11.3Zm.25,29.33c-9.98-12.59-19.15-24.71-28.89-36.36-19.67-23.51-43-41.76-73.47-48.93-40.9-9.62-79.61-3.98-113.85,20.76C24.01,80.77,11.14,134.16,20.61,194.73c5.75,36.83,24.87,66.72,51.9,91.93,50.77,47.34,101.3,94.92,151.94,142.39,14.65,13.74,29.31,27.47,44.02,41.16,17.73,16.5,29.96,16.55,47.57,.29,1.87-1.73,3.7-3.49,5.56-5.22,62.3-58.08,124.15-116.67,187.08-174.06,39.71-36.21,59.15-79.9,58.37-133.77-.88-60.01-35.91-112.48-92.11-131.66-34.87-11.9-69.39-10.58-102.99,4.75-22.94,10.46-40.56,27.58-55.95,47.23-7.64,9.75-15.37,19.43-23.62,29.85Z"/>
                                    </g>
                                </svg>
                                <div className="navbar__notification" ref={wishlistNotification}></div>
                            </span>
                        </Link>
                        
                        <Link to="/checkout">
                            <span className="navbar__cart">
                                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 564.33 500">
                                    <path d="M562.66,66.87c-1.51-1.99-3.85-3.15-6.33-3.15H83.35L69.38,6.16c-.88-3.62-4.09-6.16-7.77-6.16H8C3.58,0,0,3.62,0,8.09s3.58,8.09,8,8.09H55.34l92.33,380.6c.88,3.62,4.09,6.16,7.77,6.16H472.14c4.42,0,8-3.62,8-8.09s-3.58-8.09-8-8.09H161.71l-18.18-74.92H496.08c3.64,0,6.81-2.48,7.74-6.04l60.25-231.93c.63-2.43,.11-5.01-1.4-7Zm-72.75,228.78H139.61L87.27,79.91h458.68l-56.04,215.75Z" />
                                    <path d="M168.03,420.04c-22.15,0-40.17,17.93-40.17,39.98s18.02,39.98,40.17,39.98,40.17-17.93,40.17-39.98-18.02-39.98-40.17-39.98Zm0,63.77c-13.33,0-24.17-10.67-24.17-23.8s10.84-23.8,24.17-23.8,24.17,10.67,24.17,23.8-10.84,23.8-24.17,23.8Z" />
                                    <path d="M446,420.04c-22.15,0-40.17,17.93-40.17,39.98s18.02,39.98,40.17,39.98,40.17-17.93,40.17-39.98-18.02-39.98-40.17-39.98Zm0,63.77c-13.33,0-24.17-10.67-24.17-23.8s10.84-23.8,24.17-23.8,24.17,10.67,24.17,23.8-10.84,23.8-24.17,23.8Z" />
                                </svg>
                                <div className="navbar__notification" ref={checkoutNotification}></div>
                            </span>
                        </Link>

                        <span className="navbar__user"  onClick={()=>{
                            navigate("/profile");
                        }} >
                            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436.75 500">
                                <g id="b">
                                    <g>
                                        <path d="M217.89,499.96c-60.18,0-120.36-.04-180.54,.04-12.63,.02-24.25-2.94-31-14.2-3.71-6.2-6.65-14.21-6.33-21.25,3.05-67.58,34.09-118.16,94.21-149.84,24.03-12.67,50.42-17.35,77.4-17.74,31.28-.45,62.57-.52,93.85,0,46.09,.76,86.97,15.49,120.28,48.12,30.34,29.71,47.49,65.92,50.46,108.45,.54,7.69,1.13,15.85-.78,23.15-3.94,15.03-16.9,23.2-34.4,23.25-33.67,.1-67.35,.03-101.02,.03-27.37,0-54.75,0-82.12,0Zm.2-15.3c60.2,0,120.39,.01,180.59,0,16.89,0,24.8-8.56,23.11-25.42-.89-8.83-2-17.69-3.81-26.37-6.21-29.71-20.21-55.45-41.99-76.48-32.12-31.02-71.29-44.39-115.53-44.24-28.03,.1-56.07-.03-84.1,.03-22.33,.05-44.15,3.14-64.97,11.62-34.74,14.15-61,37.7-78.47,70.95-10.36,19.71-15.33,40.84-17.45,62.92-1.76,18.36,5.39,26.99,23.99,26.99,59.54,0,119.09,0,178.63,0Z" />
                                        <path d="M343.53,124.79c-2.22,73.12-54.63,124.64-125.4,124.28-73.12-.37-124.56-54.33-124.13-125.11C94.44,51.51,147.63-.12,218.64,0c72.68,.12,123.1,53.57,124.89,124.79ZM218.56,14.84c-60.36-.02-109.35,48.81-109.72,109.35-.36,60.41,49.09,110.01,109.68,110.04,60.37,.02,109.36-48.8,109.72-109.35,.35-60.41-49.09-110.02-109.68-110.04Z"/>
                                    </g>
                                </g>
                            </svg>
                        </span>
                    </div>

                    <div className="navbar-mobile-container">  
                    </div>
                </div>
            </div>

            {/* The mobile button is outside because we dont want the mobile menu to position above the icon*/}
            <div className="navbar__mobile-button" ref={menuButton} onClick={()=>{
                if(firstRender) {
                    setFirstRender(false);
                }

                state.mobile_menu ?  dispatch(UIActionCreator.toggleOffMobileMenu()) :  dispatch(UIActionCreator.toggleOnMobileMenu());
            }}>
                    <span className="navbar__mobile-button-bar"></span>
                    <span className="navbar__mobile-button-bar"></span>
                    <span className="navbar__mobile-button-bar"></span>
            </div>

             { firstRender ? "" : <MobileMenu /> }
        </>
    );
};

export default Navbar;