import PageTemplate from './PageTemplate';
import Navbar from '../components/Navbar';
import CartDetails from '../components/CartDetails';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import UIActionCreator from '../actions/ui.actions';
import { useSelector } from 'react-redux/es/exports';

const Checkout = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);

    useEffect(()=>{
        dispatch(UIActionCreator.enterView('checkout'));
    }, []);

    return (
        <>
            <PageTemplate>
                <Navbar type="CHECKOUT"/>
                {
                    state.user_data.cart.length > 0 ?
                        <CartDetails/>
                    : 
                        null
                }
            </PageTemplate>
        </>
    );
};

export default Checkout;