import PageTemplate from './PageTemplate';
import Navbar from '../components/Navbar';
import MasonryGallery from '../components/MasonryGallery';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import UIActionCreator from '../actions/ui.actions';
import useUserId from "../hooks/useUserId";
import ProfileActions from "../actions/profile.actions";
import config from '../config/project.config';
import { useSelector } from 'react-redux';
import useCache from "../hooks/useCache";
import MasonryGalleryTemplate from '../components/MasonryGalleryTemplate';

const GalleryView = () => {
    const dispatch = useDispatch();
    const user_id = useUserId();
    const state = useSelector(state => state);
    const gallery_data = useCache("gallery_data", `${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/gallery/${user_id}`)

    useEffect(()=>{
        if(gallery_data !== "LOADING" && gallery_data) {
            dispatch(ProfileActions.storeGalleryData(gallery_data, user_id));
        }
    }, [gallery_data]);

    useEffect(()=>{
        dispatch(UIActionCreator.enterView('gallery'));
    }, []);

    return (
        <>
            <PageTemplate>
                {
                    gallery_data && gallery_data !== "LOADING" && state.gallery_data ?
                        <>
                            <Navbar type="GALLERY" galleryName={state.gallery_data.user.user_name}/>
                            <MasonryGallery artworks={state.gallery_data.showroom.products}/>
                        </>
                    :
                        <>
                            <Navbar type="TEMPLATE"/>
                            <MasonryGalleryTemplate/>
                        </>  
                } 
            </PageTemplate>
        </>
    );
};

export default GalleryView;