import React from "react";
import { BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import { useSelector } from "react-redux";
import Login from '../containers/Login';
import ChangePassword from "../components/ChangePassword";
import GalleryView from '../containers/GalleryView';
import SuarteWorld from '../containers/SuarteWorld';
import WishList from '../containers/WishList';
import Artwork from "../containers/Artwork";
import Search from '../containers/Search';
import ProtectedRoute from "../components/ProtectedRoute";
import Checkout from "../containers/Checkout";
import NotFound from "../components/NotFound";
import ProfileTemplate from "../containers/ProfileTemplate";
import Showroom from "../components/Showroom";
import Dashboard from "../components/Dashboard";
import EditProfile from "../components/EditProfile";
import ServerError from "../components/ServerError";
import GalleryRegister from "../components/GalleryRegister";
import ShowroomMyArtworks from "../components/ShowroomMyArtworks";
import ShowroomNewArtwork from "../components/ShowroomNewArtwork";
import RegisterSuccess from "../components/RegisterSuccess";
import PackageTesting from "../components/PackageTesting";
import Contact from "../components/Contact";
import EditArtwork from "../components/EditArtwork";
import Invoices from "../components/Invoices";
import Notifications from "../components/Notifications";
import Page from "../containers/Page";

const App = () => {
    const state = useSelector(state => state);

    return (
            <div data-theme={state.theme}>
                <BrowserRouter>
                    <Page> {/** USED FOR CHANGING THE TITLE OF THE DOCUMENT */}
                        <Routes>
                            <Route exact path="login" element={<Login />}/>
                            <Route exact path="register" element={<GalleryRegister/>}/>
                            <Route exact path="register/success" element={<RegisterSuccess/>}/>

                            <Route exact path="new-password/:token" element={<ChangePassword/>}/>

                            {/* Protected routes */}
                            <Route element={<ProtectedRoute/>}>
                                <Route exact path="main" element={<GalleryView/>}/>
                                <Route exact path="suarteworld" element={<SuarteWorld/>}/>
                                <Route exact path="wishlist" element={<WishList/>}/>
                                <Route exact path="checkout" element={<Checkout/>}/>
                                <Route exact path="artwork/:id" element={<Artwork/>}/> 
                                <Route exact path="search" element={<Search/>}/> 
                                <Route path="/profile" element={<ProfileTemplate/>}>
                                    <Route index element={<Navigate to="edit" replace={true}/>}/>
                                    <Route path="edit" element={<EditProfile/>} />
                                    <Route path="dashboard" element={<Dashboard/>} />

                                    <Route path="gallery">
                                        <Route index element={<Navigate to="showroom" replace={true}/>}/>
                                        <Route path="showroom" element={<Showroom/>}/>
                                        <Route path="artworks" element={<ShowroomMyArtworks/>} />
                                        <Route path="artworks/new-artwork" element={<ShowroomNewArtwork/>} />
                                        <Route path="artworks/edit/:id" element={<EditArtwork/>} />
                                        <Route path="imported-artworks" element={<Showroom/>} />
                                        <Route path="artists" element={<Showroom/>}/>
                                        <Route path="collectors" element={<Showroom/>}/>
                                    </Route>
                                    
                                    <Route path="orders" element={<Showroom/>} />
                                    <Route path="notifications" element={<Notifications/>} />
                                    <Route path="invoices" element={<Invoices/>} />
                                    <Route path="referrals" element={<Showroom/>} />

                                    <Route path="support" element={<Showroom/>}/>
                                    <Route path="support/faqs" element={<Showroom/>} />
                                    <Route path="support/terms" element={<Showroom/>} />
                                    <Route path="support/contact" element={<Contact/>} />
                                </Route>
                            </Route>

                        
                            <Route path="/" element={ <Navigate to="/main" replace={true}/> } />
                            <Route path="/package-testing" element={<PackageTesting/>}/> <>{/**THIS IS JUST A BLANK SITE, TO TEST NEW PACKAGES */}</>
                            <Route path="404-not-found" element={<NotFound/>}/>
                            <Route path="500-internal-server-error" element={<ServerError/>}/>
                            <Route path="*" element={<NotFound/>}/>
                        </Routes>
                    </Page>
                </BrowserRouter>
            </div>
    );
};

export default App;