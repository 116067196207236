import '../assets/css/ArtworkCard.scss';
import { useState } from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import config from '../config/project.config';
import Utils from '../utils/';

const ArtworkCard = ({artwork, displayEditOptions=null, disableLeft, disableRight, onSwap, onLeft, onRight}) => { 
    const artworkImage = useRef(null);
    const [imageLoaded, setImageLoaded] = useState(false);
    const files_server = config.files_server.url;
    const {min, max} = Utils.getMinMaxPriceFromArtwork(artwork);

    useEffect(()=>{
        if(imageLoaded) {
            artworkImage.current.classList.remove("artwork__image-template");
        } else {
            artworkImage.current.classList.add("artwork__image-template");
        }

    }, [imageLoaded]);

    return (
        <>
            <div className="artwork-card">
                <div className="artwork__image artwork__image-template" ref={artworkImage} >
                    <img className={`artwork__artwork ${displayEditOptions}`} src={`${files_server}/artwork/${artwork.product_id}/0.webp`} alt="artwork thumbnail" draggable="false" onLoad={()=>{
                        setImageLoaded(true);
                    }} onError={()=>{setImageLoaded(false)}}/>

                    { //JUST FOR THE EDIT OPTION IN SHOWROOM
                        (displayEditOptions === true) && (
                            <div className="edit-showroom__options" onClick={(e)=>{e.stopPropagation();}}>
                                <div className="edit-showroom__wrap">
                                    <span className={disableLeft ? "disabled" : null} onClick={!disableLeft ? ()=>{onLeft()} : null}>
                                        Left
                                    </span>
                                    <span onClick={()=>{onSwap()}}>
                                        Swap
                                    </span>
                                    <span className={disableRight ? "disabled" : null} onClick={!disableRight ? ()=>{onRight()} : null}>
                                        Right
                                    </span>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div className="artwork__info">
                    <p className="artwork__name">{artwork.product_name}</p>
                    <div className="artwork-details">
                        <span className="artwork__author">{artwork.metas.filter(meta => meta.meta_key === 'artist')[0].meta_value ?? 'Unknown artist'}</span>
                        <span className="artwork__price">${min} - ${max}</span>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default ArtworkCard;