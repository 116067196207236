import '../assets/css/CartProduct.scss';
import { useDispatch } from 'react-redux';
import { useRef } from 'react';
import UserActions from '../actions/user.actions';
import config from '../config/project.config';

const CartProduct = ({product}) => {
    const dispatch = useDispatch();
    const productContainer = useRef(null);
    const product_pic = `${config.files_server.url}/artwork/${product.product_original_id}/0.webp`
    const minus = useRef();
    const plus = useRef();
    
    return (
        <>
            <div className="cart-product" ref={productContainer}>
                <div className="cart-product__image">
                    <img src={product_pic} alt="artwork image" />
                </div>

                <div className="cart-product__details">
                    <p className="cart-product__name">{product.product_name}</p>
                    <p className="cart-product__author">{product.product_artist}</p>
                    <div style={{width: "65%", borderBottom: "1px solid grey"}}></div>
                    <div className="cart-product__dimension-type">
                        <p className="cart-product__type">{product.product_type}</p>
                        <p className="cart-product__dimension">{product.product_size.product_width} x {product.product_size.product_height} cm</p>
                    </div>

                    <div className="cart-product__price-info">
                        <p className="cart-product__price">$ {product.product_price}</p>
                    </div>

                    {
                        product.product_type === "Limited edition" ? 
                            <>
                                <div className="cart-product__quantity">
                                    <div className="cart-product__quantity-button">
                                        <span className={product.product_quantity > 1 ? "quantity-button__minus" : "quantity-button__minus disabled"} ref={minus} onClick={()=>{
                                                if(product.product_quantity > 1){
                                                    dispatch(UserActions.updateQuantity(product, product.product_quantity - 1));
                                                    
                                                } 
                                            }}>
                                            <i className="fa-solid fa-minus"></i>
                                        </span>
                                        <span className="quantity-button__quantity">
                                            {product.product_quantity}
                                        </span>
                                        <span className={product.product_quantity < product.product_max_quantity  ? "quantity-button__plus" : "quantity-button__plus disabled"} ref={plus} onClick={()=>{
                                                if(product.product_quantity < product.product_max_quantity) {
                                                    dispatch(UserActions.updateQuantity(product, product.product_quantity + 1));
                                                } 
                                            }}>
                                            <i className="fa-solid fa-plus"></i>
                                        </span>
                                    </div>
                                </div>
                            </>
                        
                        : 
                            ""
                    }

                    <span className="x-icon-checkout" onClick={(e)=>{
                        productContainer.current.classList.add("remove")
        
                        setTimeout(()=>{
                            productContainer.current.classList.remove("remove")
                            dispatch(UserActions.removeFromCart(product)); //This is in a setTimeout to prevent the cart from being updated before the animation is finished
                        }, 300);
                    }}>
                        <svg viewBox="0 0 18 18">
                            <path d="M9.88 9l4.56 4.56-.88.88L9 9.88l-4.56 4.56-.88-.88L8.12 9 3.56 4.44l.88-.88L9 8.12l4.56-4.56.88.88z"></path>
                        </svg>
                    </span>
                </div>


            </div>
        </>
    );
};


export default CartProduct;