import '../assets/css/GalleryRegister.scss';
import config from '../config/project.config';
import SelectAccordeon from './SelectAccordeon';
import { useState } from 'react';
import FadeIn from './FadeIn';
import { useNavigate } from "react-router-dom";
import useFetchWrapper from '../hooks/useFetchWrapper';
import ErrorDivDisplayer from './ErrorDivDisplayer';
import Spinner from 'react-bootstrap/Spinner';
import { BsCheckLg } from "react-icons/bs";

const GalleryRegister = () => {
    const API_URL = `${config.backend_api.protocol}://${config.backend_api.host}:${config.backend_api.port}/gallery`;
    const [galleryPhone, setGalleryPhone] = useState(null);
    const [agentHonorific, setAgentHonorific] = useState('---');
    const [agentPhone, setAgentPhone] = useState(null);
    const navigate = useNavigate();
    const [registerState, setRegisterState] = useState("default");
    const fetchWrapper = useFetchWrapper();
    const [error, setError] = useState(null);
    const [emailCheckbox, setEmailCheckbox] = useState(false);
    const [policyCheckbox, setPolicyCheckbox] = useState(false);

    const registerGallery = (e) => {
        e.preventDefault();
        setRegisterState("fetching")
        setError(null); 

        if(!emailCheckbox) {
            setError({attribute: "checkbox_emails", message: "You must accept to register"});
            setRegisterState("default");
        } else if(!policyCheckbox) {
            setError({attribute: "checkbox_policy", message: "You must accept the privacy policy to register"});
            setRegisterState("default");
        } else {
            const body = {
                user: {
                    user_name: e.target.gallery_name.value,
                    user_login: e.target.username.value,
                    user_email: e.target.email.value,
                    user_phone: {
                        phone_number_prefix: galleryPhone,
                        phone_number_suffix: e.target.gallery_phone.value
                    }
                },
    
                address: {
                    address_country: e.target.country.value,
                    address_state: e.target.region.value,
                    address_city: e.target.city.value,
                    address_zip: e.target.postal_code.value,
                    address_address: e.target.address.value, 
                    address_name: e.target.gallery_name.value,
                },
    
                gallery: {
                    gallery_nit: e.target.nif.value,
    
                },
    
                agent: {
                    agent_last_name: e.target.last_name.value,
                    agent_honorific: agentHonorific,
                    agent_first_name: e.target.agent_name.value,
                    agent_phone: {
                        phone_number_prefix: agentPhone,
                        phone_number_suffix: e.target.agent_phone.value
                    }
                }        
            }
            
            fetchWrapper.post(API_URL, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                },
                body: JSON.stringify(body)
            })
            .then(response => {
                //This code means that it was the client's fault
                if(response.status === 400) {
                    response.json().then(data => {
                        setError({attribute: data.attribute, message: data.reason})
                        setRegisterState("default");
                    });
                } else if(response.status === 409) {
                    setRegisterState("default");
                    alert("Email, username or NIT already exists. Please try again with new data");
                } else if(response.status === 500) {
                    navigate("/500-internal-server-error");
                } else if(response.status === 200) {
                    navigate("/register/success");
                }
            })
            .catch(err=> navigate("/500-internal-server-error"));
        }
    }

    return (
        <>
            <FadeIn>
                <div className="register-container">
                    <h1 className="register__heading">REGISTER GALLERY</h1>

                    <form onSubmit={registerGallery} className="register__wrap">
                        <div className="register__gallery-info">
                            <p className="register__subheading">Gallery information</p>

                            <div className="register-inputs__wrap">
                                <p className="register__input-label">Name*</p>
                                <input className="register__input" autoComplete="off" type="text" name="gallery_name"/>
                                <ErrorDivDisplayer attribute="user_name" error={error}/>

                                <p className="register__input-label">Email*</p>
                                <input className="register__input" autoComplete="off" type="text" name="email"/>
                                <ErrorDivDisplayer attribute="user_email" error={error}/>

                                <p className="register__input-label">Username*</p>
                                <input className="register__input" autoComplete="off" type="text" name="username"/>
                                <ErrorDivDisplayer attribute="user_login" error={error}/>

                                <p className="register__input-label">NIT*</p>
                                <input className="register__input" autoComplete="off" type="text" name="nif"/>
                                <ErrorDivDisplayer attribute="gallery_nit" error={error}/>

                                <p className="register__input-label">Phone number*</p>
                                <div className="register__input-group phone">
                                    <div className="register__input-group-accordeon">
                                         <SelectAccordeon options={config.prefixOptions} type={"PREFIX"} option={galleryPhone} setOption={setGalleryPhone}/>
                                    </div>
                                    <div className="register__input-group-accordeon-right">
                                        <input className="register__input" type="text" autoComplete="off" name="gallery_phone"/>
                                    </div>
                                </div>
                                <ErrorDivDisplayer attribute="user_phone" error={error}/>

                                <div className="register__input-group">
                                    <div className="register__input-group-item">
                                        <p className="register__input-label">Country*</p>
                                        <input className="register__input" autoComplete="off" type="text" name="country"/>
                                        <ErrorDivDisplayer attribute="address_country" error={error}/>
                                    </div>

                                    <div className="register__input-group-item">
                                        <p className="register__input-label">Region/Province*</p>
                                        <input className="register__input" autoComplete="off" type="text" name="region"/>
                                        <ErrorDivDisplayer attribute="address_state" error={error}/>
                                    </div>
                                </div>

                                <div className="register__input-group">
                                    <div className="register__input-group-item">
                                        <p className="register__input-label">City*</p>
                                        <input className="register__input" autoComplete="off" type="text" name="city"/>
                                        <ErrorDivDisplayer attribute="address_city" error={error}/>
                                    </div>

                                    <div className="register__input-group-item">
                                        <p className="register__input-label">Postal code*</p>
                                        <input className="register__input" autoComplete="off" type="text" name="postal_code"/>
                                        <ErrorDivDisplayer attribute="address_zip" error={error}/>
                                    </div>
                                </div>

                                <p className="register__input-label">Address*</p>
                                <input className="register__input" type="text" autoComplete="off" name="address"/>
                                <ErrorDivDisplayer attribute="address_address" error={error}/>

                            </div>
                        </div>

                        <div className="register__personal-info">
                            <p className="register__subheading">Personal information</p>

                            <div className="register-inputs__wrap">
                                <p className="register__input-label">Last name*</p>
                                <input className="register__input" type="text" autoComplete="off" name="last_name"/>
                                <ErrorDivDisplayer attribute="agent_last_name" error={error}/>

                                <p className="register__input-label">Name*</p>
                                <div className="register__input-group">
                                    <div className="register__input-group-accordeon">
                                        <SelectAccordeon options={config.firstNameOptions} option={agentHonorific} setOption={setAgentHonorific}/>
                                    </div>
                                    <div className="register__input-group-accordeon-right">
                                        <input className="register__input" type="text" autoComplete="off" name="agent_name"/>
                                    </div>
                                </div>
                                <ErrorDivDisplayer attribute="agent_first_name" error={error}/>

                                <p className="register__input-label">Phone number</p>
                                <div className="register__input-group phone">
                                    <div className="register__input-group-accordeon">
                                         <SelectAccordeon options={config.prefixOptions} type={"PREFIX"} option={agentPhone} setOption={setAgentPhone}/>
                                    </div>
                                    <div className="register__input-group-accordeon-right">
                                        <input className="register__input" type="text" autoComplete="off" name="agent_phone"/>
                                    </div>
                                </div>
                                <ErrorDivDisplayer attribute="agent_phone" error={error}/>

                                <div className="register__checkboxes">
                                    <div className={emailCheckbox ? "register__checkbox-container checked" : "register__checkbox-container"}>
                                        <div className="register__checkbox" onClick={()=>{
                                            setEmailCheckbox(!emailCheckbox);
                                        }}><BsCheckLg/></div>
                                        <p className="register__checkbox-label">I want to receive emails from Suarte* (TODO: ADD LINKS)</p>
                                    </div>
                                    <ErrorDivDisplayer attribute="checkbox_emails" error={error}/>

                                    <div className={policyCheckbox ? "register__checkbox-container checked" : "register__checkbox-container"}>
                                        <div className="register__checkbox" onClick={()=>{
                                            setPolicyCheckbox(!policyCheckbox);
                                        }}><BsCheckLg/></div>
                                        <p className="register__checkbox-label">I agree to the private policy* (TODO: ADD LINKS)</p>

                                    </div>
                                    <ErrorDivDisplayer attribute="checkbox_policy" error={error}/>
                                </div>
                            </div>

                            {
                                registerState === "default" ? 
                                    <button type="submit" className="register__submit-button">Start journey in Suarte</button>
                                :
                                    <div className="register__submit-button loading">
                                        <Spinner animation="grow" />
                                        <span>Loading</span>
                                    </div>     
                            }

                           
                        </div>
                    </form>
                </div>
            </FadeIn>
        </>
    );
};


export default GalleryRegister;
