import '../assets/css/MobileMenu.scss'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import UIActionCreator from '../actions/ui.actions';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import config from '../config/project.config.js';
import useUserId from '../hooks/useUserId';
import { useNavigate } from 'react-router-dom';

const MobileMenu = () => {
    const state = useSelector(state => state);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    const user_id = useUserId();
    const navigate = useNavigate();

    const checkoutNotification = useRef();
    const wishlistNotification = useRef();

    //THIS WILL BE IN GLOBAL STATE:
    const checkout = [1];
    const wishlist = [1];

    //We want to execute it every render!
    useEffect(() => {
        if (checkoutNotification.current) {
            if (state.user_data.cart.length > 0) {
                checkoutNotification.current.classList.add("notification-active");
            } else {
                checkoutNotification.current.classList.remove("notification-active");
            }
        }

        if (wishlistNotification.current) {
            if (state.user_data.wishlist.length > 0) {
                wishlistNotification.current.classList.add("notification-active");
            } else {
                wishlistNotification.current.classList.remove("notification-active");
            }
        }
    });

    useEffect(()=>{
        return () => {
            dispatch(UIActionCreator.toggleOffMobileMenu()); //We want to toggle the mobile menu off
        }
    }, []);

    //This useEffect is implemented because bootstrap injects dom objects dinamically. That's why we need to have this useEffect in order to check the refs
    useEffect(() => {
        if (open) {
            const elements = document.getElementsByClassName("menu-option");


            for (let i = 0; i < elements.length; i++) {
                if (state.view === elements[i].dataset.type) {
                    elements[i].classList.add("menu-option__current");

                    if (i != 0) {
                        elements[i - 1].classList.add("active-option-top");
                    }

                    if (i != elements.length - 1) {
                        elements[i + 1].classList.add("active-option-bottom");
                    }
                }
            }
        }

    }, [open]);

    return (
        <>
            <div>
                <Offcanvas className={"mobilemenu__width"} onShow={() => { setOpen(true); }} onExit={() => { setOpen(false); }} placement={'end'} show={state.mobile_menu} onHide={() => { dispatch(UIActionCreator.toggleOffMobileMenu()); }}>
                    <div className="mobile-menu-aside">
                        <div style={{ marginTop: "100px" }}></div>

                        <Link to="/main">
                            <div className="mobile-menu__option menu-option" data-type="gallery">
                                <div className="mobile-menu__gallery bg-active">
                                    <span className="mobile-menu-gallery__text">Showroom</span>
                                    <div className="mobile-menu-gallery__icon">
                                        <img src={`${config.files_server.protocol}://${config.files_server.host}:${config.files_server.port}/user/${user_id}/profile.webp`} alt="logo-galeria" className="mobile-menu__gallery-pic" />
                                    </div>
                                </div>
                            </div>

                        </Link>

                        <Link to="/suarteworld">
                            <div className="mobile-menu__option menu-option" data-type="suarteworld">
                                <div className="mobile-menu__suarteworld bg-active">
                                    <span className="mobile-menu-suarteworld__text">Suarteworld</span>
                                    <div className="mobile-menu-suarteworld__icon">
                                        <svg id="a" xmlns="http://www.w3.org/2000/svg" className="mobile-menu__suarteworld-icon" viewBox="0 0 345.45 487.32">
                                            <path d="M339.2,172.18c-8.23-17.42-18.81-28.65-31.72-33.7-12.91-5.03-25.82-6.33-38.71-3.87-12.89,2.46-28.17,6.77-45.84,12.94-12.25,4.33-21.15,7.03-26.7,8.07-5.56,1.05-9.23-.3-11-4.07-3.18-6.73,4.44-14.45,22.85-23.15,12.08-5.71,25.21-10.09,39.38-13.16,14.18-3.06,30.04-5.23,47.61-6.51l-14.31-63.36c-20.19,.34-40.38,2.97-60.57,7.91-8.93,2.19-17.58,4.71-25.97,7.54l.14-.07-10.82-20.92-63.53,15.59,17.59,32.02h.02c-8.23,5.27-15.82,10.95-22.77,17.04-13.79,12.09-23.22,25.32-28.29,39.71-5.07,14.39-4.14,28.91,2.78,43.56,8.23,17.43,18.96,28.59,32.17,33.48,13.22,4.9,26.36,6.08,39.45,3.52,13.08-2.55,28.66-7,46.73-13.36,12.64-4.52,21.79-7.33,27.45-8.43,5.66-1.09,9.38,.24,11.15,4,1.5,3.17,.04,6.88-4.36,11.14-4.41,4.26-11.56,8.73-21.46,13.41-11.69,5.52-22.62,9.41-32.81,11.69-10.19,2.27-24.47,4.72-42.85,7.35l-14.3,2.03,14.31,63.36c20.19-.33,40.38-2.97,60.57-7.91,10.92-2.67,21.43-5.84,31.54-9.48l-.05,.03,9.1,20.5,65.11-15.98-15.94-34.02c5.72-3.98,11.16-8.18,16.29-12.61,14.28-12.32,24.21-25.79,29.77-40.41,5.56-14.62,4.89-29.25-2.04-43.91Z"/>
                                            <path d="M137.46,.58c1.29,2.86,2.71,5.74,3.91,8.72,1.4,3.47,3.31,6.5,6.38,8.74,.76,.56,1.47,1.24,2.06,1.99,1.46,1.85,1.29,2.74-.71,3.97-4.26,2.61-8.81,4.55-13.68,5.73-.85,.21-1.69,.43-2.55,.55-1.83,.25-2.59-.26-2.92-2.09-.22-1.28-.21-2.6-.32-4.29-.84,.34-1.6,.61-2.31,.95-26.44,12.7-48.32,30.92-65.63,54.58-16.12,22.03-26.57,46.54-31.04,73.46-6.98,41.95,1.12,81.02,23.9,116.8,23.39,36.74,56.37,61.45,97.51,75.32,17.77,5.99,36.11,9.04,54.87,9.65,24.21,.79,47.75-2.8,70.62-10.74,.49-.17,.98-.36,1.46-.56,.15-.06,.26-.21,.51-.42l-.25-2.09c3.31-1.04,6.62-1.83,9.71-3.12,3.11-1.3,6.01-3.11,9.2-4.8,.19,.27,.59,.7,.83,1.2,2.93,6.13,5.85,12.26,8.75,18.41,1.37,2.9,1.06,3.72-1.92,5.01-19.13,8.26-38.99,13.97-59.64,16.85-4.64,.65-9.3,1.17-14.1,1.77,.72,1.7,1.2,3.34-.15,4.94-.22,.26,.05,.99,.2,1.48,2.81,9.25,5.57,18.52,8.47,27.73,1.46,4.63,4.86,7.31,9.51,8.43,5.92,1.43,11.88,2.75,17.78,4.24,15.78,3.98,31.55,8.01,47.32,12.06,4.26,1.1,8.22,2.83,11.47,5.94,2.65,2.53,4.57,5.42,5.41,9.05,.89,3.85,1.3,7.72,1.2,11.66-.03,1.43-.54,2.47-1.55,3.43-2.25,2.14-4.93,3.57-7.72,4.82-6.54,2.93-13.36,5.02-20.3,6.77-14.81,3.72-29.87,6.08-45.06,7.44-11.28,1.01-22.58,1.71-33.88,2.41-11.77,.74-23.51-.25-35.24-1.02-12.26-.8-24.47-2.1-36.61-4.03-13.78-2.2-27.44-4.91-40.48-10.03-3.46-1.36-6.72-3.25-9.96-5.1-2.26-1.29-3.47-3.3-3.12-6.09,.34-2.67,.35-5.39,.76-8.05,1.31-8.36,6.45-13.65,14.22-16.13,8.2-2.62,16.56-4.78,24.92-6.87,14.27-3.58,28.58-7,42.9-10.41,5.32-1.27,8.72-4.41,10.28-9.65,2.62-8.76,5.3-17.5,7.99-26.24,.33-1.07,.38-1.85-.34-2.88-.53-.75-.36-1.98-.55-3.34-.83-.04-2.02-.12-3.22-.17-36.13-1.53-69.84-11.36-100.83-30.02-33.53-20.19-59.3-47.73-76.53-82.97-9.3-19.02-15.08-39.13-17.35-60.21-3.56-33.04,1.27-64.79,14.63-95.22,9.79-22.28,23.5-41.87,40.76-58.99C78.64,27.68,103.91,12.02,132.49,1.62c1.51-.55,3.01-1.38,4.97-1.05Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>

                        </Link>


                        <Link to="/search">
                            <div className="mobile-menu__option menu-option" data-type="search">
                                <div className="mobile-menu__search bg-active">
                                    <span className="mobile-menu-search__text">Search</span>
                                    <div className="mobile-menu-search__icon">
                                        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                                            <g id="b">
                                                <path d="M350.79,342.08c2.55,1.56,5.18,2.56,6.99,4.37,45.71,45.56,91.33,91.21,136.97,136.85,3.61,3.61,7.54,7.21,3.61,12.83-2.98,4.24-6.9,5.1-11.26,2.08-1.59-1.1-2.86-2.66-4.24-4.04-45.64-45.63-91.29-91.26-136.89-136.93-1.83-1.83-3.22-4.1-5.2-6.66-54.26,48.57-116.44,65.07-185.82,49.56-52.3-11.69-93.17-42-122.35-86.79C-20.77,231.48-8.18,118.28,67.25,51.15c77.25-68.74,191.3-66.44,265.2-3.67,43.84,37.25,67.98,85,71.71,142.53,3.7,57.12-12.83,107.59-53.37,152.06Zm38.97-138.97c-1.52-103.22-78.69-187.7-187.38-187.75C96.23,15.32,14.35,95.84,14.41,202.94c.05,106.86,81.53,187.13,187.48,187.43,107.57,.31,185.97-82.88,187.87-187.26Z" />
                                            </g>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </Link>


                        <Link to="/wishlist">
                            <div className="mobile-menu__option menu-option" data-type="wishlist">
                                <div className="mobile-menu__heart bg-active">
                                    <span className="mobile-menu-heart__text">Wishlist</span>
                                    <div className="mobile-menu-heart__icon">
                                        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 584.6 500">
                                            <g id="b">
                                                <path d="M292.12,78.28c9.11-10.82,16.92-21.23,25.87-30.53C357.51,6.72,405.89-6.93,461.14,3.2c42.48,7.79,75.06,31.21,98.52,67.15,13.67,20.94,21.14,44.31,23.74,69.36,2.52,24.34,1.08,48.31-4.52,72.04-7.95,33.63-26.71,60.78-51.36,84.1-66.89,63.26-133.89,126.4-200.96,189.46-20.83,19.59-47.48,19.57-68.29,0-67.43-63.42-134.81-126.9-202.08-190.49C26.61,266.87,7.36,233.24,2.14,192.32-3.21,150.3,.6,109.47,23.55,72.97,53.52,25.3,97.47,.73,153.85,.16c54.66-.55,96.75,24.23,129.52,66.82,2.76,3.59,5.54,7.16,8.75,11.3Zm.25,29.33c-9.98-12.59-19.15-24.71-28.89-36.36-19.67-23.51-43-41.76-73.47-48.93-40.9-9.62-79.61-3.98-113.85,20.76C24.01,80.77,11.14,134.16,20.61,194.73c5.75,36.83,24.87,66.72,51.9,91.93,50.77,47.34,101.3,94.92,151.94,142.39,14.65,13.74,29.31,27.47,44.02,41.16,17.73,16.5,29.96,16.55,47.57,.29,1.87-1.73,3.7-3.49,5.56-5.22,62.3-58.08,124.15-116.67,187.08-174.06,39.71-36.21,59.15-79.9,58.37-133.77-.88-60.01-35.91-112.48-92.11-131.66-34.87-11.9-69.39-10.58-102.99,4.75-22.94,10.46-40.56,27.58-55.95,47.23-7.64,9.75-15.37,19.43-23.62,29.85Z" />
                                            </g>
                                        </svg>
                                        <div className="mobile__notification" ref={wishlistNotification}></div>
                                    </div>
                                </div>
                            </div>

                        </Link>

                        <Link to="/checkout">
                            <div className="mobile-menu__option menu-option" data-type="checkout">
                                <div className="mobile-menu__cart bg-active">
                                    <span className="mobile-menu-cart__text">Checkout</span>
                                    <div className="mobile-menu-cart__icon">
                                        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 564.33 500">
                                            <path d="M562.66,66.87c-1.51-1.99-3.85-3.15-6.33-3.15H83.35L69.38,6.16c-.88-3.62-4.09-6.16-7.77-6.16H8C3.58,0,0,3.62,0,8.09s3.58,8.09,8,8.09H55.34l92.33,380.6c.88,3.62,4.09,6.16,7.77,6.16H472.14c4.42,0,8-3.62,8-8.09s-3.58-8.09-8-8.09H161.71l-18.18-74.92H496.08c3.64,0,6.81-2.48,7.74-6.04l60.25-231.93c.63-2.43,.11-5.01-1.4-7Zm-72.75,228.78H139.61L87.27,79.91h458.68l-56.04,215.75Z" />
                                            <path d="M168.03,420.04c-22.15,0-40.17,17.93-40.17,39.98s18.02,39.98,40.17,39.98,40.17-17.93,40.17-39.98-18.02-39.98-40.17-39.98Zm0,63.77c-13.33,0-24.17-10.67-24.17-23.8s10.84-23.8,24.17-23.8,24.17,10.67,24.17,23.8-10.84,23.8-24.17,23.8Z" />
                                            <path d="M446,420.04c-22.15,0-40.17,17.93-40.17,39.98s18.02,39.98,40.17,39.98,40.17-17.93,40.17-39.98-18.02-39.98-40.17-39.98Zm0,63.77c-13.33,0-24.17-10.67-24.17-23.8s10.84-23.8,24.17-23.8,24.17,10.67,24.17,23.8-10.84,23.8-24.17,23.8Z" />
                                        </svg>
                                        <div className="mobile__notification" ref={checkoutNotification}></div>
                                    </div>
                                </div>
                            </div>

                        </Link>

                        <div className="mobile-menu__option menu-option" data-type="profile" >
                            <div className="mobile-menu__user bg-active" onClick={() => {
                                navigate("/profile");
                                dispatch(UIActionCreator.toggleOffMobileMenu()); //We want to toggle the mobile menu off
                            }}>
                                <span className="mobile-menu-user__text">Profile</span>
                                <div className="mobile-menu-user__icon">
                                    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 436.75 500">
                                        <g id="b">
                                            <g>
                                                <path d="M217.89,499.96c-60.18,0-120.36-.04-180.54,.04-12.63,.02-24.25-2.94-31-14.2-3.71-6.2-6.65-14.21-6.33-21.25,3.05-67.58,34.09-118.16,94.21-149.84,24.03-12.67,50.42-17.35,77.4-17.74,31.28-.45,62.57-.52,93.85,0,46.09,.76,86.97,15.49,120.28,48.12,30.34,29.71,47.49,65.92,50.46,108.45,.54,7.69,1.13,15.85-.78,23.15-3.94,15.03-16.9,23.2-34.4,23.25-33.67,.1-67.35,.03-101.02,.03-27.37,0-54.75,0-82.12,0Zm.2-15.3c60.2,0,120.39,.01,180.59,0,16.89,0,24.8-8.56,23.11-25.42-.89-8.83-2-17.69-3.81-26.37-6.21-29.71-20.21-55.45-41.99-76.48-32.12-31.02-71.29-44.39-115.53-44.24-28.03,.1-56.07-.03-84.1,.03-22.33,.05-44.15,3.14-64.97,11.62-34.74,14.15-61,37.7-78.47,70.95-10.36,19.71-15.33,40.84-17.45,62.92-1.76,18.36,5.39,26.99,23.99,26.99,59.54,0,119.09,0,178.63,0Z" />
                                                <path d="M343.53,124.79c-2.22,73.12-54.63,124.64-125.4,124.28-73.12-.37-124.56-54.33-124.13-125.11C94.44,51.51,147.63-.12,218.64,0c72.68,.12,123.1,53.57,124.89,124.79ZM218.56,14.84c-60.36-.02-109.35,48.81-109.72,109.35-.36,60.41,49.09,110.01,109.68,110.04,60.37,.02,109.36-48.8,109.72-109.35,.35-60.41-49.09-110.02-109.68-110.04Z" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>

                        {/* {
                            state.theme === 'dark' ?
                            <div className="mobile-menu__mode">
                                <img src={sol} alt="sun icon" className="mobile-menu-sun" onClick={()=>{
                                    dispatch(UIActionCreator.changeTheme('light'));
                                }}/>
                            </div>
                            :
                            <div className="mobile-menu__mode">
                                <img src={luna} alt="moon icon" className="mobile-menu-moon" onClick={()=>{
                                    dispatch(UIActionCreator.changeTheme('dark'));
                                }}/>
                            </div>
                        } */}
                    </div>

                </Offcanvas>
            </div>
        </>
    );


};

export default MobileMenu;