// Custom general popup component
import '../assets/css/Popup.scss';
import Modal from 'react-bootstrap/Modal';

const Popup = ({show, setShow, className="", children, backdrop=true}) => {

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName={"custom-popup__default-class " + className}
                centered
                backdropClassName={`${backdrop}`}
            >
                <Modal.Header closeButton>
                </Modal.Header>

                <div className="custom-popup__content">
                    {children}
                </div>
            </Modal>   
        </>
    );
};


export default Popup;