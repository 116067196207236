import '../assets/css/WishlistInfo.scss';
import AudioPlayer from './AudioPlayer';
import PriceSection from './PriceSection';
import { useEffect, useRef, useState } from 'react';
import useIsInWishlist from '../hooks/useIsInWishlist';
import { useDispatch } from 'react-redux';
import UserActions from '../actions/user.actions';
import song from  '../assets/audio/cyb3rapes.mp3';

const WishlistInfo = ({artwork}) => {
    const heartSvg = useRef();
    const [like, setLike] = useState(null);
    const isInWishlist = useIsInWishlist(artwork.product_id);
    const color = JSON.parse(artwork.metas.filter(meta => meta.meta_key === "color_tag")[0].meta_value);
    const dispatch = useDispatch();

    useEffect(() => {
        if (like === true) {
            heartSvg.current.style.fill = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
            dispatch(UserActions.addToWishlist(artwork));

        } else if(like === false) {
            heartSvg.current.style.fill = `rgba(${color.r}, ${color.g}, ${color.b}, 0)`;
            dispatch(UserActions.removeFromWishlist(artwork));
        } else if(isInWishlist) {
            heartSvg.current.style.fill = `rgba(${color.r}, ${color.g}, ${color.b}, 1)`;
        } else {
            heartSvg.current.style.fill = `rgba(${color.r}, ${color.g}, ${color.b}, 0)`;
        }
    }, [like])

    return (
        <>
            
            <h1 className="wishlist-artwork__name">{artwork.product_name}</h1>
            <p className="wishlist-artwork__author">{artwork.metas.filter(meta => meta.meta_key === 'artist')[0].meta_value ?? 'Unknown artist'}</p>
            <div className="wishlist-info">
                <div className="wishlist-content">
                    <div className="wishlist-content__left-panel">
                        <div className="wishlist__technique">
                            <p className='wishlist__technique-item'>{artwork.technique.technique_name}</p>
                            <p className='wishlist__technique-item'>{artwork.metas.filter(meta => meta.meta_key === 'width')[0].meta_value} x {artwork.metas.filter(meta => meta.meta_key === 'height')[0].meta_value} cm</p>
                            <p className='wishlist__technique-item'>{artwork.product_date}</p>
                            <div className="wishlist__remove-button" onClick={()=>{
                                if (isInWishlist) {
                                    heartSvg.current.classList.remove("active");
                                    setLike(false);
                                } else {
                                    heartSvg.current.classList.add("active");
                                    setLike(true);
                                }
                            }}>
                                <svg xmlns="http://www.w3.org/2000/svg" ref={heartSvg} viewBox="0 0 16 15">
                                    <g id="heart" >
                                        <path d="M8,15,6.84,13.937C2.72,10.1,0,7.6,0,4.5A4.386,4.386,0,0,1,4.4,0,4.7,4.7,0,0,1,8,1.717,4.7,4.7,0,0,1,11.6,0,4.386,4.386,0,0,1,16,4.5c0,3.106-2.72,5.6-6.84,9.441Z"/>
                                    </g>
                                </svg>
                            </div>
                        </div>

                        <p className="wishlist__description">
                            {artwork.product_description}
                        </p>

                        <div className="wishlist__audio">
                            <AudioPlayer audio={song}/>
                        </div>
                    </div>



                    <div className="wishlist-content__right-panel">
                        <PriceSection artworkDetails={artwork}/>      
                    </div>
                </div>
            </div>
        </>
    );
};


export default WishlistInfo;