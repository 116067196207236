import { useSelector } from "react-redux"
import { useState } from "react";
import jwt_decode from "jwt-decode"

const useUserId = () => {
    const state = useSelector(state => state);
    const token_decoded = jwt_decode(state.token); 
    return token_decoded.id;
}
   

export default useUserId;