import '../assets/css/LoadingPage.scss'
import Spinner from 'react-bootstrap/Spinner';

const LoadingPage = ({color="black"}) => {
    return (
        <>
            <div className={`loading-container ${color}`}>
                <Spinner animation="grow" variant="secondary" />
            </div>
        </>
    );
};

export default LoadingPage;