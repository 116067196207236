import '../assets/css/MasonryGallery.scss';
import ArtworkCardTemplate from './ArtworkCardTemplate';
import Masonry from 'react-masonry-css';

const MasonryGalleryTemplate = ({artworks}) => {
    const breakpoints = {
        default: 3,
        720: 2,
        550: 1
    };

    return (
        <>
            <div className="masonry">
                <Masonry
                    breakpointCols={breakpoints}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column">
                    <ArtworkCardTemplate/>
                    <ArtworkCardTemplate/>
                    <ArtworkCardTemplate/>
                    <ArtworkCardTemplate/>
                    <ArtworkCardTemplate/>
                    <ArtworkCardTemplate/>
                </Masonry>
            </div>
        </>
    );
};

export default MasonryGalleryTemplate;