import '../assets/css/ArtworkZoom.scss';
import OpenSeaDragon from 'openseadragon';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux/es/exports';
import UIActionCreator from '../actions/ui.actions';
import {motion} from 'framer-motion';


const ArtworkZoom = () => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const artwork_modal = useRef();
    const MAX_ZOOM_LEVEL_TOLERANCE = 8; //This is the maximum zoom level that the viewer will allow.
    const rangeInput = useRef();
    const [viewer, setViewer] = useState(null);

    //Initialize the viewer on mount
    useEffect(() => {
        document.body.style.overflow = 'hidden'; //remove vertical scroll

        //Quick conditional to check if the browser is firefox
        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1) {
            artwork_modal.current.classList.add("zoom-black-background");
        } else {
            artwork_modal.current.classList.add("zoom-glassmorphism-background");
        }
        
        setViewer(
            OpenSeaDragon({
                id: "artworkZoom",
                prefixUrl: "openseadragon-images/", //wtf is this?
                tileSources: state.dzi,
                visibilityRatio: 1.0,
                constrainDuringPan: false,
                animationTime: 1.0,
                zoomInButton: "artworkZoom__zoomIn",
                zoomOutButton: "artworkZoom__zoomOut",
                homeButton: "artworkZoom__reset",
            })
        );

        return() => {
            document.body.style.overflow = 'auto'; //restore vertical scroll on unmount
            dispatch(UIActionCreator.closeArtworkZoom());
        }
    }, []);

    useEffect(() => {
        if(viewer) {
      
            //If it's the first time we changed viewer, we add the event handlers and set the flag to false
            viewer.addHandler("open" , function() {
              rangeInput.current.min = viewer.viewport.getMinZoom();
      
              //This is to set a max zoom. This will work for every image
              if(viewer.viewport.getMaxZoom() < MAX_ZOOM_LEVEL_TOLERANCE) {
                  rangeInput.current.max = viewer.viewport.getMaxZoom();
                  viewer.viewport.maxZoomLevel = rangeInput.current.max;
              } else {
                  rangeInput.current.max = MAX_ZOOM_LEVEL_TOLERANCE;
                  viewer.viewport.maxZoomLevel = rangeInput.current.max;
              }
      
              //Also, we want value fixed to the home zoom:
              rangeInput.current.value = viewer.viewport.getHomeZoom();
            });
      
            //This will adjust min-zoom, max-zoom and current value of the zoom range
            viewer.addHandler('resize', function() {
                if(rangeInput.current) {
                    rangeInput.current.min = viewer.viewport.getMinZoom();
      
                    if(viewer.viewport.getMaxZoom() < MAX_ZOOM_LEVEL_TOLERANCE) {
                        rangeInput.current.max = viewer.viewport.getMaxZoom();
                        viewer.viewport.maxZoomLevel = rangeInput.current.max;
                    } else {
                        rangeInput.current.max = MAX_ZOOM_LEVEL_TOLERANCE;
                        viewer.viewport.maxZoomLevel = rangeInput.current.max;
                    }
        
                    if(rangeInput.current.value > rangeInput.current.max) {
                        rangeInput.current.value = rangeInput.current.max;
                    }
                    else if(rangeInput.current.value < rangeInput.current.min) {
                        rangeInput.current.value = rangeInput.current.min;
                    } else {
                        rangeInput.current.value = viewer.viewport.getZoom();
                    }
                }
            });
      
            //viewport Zoom event handler
            viewer.addHandler('zoom', function() {
                if(rangeInput.current) {
                    rangeInput.current.value = viewer.viewport.getZoom();
                }
            });  
        } 
        
      }, [viewer]);

    //Zoom to the current value of the range
  const handleZoomRange = () => {
    viewer.viewport.zoomTo(rangeInput.current.value);
  }

    return (
        <>
            <motion.div className="lupa-container" ref={artwork_modal}
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{opacity: 0}}
                transition={{duration: 0.2}}
            >

                <div className="artworkZoom-container">
                    <div id="artworkZoom"></div>

                    <div className="zoom-controls">
                        <span id="artworkZoom__zoomOut">
                            <i className="fa-solid fa-minus"></i>
                        </span>

                        <input type="range" step="0.001" id="artworkZoom__range" ref={rangeInput} onInput={handleZoomRange}/>

                        <span id="artworkZoom__zoomIn">
                            <i className="fa-solid fa-plus"></i>
                        </span>

                        <span id="artworkZoom__reset">
                            <i className="fa-solid fa-undo"></i>
                        </span>
                    </div>
                </div>

                <div className="x-icon" onClick={()=>{dispatch(UIActionCreator.closeArtworkZoom());}}>
                    <svg width="40" height="40" fill="white100" viewBox="0 0 18 18">
                        <title>Close</title>
                        <path d="M9.88 9l4.56 4.56-.88.88L9 9.88l-4.56 4.56-.88-.88L8.12 9 3.56 4.44l.88-.88L9 8.12l4.56-4.56.88.88z" fill="#FFF" fillRule="evenodd"></path>
                    </svg>
                </div>
            </motion.div>
        </>
    );
};


export default ArtworkZoom;