import Audio from 'react-h5-audio-player';
import '../assets/css/AudioPlayer.scss';
import 'react-h5-audio-player/src/styles.scss';

const AudioPlayer = ({audio}) => {
    return (
        <>
            <div className="audio-container">
                <Audio
                    src={audio}
                    showJumpControls={false}
                    showSkipControls={false}
                    customVolumeControls={[]}
                    customAdditionalControls={[]}
                    showDownloadProgress={true}
                    layout="horizontal-reverse"
                /> 
            </div>
        </>
    );
};


export default AudioPlayer;