import '../assets/css/ProfileNavigation.scss';
import ProfileNavigationOptions from './ProfileNavigationOptions';
import {Link, NavLink } from 'react-router-dom';
import {useRef, useEffect} from 'react';
import config from '../config/project.config';
import { useSelector } from 'react-redux';

const ProfileNavigation = () => {
    const profileNav = useRef();
    const state = useSelector(state => state);

    return (
        <>
            <div className="profile-navigation-container" ref={profileNav}>

                <Link className="profile-navigation__exit" to="/main" onClick={()=>{document.body.style.overflow = 'auto';}}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 300 300">
                        <g id="Capa_4" data-name="Capa 4">
                            <path
                                d="M241.73,174.15a8.45,8.45,0,0,0-8.45,8.45v96.91H50.83l79.57-22.33A14.6,14.6,0,0,0,141,243.13V56.35a12,12,0,0,0-8.61-11.54L49.2,20.13H233.28v92.55a8.46,8.46,0,0,0,16.91,0v-101a8.45,8.45,0,0,0-8.46-8.45h-221a8.45,8.45,0,0,0-8.45,8.45V288a8.46,8.46,0,0,0,8.45,8.45h221a8.46,8.46,0,0,0,8.46-8.45V182.6A8.45,8.45,0,0,0,241.73,174.15Z" />
                            <path
                                d="M282.79,132.61H207V120.72a3.42,3.42,0,0,0-5.84-2.41L172.06,147.4a3.42,3.42,0,0,0,0,4.84l29.09,29.09a3.42,3.42,0,0,0,5.84-2.42V167h75.8a5,5,0,0,0,4.95-4.95V137.56A5,5,0,0,0,282.79,132.61Z" />
                        </g>
                    </svg>
                </Link>
               
                <p className="profile-navigation__gallery-name">{state.gallery_data.user.user_name}</p>

                <div className="profile__logo" style={{backgroundImage: `url(${state.gallery_data.gallery_pic})`}}>
                </div>

                <NavLink className={({ isActive }) => isActive ? "profile-navigation__edit active" : "profile-navigation__edit" } to="edit">
                    <div className="profile-navigation__edit-inner">
                        <h1 className="profile-navigation__edit-h1">Edit profile</h1>
                    </div>
                </NavLink>
                
                <ProfileNavigationOptions/>

            </div>
        </>
    );
};


export default ProfileNavigation;