import '../assets/css/ArtworkCardTemplate.scss';

const ArtworkCardTemplate = () => {
    return (
        <>
            <div className="artwork-card-template">
                <div className="artwork-card__image-template"></div>

                <div className="artwork-card__details-template-wrap">
                    <div className="artwork-card__name-template"></div>
                    <div className="artwork-card__price-author-template-container">
                        <div className="artwork-card-price-author-template__item"></div>
                        <div className="artwork-card-price-author-template__item"></div>
                    </div>
                </div>
            </div>
        </>
    );
};


export default ArtworkCardTemplate;